import React from "react";
import { useNavigate } from "react-router-dom";

import OPTCalculculatorSlideStyles from "./OPTCalculculatorSlideStyles";
import ButtonCustom from "../../../../ButtonCustom/ButtonCustom";
import { routes } from "../../../../../routes";

import OPTCalculator from "../../../../../assets/svg/opt-calculator.svg";

const OPTCalculculatorSlide = () => {
  const navigate = useNavigate();
  return (
    <OPTCalculculatorSlideStyles>
      <div className="opt-calculator-slide">
        <div className="top-image">
          <img src={OPTCalculator} alt="Illustration" />
        </div>
        <div className="title text-primary">OPT Calculator</div>
        <div className="sub-title text-primary">
          Use this tool to calculate OPT eligibility and important timelines.
        </div>
        <ButtonCustom
          width={265}
          onClick={() => navigate(routes.TOOLS.OPT_CALCULATOR)}
          keepLabelCase={true}
          className="bg-blue"
        >
          Learn more
        </ButtonCustom>
      </div>
    </OPTCalculculatorSlideStyles>
  );
};

export default OPTCalculculatorSlide;
