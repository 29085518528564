import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import OPTQueryContainer from "../../../../../components/OPTCalculator/OPTQueryContainer/OPTQueryContainer";
import CustomDatePicker from "../../../../../components/CustomDatePicker/CustomDatePicker";
import DeadlinesOnPostCompletionOPTStyles from "./DeadlinesOnPostCompletionOPTStyles";
import {
  disableNext,
  updateDeadlinesOnPostCompletionOPT,
} from "../../../../../redux/reducers/optReducer";
import { isFormattedDateValid } from "../../../../../utils/common";
import InfoIconTooltip from "../../../../../components/InfoIconTooltip/InfoIconTooltip";

const DeadlinesOnPostCompletionOPT = () => {
  const dispatch = useDispatch();
  const { optCalculatorState = {} } = useSelector((store) => store.optStore);
  const { deadlines_post_completion_opt = {} } = optCalculatorState;
  const { value = null } = deadlines_post_completion_opt; // null value is intended to keep the input clear and show placeholder
  const handleDateChange = (date) => {
    dispatch(
      updateDeadlinesOnPostCompletionOPT({
        value: isFormattedDateValid(date, "LL") ? date : null,
      })
    );
  };
  useEffect(() => {
    if (!value) {
      dispatch(disableNext(true));
    } else {
      dispatch(disableNext(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <DeadlinesOnPostCompletionOPTStyles>
      <OPTQueryContainer
        title={
          <>
            Enter the program end date on the Form I-20:
            <InfoIconTooltip
              variant="header"
              aria-label="OPT calculator note"
              placement="bottom"
              margin="-1px 0px 0px 6px"
              content="If you are graduating early, you must enter the updated end date."
            />
          </>
        }
      >
        <CustomDatePicker
          dateFormat={"MMMM dd, yyyy"}
          width={200}
          type="date"
          onChange={handleDateChange}
          value={value !== null ? new Date(value) : null}
          placeholder="Enter date"
          isClearable
          name="opt_program_end_date"
        />
      </OPTQueryContainer>
    </DeadlinesOnPostCompletionOPTStyles>
  );
};

export default DeadlinesOnPostCompletionOPT;
