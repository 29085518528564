import { createSlice } from "@reduxjs/toolkit";
import {
  OPT_CALCULATOR_RIGHT_SIDEBAR_SCHEMA,
  OPT_CALCULATOR_STATUS_LIST,
  optCalculatorInitialArgs,
} from "../../constant/mockdata";
import { filter, size } from "lodash";

const initialState = {
  // OPT Calculator
  optCalculatorState: { ...optCalculatorInitialArgs },
  optStatusList: [...OPT_CALCULATOR_STATUS_LIST],
  optRightSidebarStatus: [...OPT_CALCULATOR_RIGHT_SIDEBAR_SCHEMA],
  optIndex: null,
  isNextDisabled: false,
  optTotalCompletedStatus: 0,
};

export const optReducer = createSlice({
  name: "opt",
  initialState,
  reducers: {
    updateFullTimeCPTUsed: (state, action) => {
      state.optCalculatorState.full_time_cpt_used = {
        ...state.optCalculatorState.full_time_cpt_used,
        ...action.payload,
      };
    },
    updateAvailablePostCompletionOPT: (state, action) => {
      state.optCalculatorState.available_post_completion_opt = {
        ...state.optCalculatorState.available_post_completion_opt,
        ...action.payload,
      };
    },
    updateDeadlinesOnPostCompletionOPT: (state, action) => {
      state.optCalculatorState.deadlines_post_completion_opt = {
        ...state.optCalculatorState.deadlines_post_completion_opt,
        ...action.payload,
      };
    },
    updatePreferredOPTStartDate: (state, action) => {
      state.optCalculatorState.preferred_opt_start_date = {
        ...state.optCalculatorState.preferred_opt_start_date,
        ...action.payload,
      };
    },
    updateFormI765Deadline: (state, action) => {
      state.optCalculatorState.form_i765_filing_deadline = {
        ...state.optCalculatorState.form_i765_filing_deadline,
        ...action.payload,
      };
    },
    updateOPTIndex: (state, action) => {
      state.optIndex = action.payload;
    },
    disableNext: (state, action) => {
      state.isNextDisabled = action.payload;
    },
    handleOPTNextAction: (state, action) => {
      const newOPTStatusList = Object.assign([], state.optStatusList);
      newOPTStatusList[action.payload].isCompleted = true;
      const total = size(filter(newOPTStatusList, { isCompleted: true }));
      state.optIndex = action.payload + 1;
      state.optStatusList = newOPTStatusList;
      state.optTotalCompletedStatus = total;
    },
    updateOPTRightSidebarStatus: (state, action) => {
      state.optRightSidebarStatus = [...action.payload];
    },
    resetOPTInitialState: () => initialState,
  },
});

export const {
  updateOPTIndex,
  updateFullTimeCPTUsed,
  updateAvailablePostCompletionOPT,
  updateDeadlinesOnPostCompletionOPT,
  updateDSOUSCIS,
  updatePreferredOPTStartDate,
  updateFormI765Deadline,
  disableNext,
  handleOPTNextAction,
  updateOPTRightSidebarStatus,
  resetOPTInitialState,
} = optReducer.actions;

export default optReducer.reducer;
