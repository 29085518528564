import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { isNumber } from "lodash";

import OPTQueryContainer from "../../../../../components/OPTCalculator/OPTQueryContainer/OPTQueryContainer";
import CustomDatePicker from "../../../../../components/CustomDatePicker/CustomDatePicker";
import CustomAddToCalendarButton from "../../../../../components/CustomAddToCalendarButton/CustomAddToCalendarButton";

import PreferredOPTStartDateStyles from "./PreferredOPTStartDateStyles";

import {
  disableNext,
  updatePreferredOPTStartDate,
} from "../../../../../redux/reducers/optReducer";

import {
  getDateDiffInDays,
  isFormattedDateValid,
} from "../../../../../utils/common";

import { OPT_DAYS_PER_LEVEL } from "../../../../../constant/mockdata";

const DateSuggestion = ({ startDate = "", endDate = "", deadline = "" }) => {
  return (
    <div className="date-suggestion__container">
      <h2>OPT Start date must be between</h2>
      <p>
        {startDate} and {endDate}
      </p>
      <CustomAddToCalendarButton
        name={"OPT Start date must be between"}
        class="date-suggestion__atcb" // need to make an exception to use class instead of className
        startDate={startDate ? moment(startDate)?.format("YYYY-MM-DD") : ""}
        endDate={endDate ? moment(endDate)?.format("YYYY-MM-DD") : ""}
        options={[
          "Apple",
          "Google",
          "iCal",
          "Microsoft365",
          "MicrosoftTeams",
          "Outlook.com",
          "Yahoo",
        ]}
        buttonStyle="round"
      />
      <h2 className="mt-30">Requested OPT end date must be on or before:</h2>
      <p>{deadline}</p>
      <CustomAddToCalendarButton
        name={"Requested OPT end date must be on or before"}
        class="date-suggestion__atcb" // need to make an exception to use class instead of className
        startDate={deadline ? moment(deadline)?.format("YYYY-MM-DD") : ""}
        options={[
          "Apple",
          "Google",
          "iCal",
          "Microsoft365",
          "MicrosoftTeams",
          "Outlook.com",
          "Yahoo",
        ]}
        buttonStyle="round"
      />
    </div>
  );
};

const PreferredOPTStartDate = () => {
  const dispatch = useDispatch();
  const [datePreviewData, setDatePreviewData] = useState({
    startDate: null,
    endDate: null,
    deadline: null,
  });

  const { optCalculatorState = {} } = useSelector((store) => store.optStore);
  const {
    preferred_opt_start_date = {},
    deadlines_post_completion_opt = {},
    available_post_completion_opt = {},
  } = optCalculatorState;
  const { value = null } = preferred_opt_start_date;
  const { startDate = null } = value;
  useEffect(() => {
    if (!startDate) {
      dispatch(disableNext(true));
    } else {
      dispatch(disableNext(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate]);

  useEffect(() => {
    const fullTimeDays = available_post_completion_opt?.value
      ?.filter((val) => !val?.isPartTime)
      .reduce(getDateDiffInDays, 0);

    const partTimeDays =
      Number(
        available_post_completion_opt?.value
          ?.filter((val) => val?.isPartTime)
          .reduce(getDateDiffInDays, 0)
      ) / 2;

    setDatePreviewData({
      startDate: deadlines_post_completion_opt?.value
        ? moment(deadlines_post_completion_opt?.value)
            .add(1, "d")
            .format("MMMM DD, YYYY")
        : null,
      endDate: deadlines_post_completion_opt?.value
        ? moment(deadlines_post_completion_opt?.value)
            .add(60, "d")
            .format("MMMM DD, YYYY")
        : null,
      deadline:
        startDate && isNumber(fullTimeDays) && isNumber(partTimeDays)
          ? moment(startDate)
              .add(OPT_DAYS_PER_LEVEL - fullTimeDays - partTimeDays, "days")
              .format("MMMM DD, YYYY")
          : null,
    });
  }, [deadlines_post_completion_opt, available_post_completion_opt, startDate]);

  const isDateWithinRange = (value, minDate, maxDate) => {
    if (
      moment(value)?.isSameOrAfter(moment(minDate)) &&
      moment(value)?.isSameOrBefore(moment(maxDate))
    ) {
      return true;
    }
    return false;
  };

  const handleDateChange = (date, dateType) => {
    dispatch(
      updatePreferredOPTStartDate({
        value: {
          ...value,
          [dateType]: isFormattedDateValid(date, "LL") ? date : null,
        },
      })
    );
  };

  return (
    <PreferredOPTStartDateStyles>
      <OPTQueryContainer title={"Enter preferred OPT start date:"}>
        <CustomDatePicker
          dateFormat={"MMMM dd, yyyy"}
          width={200}
          minDate={
            datePreviewData?.startDate
              ? new Date(datePreviewData.startDate)
              : null
          }
          maxDate={
            datePreviewData?.endDate ? new Date(datePreviewData.endDate) : null
          }
          type="date"
          onChange={(date) => handleDateChange(date, "startDate")}
          value={
            startDate && datePreviewData?.startDate && datePreviewData?.endDate
              ? isDateWithinRange(
                  startDate,
                  datePreviewData.startDate,
                  datePreviewData.endDate
                )
                ? new Date(startDate)
                : new Date(datePreviewData.startDate)
              : startDate
              ? new Date(startDate)
              : null
          }
          placeholder="Enter date"
          disabled={!(datePreviewData?.startDate && datePreviewData?.endDate)}
          isClearable
          name={"opt_preferred_start_date"}
        />
        {datePreviewData?.startDate && datePreviewData?.endDate ? (
          <DateSuggestion
            startDate={datePreviewData.startDate}
            endDate={datePreviewData.endDate}
            deadline={datePreviewData?.deadline}
          />
        ) : (
          <p className="warning-message">
            Please enter deadlines on post-completion OPT to calculate OPT start
            date window.
          </p>
        )}
      </OPTQueryContainer>
    </PreferredOPTStartDateStyles>
  );
};

export default PreferredOPTStartDate;
