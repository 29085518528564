import React from "react";
import { redirect } from "react-router-dom";
import { toast, Slide } from "react-toastify";
import {
  CheckCircleOutline,
  HighlightOff,
  ErrorOutline,
  Warning,
} from "@mui/icons-material";
import { getNewToken, checkSsoUser } from "../services/authenticationServices";
// import NewCountryList from "../assets/data/countries+states+cities.json";
import moment from "moment";
import { default as momentTz } from "moment-timezone";
import Bugsnag from "@bugsnag/js";
import axios from "axios";
import MixpanelEvents from "../constant/MixpanelEvents.json";

import {
  SECONDARY_COLOR,
  LIGHT_GRAY_COLOR,
  MEDIUM_GRAY_COLOR,
  ACCESSIBILITY_SECONDARY_COLOR,
  ACCESSIBILITY_GRAY_COLOR,
  GRAY_COLOR,
  GRAY_TEXT_COLOR,
  ACCESSIBILITY_LIGHT_GRAY_COLOR,
  SECONDARY_BTN_HOVER_COLOR,
  ACCESSIBILITY_SECONDARY_BTN_HOVER_COLOR,
  ACCESSIBILITY_GRAY_TEXT_COLOR,
  GRAY_BTN_HOVER_COLOR,
  ACCESSIBILITY_GRAY_BTN_HOVER_COLOR,
  ACCESSIBILITY_GRAY_OUTLINE_COLOR,
  GRAY_OUTLINE_COLOR,
  PRIMARY_MAIN_COLOR,
  PRIMARY_DARK_COLOR,
  FIELD_LABEL_COLOR,
  LINKL_COLOR,
  CARD_BOX_SHADOW,
  CARD_BOX_SHADOW_HOVER,
  F_LIGHT_GRAY_COLOR,
  ICON_BACKGROUND,
  ICON_BACKGROUND_HOVER,
} from "../styles/variables";
import NoUserIcon from "../assets/svg/noUser.svg";
import NoUserAccessibilityIcon from "../assets/svg/noUser-acceessibility.svg";
import { routes } from "../routes";
import { getCountriesAPI } from "../services/commonServices";
import { isEmpty, isNull, isUndefined } from "underscore";
import ALLCountryList from "../assets/data/CountryCodes.json";
import ALLCitiesOfCountriesList from "../assets/data/CountriesWithCities.json";
import ALLCitiesList from "../assets/data/countries.json";
import { includes } from "lodash";
import { updateLastActiveSessionAPI } from "../services/authenticationServices";
import { config } from "../constant/config";
import { Mixpanel } from "../mixpanel";

// Notifications Icons - START //
import MessageIcon from "../assets/Notifications/Networking.svg";
import MyTopicsIcon from "../assets/Notifications/Topics.svg";
import webinarsIcon from "../assets/Notifications/Webinar.svg";
import calendarIcon from "../assets/Notifications/Calendar.svg";
import searchJobsIcon from "../assets/svg/searchJobsIcon.svg";
import resourceIcon from "../assets/Notifications/Resources.svg";
import studentServiceIcon from "../assets/Notifications/Services.svg";
import DefaultIcon from "../assets/Notifications/Other.svg";
import postIcon from "../assets/Notifications/Post.svg";
// Notifications Icons - END //

export let NoUserImage =
  localStorage.getItem("accessibilityHelp") == "true"
    ? NoUserAccessibilityIcon
    : NoUserIcon;

export const headers_with_token = ({ formData = false } = {}) => {
  let keepSignedIn = localStorage.getItem("keepSignedIn");
  const authExpire = localStorage.getItem("authTokenExpiration");
  const authExpiration = moment(authExpire).unix();
  let currDate;
  if (keepSignedIn == 0) {
    currDate = moment().add(5, "minutes").unix();
    keepSignedIn = false;
  } else {
    currDate = moment().add(24, "hours").unix();
    keepSignedIn = true;
  }

  if (currDate >= authExpiration) {
    // eslint-disable-next-line
    (async () => {
      const data = await getNewToken(keepSignedIn);
      if (data?.data) {
        localStorage.setItem("authToken", data?.data?.authentication_token);
        localStorage.setItem(
          "authTokenExpiration",
          data?.data?.auth_token_expiration
        );
      }
    })();
  }

  if (localStorage.getItem("isLoginWithLinkedin")) {
    // eslint-disable-next-line
    (async () => {
      const userId = localStorage.getItem("userId");
      const response = await checkSsoUser(userId);

      if (!response.success) {
        // Leave breadcrumb
        Bugsnag.leaveBreadcrumb(
          "Session storage clear",
          {
            instance: "SSO API failure",
          },
          "state"
        );
        clearLocalStorage();
        applyAccessibilityHelp(false);
        redirect(routes.LOGIN);
      }
    })();
  }

  const authToken = localStorage.getItem("authToken");
  return {
    headers: {
      "Content-Type": formData ? "multipart/form-data" : "application/json",
      Authorization: authToken,
    },
  };
};

export const cancel_token = () => {
  const CancelToken = axios.CancelToken;
  return CancelToken.source();
};

export const axios_api_config = (cancelToken) => {
  return {
    ...headers_with_token(),
    // Pass cancelToken in config
    cancelToken,
  };
};

export const headers_new_token = () => {
  const authToken = localStorage.getItem("authToken");
  return {
    headers: {
      "Content-Type": "application/json",
      // "X-User-Email": email,
      Authorization: authToken,
    },
  };
};

export const getQueryParams = (query) =>
  query
    .replace("?", "")
    .split("&")
    .reduce(
      (r, e) => ((r[e.split("=")[0]] = decodeURIComponent(e.split("=")[1])), r),
      {}
    );

export const prepairCountryFlagURL = (countryCode = "in") => {
  return `https://flagcdn.com/48x36/${countryCode}.png`;
};

export const getYearList = (min) => {
  const minYear = min ?? moment().subtract(50, "year").year();
  const yearsOptionsList = [];
  for (let index = minYear; index <= new Date().getFullYear() + 10; index++) {
    yearsOptionsList.push({ label: index, value: index });
  }
  return yearsOptionsList;
};

export const getYearListForExperience = (min = 1960) => {
  const minYear = parseInt(min);
  const yearsOptionsList = [];
  for (let index = minYear; index <= new Date().getFullYear(); index++) {
    yearsOptionsList.push({ label: index, value: index });
  }
  return yearsOptionsList;
};

export const getYearListForStudent = (min = new Date().getFullYear()) => {
  const yearsOptionsList = [];
  for (let index = min; index <= new Date().getFullYear() + 10; index++) {
    yearsOptionsList.push({ label: index, value: index });
  }
  return yearsOptionsList;
};

export const getCountryListFromAPI = async (callback) => {
  let { countries = [] } = await getCountriesAPI();
  let fromattedCountryList = countries.map((item) => ({
    label: item.name,
    value: item.name,
    code: item.iso1.toLowerCase(),
  }));

  if (callback) callback(fromattedCountryList);
  else return fromattedCountryList;
};

export const getYearListForAlumni = (min) => {
  const minYear = min ?? moment().subtract(50, "year").year();
  const yearsOptionsList = [];
  for (let index = new Date().getFullYear(); index >= minYear; index--) {
    yearsOptionsList.push({ label: index, value: index });
  }
  return yearsOptionsList;
};

export const getCountryList = () => {
  let newCountryList = ALLCountryList.map((item) => {
    return { label: item.name, value: item.name };
  });
  return newCountryList;
};

export const getCitiesOfCountry = (country) => {
  const filteredCities = ALLCitiesOfCountriesList.filter(
    (item) => item.country === country
  );
  const uniquCities = Array.from(new Set(filteredCities[0]?.cities));
  const cities = [];
  const city = uniquCities?.map((city) => {
    cities.push({
      label: city,
      value: city,
    });
  });

  return cities;
};

export const getCitiesList = (country) => {
  let newCitiesList = ALLCitiesList.filter((item) => {
    if (item.country === country) {
      return item.states;
    }
  });

  const finalCitiesList = [];
  const cities = newCitiesList[0]?.states?.map((item) => {
    finalCitiesList.push({ name: item, label: item });
  });
  return finalCitiesList;
};

export const getCountryListWithCode = () => {
  let newCountryList = ALLCountryList.map((item) => {
    return {
      label: item.name,
      value: item.name,
      code: item.code.toLowerCase(),
    };
  });
  return newCountryList;
};

const getToastifyIcon = (type) => {
  switch (type) {
    case "success":
      return <CheckCircleOutline />;
    case "error":
      return <HighlightOff />;
    case "info":
      return <ErrorOutline />;
    case "warning":
      return <Warning />;
    default:
      return <div></div>;
  }
};

export const toastify = (type = "success", message = "", autoClose = 3500) => {
  switch (type) {
    case "success":
      toast.success(
        <div className="d-flex align-items-center">
          {getToastifyIcon(type)}
          &nbsp; <div>{message}</div>
        </div>,
        {
          autoClose: autoClose,
          position: "bottom-center",
          transition: Slide,
          closeOnClick: true,
          role: "alert",
        }
      );
      break;
    case "error":
      toast.error(
        <div className="d-flex align-items-center">
          {getToastifyIcon(type)}
          &nbsp;{" "}
          <div style={{ display: "block" }}>
            {message ? message : "Something went wrong!"}
          </div>
        </div>,
        {
          autoClose: autoClose,
          position: "bottom-center",
          transition: Slide,
          closeOnClick: true,
          role: "alert",
        }
      );
      break;
    case "info":
      toast.info(
        <div className="d-flex align-items-center">
          {getToastifyIcon(type)}
          &nbsp; <div style={{ display: "block" }}>{message}</div>
        </div>,
        {
          autoClose: autoClose,
          position: "bottom-center",
          transition: Slide,
          closeOnClick: true,
          role: "alert",
        }
      );
      break;
    case "warning":
      toast.warning(
        <div className="d-flex align-items-center">
          {getToastifyIcon(type)}
          &nbsp; <div style={{ display: "block" }}>{message}</div>
        </div>,
        {
          autoClose: autoClose,
          position: "bottom-center",
          transition: Slide,
          closeOnClick: true,
          role: "alert",
        }
      );
      break;
    default:
      break;
  }
};

// By Ali Sufyan 04-08-2021

// export const getNewCountryList = () => {
//   const contry = NewCountryList.map((item) => {
//     return { ...item, label: item.name, value: item.iso2.toLowerCase() };
//   });
//   return contry;
// };

// export const getNewCountryOptions = () => {
//   const contry = NewCountryList.map((item) => {
//     return { label: item.name, value: item.iso2.toLowerCase() };
//   });
//   return contry;
// };

// export const getCitizenCountryOptions = () => {
//   const contry = NewCountryList.map((item) => {
//     return { label: item.name, value: item.iso3 };
//   });
//   return contry;
// };

export const capitalizeFirstLetter = (string = "") => {
  if (string && string.trim().length > 0)
    return string.charAt(0).toUpperCase() + string.slice(1);
  else return "";
};

export const optionsGenerator = (options, byId = false) => {
  const optionData = [];
  if (byId) {
    options.forEach((option) => {
      optionData.push({ value: option.id, label: option.name });
    });
  } else {
    options.forEach((option) => {
      option && optionData.push({ value: option, label: option });
    });
  }
  return optionData;
};

export const renderTimestamp = (timestamp) => {
  let prefix = "";
  if (timestamp === "") return "a min ago";
  const timeDiff = Math.round(
    (new Date().getTime() - new Date(timestamp).getTime()) / 60000
  );
  if (timeDiff < 1) {
    // less than one minute ago
    prefix = "just now";
  } else if (timeDiff < 60 && timeDiff >= 1) {
    // less than sixty minutes ago
    prefix = `${timeDiff} min ago`;
  } else if (timeDiff < 24 * 60 && timeDiff >= 60) {
    // less than 24 hours
    prefix = `${Math.round(timeDiff / 60)} hours ago`;
  } else if (timeDiff < 31 * 24 * 60 && timeDiff > 24 * 60) {
    // less than 7 days
    prefix = `${Math.round(timeDiff / (60 * 24))} days ago`;
  } else if (timeDiff < 12 * 31 * 24 * 60 && timeDiff > 31 * 24 * 60) {
    // less than 12 month
    prefix = `${Math.round(timeDiff / (31 * 24 * 60))} months ago`;
  } else {
    // few month ago
    prefix = `few month ago`;
  }
  return prefix;
};

export const calculateLocalEndTimeFromEpoch = (
  epochTime,
  number,
  add,
  showSpace = true,
  format = true
) => {
  if (format) {
    return moment
      .unix(epochTime)
      .local()
      .add(number, add)
      .format(showSpace ? "hh:mm a" : "hh:mma");
  } else {
    return moment.unix(epochTime).local().add(number, add).toDate();
  }
};

export const createtTime = (date, number, add, showSpace = true) => {
  return moment
    .utc(date)
    .add(number, add)
    .format(showSpace ? "hh:mm a" : "hh:mma");
};

export const base64ToFile = (base64String, fileName) => {
  const arr = base64String.split(",");

  const mime = arr[0].match(/:(.*?);/)[1];

  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], fileName, { type: mime });
};

export const downloadAttachment = (attachment) => {
  fetch(attachment)
    .then((response) => response.blob())
    .then(
      (blob) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        })
    )
    .then((dataUrl) => {
      var anchor = document.createElement("a");
      anchor.download =
        moment().format("YYYY-MM-DD hh:mm a") +
        "_" +
        attachment.substring(attachment.lastIndexOf("/") + 1); // give any file name here
      anchor.href = dataUrl;
      anchor.click();
    })
    .catch((error) => {
      console.log("Error ::" + error.message);
    });
};

export const textLabel = (type) => {
  switch (type) {
    case "Website Link":
      return "Visit again";
    case "PDF":
    case "Document":
    case "Excel Sheet":
      return "Read again";
    case "Image":
      return "View again";
    default:
      return "Open again";
  }
};

export const loadGoogleMaps = (callback) => {
  const existingScript = document.getElementById("googleMaps");

  if (!existingScript) {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${
      config().GOOGLE_SERVICE_API_KEY
    }&libraries=places`;
    script.id = "googleMaps";
    document.body.appendChild(script);

    const script2 = document.createElement("script");
    script2.src =
      "https://unpkg.com/@googlemaps/markerclustererplus/dist/index.min.js";
    script2.id = "googleMapsCluster";
    document.body.appendChild(script2);

    script2.onload = () => {
      if (callback) callback();
    };
  }

  if (existingScript && callback) callback();
};

export const addAccessibilityClass = () => {
  const accessibilityHelp = localStorage.getItem("accessibilityHelp");
  if (accessibilityHelp == "true") {
    document.body.classList.add("accessibility");
  } else {
    document.body.classList.remove("accessibility");
  }
};

export const applyAccessibilityHelp = (help) => {
  const body = document.querySelector("body");

  if (help === true || help == "true") {
    NoUserImage = NoUserAccessibilityIcon;
    body.style.setProperty("--secondary-color", ACCESSIBILITY_SECONDARY_COLOR);
    body.style.setProperty(
      "--light-gray-color",
      ACCESSIBILITY_LIGHT_GRAY_COLOR
    );
    body.style.setProperty("--medium-gray-color", ACCESSIBILITY_GRAY_COLOR);
    body.style.setProperty("--gray-color", ACCESSIBILITY_GRAY_COLOR);
    body.style.setProperty(
      "--secondary-color-btn-hover",
      ACCESSIBILITY_SECONDARY_BTN_HOVER_COLOR
    );
    body.style.setProperty(
      "--gray-color-btn-hover",
      ACCESSIBILITY_GRAY_BTN_HOVER_COLOR
    );
    body.style.setProperty("--gray-text-color", ACCESSIBILITY_GRAY_TEXT_COLOR);
    body.style.setProperty(
      "--gray-outline-color",
      ACCESSIBILITY_GRAY_OUTLINE_COLOR
    );
    body.style.setProperty("--primary-main-color", PRIMARY_MAIN_COLOR);
    body.style.setProperty("--primary-dark-color", PRIMARY_DARK_COLOR);
    body.style.setProperty("--field-label-color", ACCESSIBILITY_GRAY_COLOR);
    body.style.setProperty("--link-color", LINKL_COLOR);
    body.style.setProperty("--card-box-shadow", CARD_BOX_SHADOW);
    body.style.setProperty("--f-light-gray-color", ACCESSIBILITY_GRAY_COLOR);
    body.style.setProperty("--card-box-shadow-hover", CARD_BOX_SHADOW_HOVER);
    body.style.setProperty("--icon_background", ICON_BACKGROUND);
    body.style.setProperty("--icon_background_hover", ICON_BACKGROUND_HOVER);
    document.body.classList.add("accessibility");
  } else {
    NoUserImage = NoUserIcon;
    body.style.setProperty("--secondary-color", SECONDARY_COLOR);
    body.style.setProperty("--light-gray-color", LIGHT_GRAY_COLOR);
    body.style.setProperty("--medium-gray-color", MEDIUM_GRAY_COLOR);
    body.style.setProperty("--gray-color", GRAY_COLOR);
    body.style.setProperty(
      "--secondary-color-btn-hover",
      SECONDARY_BTN_HOVER_COLOR
    );
    body.style.setProperty("--gray-color-btn-hover", GRAY_BTN_HOVER_COLOR);
    body.style.setProperty("--gray-text-color", GRAY_TEXT_COLOR);
    body.style.setProperty("--gray-outline-color", GRAY_OUTLINE_COLOR);
    body.style.setProperty("--primary-main-color", PRIMARY_MAIN_COLOR);
    body.style.setProperty("--primary-dark-color", PRIMARY_DARK_COLOR);
    body.style.setProperty("--field-label-color", FIELD_LABEL_COLOR);
    body.style.setProperty("--link-color", LINKL_COLOR);
    body.style.setProperty("--card-box-shadow", CARD_BOX_SHADOW);
    body.style.setProperty("--card-box-shadow-hover", CARD_BOX_SHADOW_HOVER);
    body.style.setProperty("--f-light-gray-color", F_LIGHT_GRAY_COLOR);
    body.style.setProperty("--icon_background", ICON_BACKGROUND);
    body.style.setProperty("--icon_background_hover", ICON_BACKGROUND_HOVER);
    document.body.classList.remove("accessibility");
  }
};

export const checkMobileDevice = () => {
  if (
    //eslint-disable-next-line
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
      navigator.userAgent
    ) ||
    //eslint-disable-next-line
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
      navigator.userAgent.substr(0, 4)
    )
  )
    return true;
  else return false;
};

export const focusFirstElement = (id) => {
  const element = document.getElementById(id);
  if (element) {
    const focusableEls = element.querySelectorAll(
      'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])'
    );
    if (focusableEls.length > 0) {
      const firstFocusableEl = focusableEls[0];

      if (window.getComputedStyle(firstFocusableEl).display === "none") {
        if (focusableEls.length > 1 && focusableEls[1]) focusableEls[1].focus();
      } else {
        firstFocusableEl.focus();
      }
    }
  }
};

export const numberWithCommas = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const camelCase = (str) => {
  // Split the string at all space characters
  return (
    str
      .split("-")
      // get rid of any extra spaces using trim
      .map((a) => a.trim())
      // Convert first char to upper case for each word
      .map((a) => a[0].toUpperCase() + a.substring(1))
      // Join all the strings back together
      .join(" ")
  );
};

export const getDateTimeFormatted = (date_to_be_formatted) => {
  const date = moment(date_to_be_formatted),
    today = moment();

  const diffHours = today.diff(date, "hours"),
    diffDays = today.diff(date, "days"),
    diffMonths = today.diff(date, "months"),
    diffYears = today.diff(date, "year");

  if (
    diffYears === 0 &&
    diffMonths === 0 &&
    diffDays === 0 &&
    diffHours === 0
  ) {
    return `Posted now`;
  } else if (
    diffYears === 0 &&
    diffMonths === 0 &&
    diffDays === 0 &&
    diffHours === 1
  ) {
    return `Posted 1 hour ago`;
  } else if (diffYears === 0 && diffMonths === 0 && diffDays === 0) {
    return `Posted ${diffHours} hours ago`;
  } else if (diffYears === 0 && diffMonths === 0 && diffDays === 1) {
    return `Posted 1 day ago`;
  } else if (diffYears === 0 && diffMonths === 0 && diffDays > 1) {
    return `Posted ${diffDays} days ago`;
  } else if (diffYears === 0 && diffMonths === 1) {
    return `Posted 1 month ago`;
  } else if (diffYears === 0 && diffMonths > 1) {
    return `Posted ${diffMonths} months ago`;
  } else if (diffYears === 1) {
    return `Posted 1 year ago`;
  } else if (diffYears > 1) {
    return `Posted ${diffYears} years ago`;
  }
};

export const transformIntoLabels = (data, array) => {
  if (Array.isArray(data) && data.length) {
    return data
      .filter((i) => includes(array, i.id))
      .map((d) => {
        return {
          label: d.name,
          value: d.id,
        };
      });
  } else if (data) {
    if (includes(array, data.id)) {
      return {
        label: data.name,
        value: data.id,
      };
    }
  }
  return data;
};

export const getInitials = (title = "") => {
  const matches = title && title !== null ? title.match(/\b(\w)/g) : [];
  if (matches && matches !== null) {
    return matches.splice(0, 2).join("").toLocaleUpperCase();
  }
  return "";
};

export const numberFormatter = (num) => {
  return Math.abs(num) > 999
    ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
    : Math.sign(num) * Math.abs(num);
};

export const validateAttribute = (value = "") => {
  return !(!value || isUndefined(value) || isNull(value) || isEmpty(value));
};

export const addMicrosoftClarity = () => {
  (function (c, l, a, r, i, t, y) {
    c[a] =
      c[a] ||
      function () {
        (c[a].q = c[a].q || []).push(arguments);
      };
    t = l.createElement(r);
    t.async = 1;
    t.src = "https://www.clarity.ms/tag/" + i;
    y = l.getElementsByTagName(r)[0];
    y.parentNode.insertBefore(t, y);
  })(window, document, "clarity", "script", "iez185p2wk");
};

export const checkInvalidValue = (value = "") => {
  let invalid = false;

  if (!value || isUndefined(value) || isNull(value)) invalid = true;
  else if (typeof value == "number") invalid = isEmpty(value.toString());
  else invalid = isEmpty(value);

  return invalid;
};

export const setMSClarityTagsAfterLogin = (user, isSsoUser = false) => {
  if (window.clarity) {
    window.clarity("identify", user.id.toString());
    window.clarity("set", "user_id", user.id.toString());
    window.clarity("set", "user_email", user.email);
    window.clarity("set", "user_name", user.name);
    window.clarity("set", "user_school_id", user.school_id.toString());
    window.clarity("set", "user_school_name", user.school_name);
    window.clarity("set", "user_isSsoUser", isSsoUser);
  }
};

export const setMSClarityTagsOnLogin = (email) => {
  if (window.clarity) {
    window.clarity("set", "user_email", email);
  }
};

export const setMSClarityTagsOnNonInvitedSignUp = (user) => {
  if (window.clarity) {
    window.clarity("set", "user_firstname", user.firstName);
    window.clarity("set", "user_lastname", user.lastName);
    window.clarity("set", "user_graduation_year", user.graduationYear);
    window.clarity("set", "user_major", user.major);
    window.clarity("set", "user_college", user.college);
  }
};

export const setMSClarityTagsOnInvitedSignUp = (user) => {
  if (window.clarity) {
    window.clarity("set", "user_firstname", user.first_name);
    window.clarity("set", "user_lastname", user.last_name);
    window.clarity("set", "user_account_type", user.account_type);
    window.clarity("set", "user_graduation_year", user.graduation_year);
    window.clarity("set", "user_degree", user.degree);
    window.clarity("set", "user_major", user.major);
    window.clarity("set", "user_country_of_origin", user.country_of_origin);
  }
};

export const existsMulti = (array, search1, search2, key1, key2) => {
  if (array) {
    return array.some((row) => {
      return row[key1] === search1 && row[key2] === search2;
    });
  }
  return false;
};

export const convertTime12To24 = (time) => {
  var hours = Number(time.match(/^(\d+)/)[1]);
  var minutes = Number(time.match(/:(\d+)/)[1]);
  if (time.indexOf("pm") !== -1 && hours < 12) hours = hours + 12;
  if (time.indexOf("am") !== -1 && hours === 12) hours = hours - 12;
  var sHours = hours.toString();
  var sMinutes = minutes.toString();
  if (hours < 10) sHours = "0" + sHours;
  if (minutes < 10) sMinutes = "0" + sMinutes;
  return sHours + ":" + sMinutes;
};

export const sortDateTimeArray = (array, key = "date") => {
  array.sort(function (a, b) {
    let dateTimeA = new Date(a[key] + " " + convertTime12To24(a.start_time));
    let dateTimeB = new Date(b[key] + " " + convertTime12To24(b.start_time));
    return dateTimeA - dateTimeB;
  });
};

export const trapFocus = (element) => {
  if (element) {
    var focusableEls = element.querySelectorAll(
      'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])'
    );
    var firstFocusableEl = focusableEls[0];
    var lastFocusableEl = focusableEls[focusableEls.length - 1];
    var KEYCODE_TAB = 9;
    element.addEventListener("keydown", function (e) {
      var isTabPressed = e.key === "Tab" || e.keyCode === KEYCODE_TAB;

      if (!isTabPressed) {
        return;
      }

      if (e.shiftKey) {
        if (document.activeElement === firstFocusableEl) {
          lastFocusableEl.focus();
          e.preventDefault();
        }
      } else {
        if (document.activeElement === lastFocusableEl) {
          firstFocusableEl.focus();
          e.preventDefault();
        }
      }
    });
  }
};

/**
 * Method calls update last session api. Updates local storage after api call
 * @param {number} timeGap in milliseconds between last active and current time
 */
export const updateLastActiveSession = async (timeGap = 60000) => {
  let localStorageState = JSON.parse(localStorage.getItem("interstride"));
  if (localStorageState && localStorageState.users) {
    const currentLastActiveSession = new Date(
      localStorageState.users.last_active_session_at
    );
    const currentDataTime = new Date();
    if (currentDataTime - currentLastActiveSession > timeGap) {
      const response = await updateLastActiveSessionAPI();
      if (response.success) {
        // Update local storage
        localStorageState.users.last_active_session_at =
          response.data.last_active_session_at;
        localStorage.setItem("interstride", JSON.stringify(localStorageState));
      }
    }
  }
};
export const getUserFromList = (list, id) => {
  let data = list.find((l) => {
    return l.id === id;
  });
  return data;
};

export const formatOptions = (data) => {
  if (data) {
    let formattedData = [];
    formattedData = data.map((item) => {
      return {
        label: item,
        value: item,
      };
    });
    return formattedData;
  }
  return null;
};

export const arrayReverseObj = (obj) =>
  Object.keys(obj)
    .sort()
    .reverse()
    .map((key) => ({ ...obj[key] }));

export const processJobLocationTime = ({
  city = null,
  state = null,
  country = null,
  time = null,
}) => {
  let result = [];
  if (city && city?.length > 0 && city != "null")
    result.push(capitalizeFirstLetter(city));
  if (state && state?.length > 0 && state != "null") result.push(state);
  if (country && country?.length > 0 && country != "null") {
    result.push(country);
  }

  result = result?.join(", ");

  if (time && time?.length > 0) {
    if (result?.length > 0) {
      result += " | " + getDateTimeFormatted(time);
    } else {
      result += getDateTimeFormatted(time);
    }
  }
  return result;
};

/**
 * Method extracts unique and grouped records in jobs array.
 * @param {Array} jobs gained from the BE API
 * @param {Array} grouped_state_jobs useJobs state variable to check existing jobs grouping
 * @returns {Object} {uniqueJobs, groupedJobs}
 */
export const extractUniqueAndGroupJobs = (
  jobs = [],
  grouped_state_jobs = {},
  variant = "search"
) => {
  let uniqueJobs = [];

  jobs.forEach((formattedJob) => {
    let uniqueMatch = false;

    if (variant === "search") {
      uniqueMatch = uniqueJobs.find(
        (element) =>
          element?.job_title === formattedJob.job_title &&
          element?.company === formattedJob.company
      );
    }

    if (variant === "employer") {
      uniqueMatch = uniqueJobs.find(
        (element) =>
          element?.title === formattedJob.title &&
          element?.name === formattedJob?.name
      );
    }

    if (variant === "admin") {
      uniqueMatch = uniqueJobs.find(
        (element) =>
          element?.job_title === formattedJob.job_title &&
          element?.company === formattedJob?.company
      );
    }

    if (variant === "saved") {
      uniqueMatch = uniqueJobs.find(
        (element) =>
          element?.job_title === formattedJob.job_title &&
          element?.company_name === formattedJob?.company_name
      );
    }

    if (uniqueMatch) {
      // See if job already present in uniqueJobs
      // Add to groupedJobs with parentId
      if (variant === "employer") {
        const parentId = formattedJob?.title + formattedJob?.name;
        if (grouped_state_jobs[parentId]) {
          grouped_state_jobs[parentId] = [
            ...grouped_state_jobs[parentId],
            formattedJob,
          ];
        } else {
          grouped_state_jobs[parentId] = [uniqueMatch, formattedJob];
        }
      }
      if (variant === "search") {
        // Both the jobs are same location jobs with different providers
        if (
          formattedJob?.interstride_location &&
          formattedJob?.interstride_location ===
            uniqueMatch?.interstride_location
        ) {
          const ZIP_RECRUITER_SOURCE = "ziprecruiter";
          // We need to prioritize ZIP recruiter jobs when multiple same jobs are found from different source
          if (
            formattedJob?.source === ZIP_RECRUITER_SOURCE &&
            uniqueMatch?.source !== ZIP_RECRUITER_SOURCE
          ) {
            // Replace this priority job with same spec job
            uniqueJobs = uniqueJobs.filter(
              (job) => job?.id !== uniqueMatch?.id
            );
            uniqueJobs.push(formattedJob);
          }
          // Else skip adding this job as other job is already added
        }
        // Both the jobs have different location
        else {
          const parentId = formattedJob?.job_title + formattedJob?.company;
          if (grouped_state_jobs[parentId]) {
            grouped_state_jobs[parentId] = [
              ...grouped_state_jobs[parentId],
              formattedJob,
            ];
          } else {
            grouped_state_jobs[parentId] = [uniqueMatch, formattedJob];
          }
          // If any of the child jobs is sponsored jobs then parent should show that tag
          if (formattedJob?.sponsored_last_year) {
            grouped_state_jobs[parentId][0].sponsored_last_year = true;
          }
        }
      }
      if (variant === "admin") {
        const parentId = formattedJob?.job_title + formattedJob?.company;
        if (grouped_state_jobs[parentId]) {
          grouped_state_jobs[parentId] = [
            ...grouped_state_jobs[parentId],
            formattedJob,
          ];
        } else {
          grouped_state_jobs[parentId] = [uniqueMatch, formattedJob];
        }
      }
      if (variant === "saved") {
        const parentId = formattedJob?.job_title + formattedJob?.company_name;
        if (grouped_state_jobs[parentId]) {
          grouped_state_jobs[parentId] = [
            ...grouped_state_jobs[parentId],
            formattedJob,
          ];
        } else {
          grouped_state_jobs[parentId] = [uniqueMatch, formattedJob];
        }
      }
    } else {
      // Add job if not present
      uniqueJobs.push(formattedJob);
    }
  });

  return { uniqueJobs, grouped_state_jobs };
};

// Computes and formats delivery time of message
export const deliveryTime = (time) => {
  if (time) {
    return new Date(time)
      .toLocaleString("default", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      })
      .toLocaleLowerCase();
  } else return "";
};

export const createFriendlyTitle = (title = "") => {
  return title?.length > 0
    ? title
        ?.split(" ")
        .map((name) => name.charAt(0).toUpperCase() + name.substring(1))
        .join(" ")
    : "";
};

export const getValidSearchText = (value = "") => {
  return value.replace(/[^a-zA-Z0-9\s@.~_-]/g, "");
};

export const getCapitalizedHeader = (str = "") => {
  return str?.length > 0 ? str?.charAt(0)?.toUpperCase() + str?.slice(1) : "";
};

export const getClickableLink = (link = "") => {
  return link.startsWith("http://") || link.startsWith("https://")
    ? link
    : `https://${link}`;
};

export const getLocalTzAbbr = () => {
  //initialization:
  const eventDate = moment()?.local()?.format(); //any dynamic value
  const visitorTz = momentTz?.tz?.guess();
  const momentDateTime = moment?.utc(eventDate)?.tz(visitorTz);

  //compare first 3 letters to check if abbr available for this timezone:
  const z1 = momentDateTime?.format("Z"); //eg. '+06:00'
  const z2 = momentDateTime?.format("z"); //eg. PDT|AEST|IST|'+06'
  const tzAbbr = z1.substring(0, 3) === z2.substring(0, 3) ? `GMT ${z1}` : z2;

  return tzAbbr;
};

export const applyWidgetTheme = (color) => {
  const body = document.querySelector("body");
  body.style.setProperty("--widget-theme-color", color);
};

export const compareVersions = (previousVersion, currentVersion) => {
  try {
    const [prevMajor, prevMinor = 0, prevPatch = 0] = previousVersion
      .split(".")
      .map(Number);
    const [curMajor, curMinor = 0, curPatch = 0] = currentVersion
      .split(".")
      .map(Number);

    if (curMajor > prevMajor) {
      return "major";
    }
    if (curMinor > prevMinor) {
      return "minor";
    }
    if (curPatch > prevPatch) {
      return "patch";
    }
    if (curPatch < prevPatch) {
      return "patch downgrade";
    }
    return "same/downgrade";
  } catch (e) {
    return "invalid format";
  }
};

export const clearCacheData = () => {
  caches.keys().then((names) => {
    names.forEach((name) => {
      caches.delete(name);
    });
  });
};

export const clearLocalStorage = () => {
  // session storage must be cleared with localstorage
  sessionStorage.clear();

  const storedAppVersion = localStorage.getItem("app_version");
  const storedJobTutorialStatus =
    JSON.parse(localStorage.getItem(`users_jobs_tutorial_status`)) || [];
  const storedFeedbackDialogTimestamp = localStorage.getItem(
    `feedback_dialog_timestamp`
  );
  const storedSkipDMModal = localStorage.getItem("skip_dm_modal");

  localStorage.clear();

  if (storedAppVersion) localStorage.setItem("app_version", storedAppVersion);
  if (storedJobTutorialStatus)
    localStorage.setItem(
      `users_jobs_tutorial_status`,
      JSON.stringify(storedJobTutorialStatus)
    );
  if (storedSkipDMModal)
    localStorage.setItem("skip_dm_modal", storedSkipDMModal);
  if (storedFeedbackDialogTimestamp)
    localStorage.setItem(
      `feedback_dialog_timestamp`,
      storedFeedbackDialogTimestamp
    );
};

export const INTERSTRIDE_DEFAULT_LOGO =
  "https://interstride.s3.amazonaws.com/branding/brand-header-default-logo.svg";

export const INTERSTRIDE_EMBLEM =
  "https://interstride.s3.amazonaws.com/branding/square-emblem-logo.svg";

export const CDC_ICON =
  "https://interstride.s3.amazonaws.com/miscellaneous/assets/icon-cdc.png";
export const APP_AD_IMAGE =
  "https://interstride.s3.amazonaws.com/miscellaneous/assets/app-ad.png";

export const addCustomEllipsis = (text, maxLength) => {
  if (text.length <= maxLength) return text;
  return `${text.substring(0, maxLength - 3)}...`;
};

export const getUserWindow = () => {
  let window_name = "";
  if (!navigator.userAgent) return window_name;

  if (navigator.userAgent.indexOf("Win") !== -1) window_name = "Windows OS";
  else if (navigator.userAgent.indexOf("Mac") !== -1) window_name = "Macintosh";
  else if (navigator.userAgent.indexOf("Linux") !== -1)
    window_name = "Linux OS";
  else if (navigator.userAgent.indexOf("Android") !== -1)
    window_name = "Android OS";
  else if (navigator.userAgent.indexOf("like Mac") !== -1) window_name = "iOS";
  return window_name;
};

export const getUserBrowser = () => {
  let browser_name = "";
  if (!navigator.userAgent) return browser_name;

  browser_name = navigator.userAgent.match(/chrome|chromium|crios/i)
    ? "chrome"
    : navigator.userAgent.match(/firefox|fxios/i)
    ? "firefox"
    : navigator.userAgent.match(/safari/i)
    ? "safari"
    : navigator.userAgent.match(/opr\//i)
    ? "opera"
    : navigator.userAgent.match(/edg/i)
    ? "edge"
    : "";

  return browser_name;
};

export const getUserDeviceDetails = () => {
  return {
    app_version: localStorage.getItem("app_version") ?? "not_available",
    device_name: getUserBrowser(),
    device_token: localStorage.getItem("firebaseToken") ?? "not_available",
    os_version: getUserWindow(),
    platform: 302,
  };
};

export const trackMixpanelEvent = (title) => {
  // JOBS
  if (title === "Jobs Dashboard") {
    Mixpanel.track(MixpanelEvents.jobs.job_dashboard);
  } else if (title === "Find Jobs") {
    Mixpanel.track(MixpanelEvents.jobs.search_jobs);
  } else if (title === "Job Alerts") {
    Mixpanel.track(MixpanelEvents.jobs.job_alerts);
  } else if (title === "U.S. Visa Insights") {
    Mixpanel.track(MixpanelEvents.jobs.us_visa_insight);
  } else if (title === "Country Insights") {
    Mixpanel.track(MixpanelEvents.jobs.country_insight);
  } else if (title === "Employment Data") {
    Mixpanel.track(MixpanelEvents.jobs.employment_data);
  }
  // NETWORK
  else if (title === "Network Dashboard") {
    Mixpanel.track(MixpanelEvents.network.network_dashboard);
  } else if (title === "Topics" || title === "Community") {
    Mixpanel.track(MixpanelEvents.network.topics);
  } else if (title === "Discover Network") {
    Mixpanel.track(MixpanelEvents.network.discover_network);
  }
  // LEARN
  else if (title === "Learn Dashboard") {
    Mixpanel.track(MixpanelEvents.learn.learn_dashboard);
  } else if (title === "Webinars") {
    Mixpanel.track(MixpanelEvents.learn.webinars);
  } else if (title === "Courses") {
    Mixpanel.track(MixpanelEvents.learn.courses);
  } else if (title === "Resources") {
    Mixpanel.track(MixpanelEvents.learn.resources);
  } else if (title === "Student Services") {
    Mixpanel.track(MixpanelEvents.learn.student_services);
  }
  // TOOLS
  else if (title === "Tools Dashboard") {
    Mixpanel.track(MixpanelEvents.tools.tools_dashboard);
  } else if (title === "Career Gameplan") {
    Mixpanel.track(MixpanelEvents.tools.career_gameplan);
  } else if (title === "OPT Calculator") {
    Mixpanel.track(MixpanelEvents.tools.opt_calculator);
  } else if (title === "Checklist") {
    Mixpanel.track(MixpanelEvents.tools.checklist);
  } else if (title === "Meet") {
    Mixpanel.track(MixpanelEvents.tools.meet);
  }
  // COMMON
  else if (title === "Home Dashboard") {
    Mixpanel.track(MixpanelEvents.common.dashboard);
  } else if (title === "Messages") {
    Mixpanel.track(MixpanelEvents.common.messages);
  } else if (title === "Help Center") {
    Mixpanel.track(MixpanelEvents.common.help_center);
  } else if (title === "Profile") {
    Mixpanel.track(MixpanelEvents.common.profile);
  } else if (title === "Notifications") {
    Mixpanel.track(MixpanelEvents.common.notifications);
  }
};

// Common notification config function to get consistent result for notification dialog and listing screen
export const getNotificationConfig = (type, options = {}) => {
  /** Calendar & job type is remaining not getting in response */
  const queryString = Object.keys(options)
    .map((option) => `${option}=${options[option]}`)
    .join("&");
  const prepareURL = (path) =>
    Object.keys(options).length > 0 ? `${path}?${queryString}` : path;

  switch (type) {
    case "network_messages":
    case "group_messages":
    case "missed_message":
      return {
        label: "View Network",
        url: routes.NETWORK.MESSENGEN,
        icon: MessageIcon,
        category: "Messages",
      };
    case "new_topics":
    case "created_topics":
    case "followed_topics":
      return {
        label: "View Community",
        url: prepareURL(routes.NETWORK.TOPICS),
        icon: MyTopicsIcon,
        category: "Communities",
      };
    case "follow_requests":
      return {
        label: "View Community",
        url: routes.NETWORK.TOPICS,
        icon: MyTopicsIcon,
        category: "Communities",
      };
    case "user_post":
      return {
        label: "View Post",
        url: prepareURL(routes.DASHBOARD),
        icon: postIcon,
        category: "Post",
      };
    case "webinar":
      return {
        label: "View Webinar",
        url: prepareURL(routes.LEARN.WEBINARS),
        icon: webinarsIcon,
        category: "Webinars",
      };
    case "deal":
      return {
        label: `View Service`,
        url: prepareURL(routes.LEARN.STUDENT_SERVICES),
        icon: studentServiceIcon,
        category: "Student services",
      };
    case "video":
      return {
        label: `View Resource`,
        url: prepareURL(routes.LEARN.RESOURCES),
        icon: resourceIcon,
        category: "Resources",
      };
    case "appointment":
      return {
        label: `View Appointment`,
        url: prepareURL(routes.TOOLS.MEET),
        icon: calendarIcon,
        category: "Appointment",
      };
    case "featured_job":
    case "employer_job":
      return {
        label: `View Job`,
        url: prepareURL(routes.JOBS.FEATURED_JOBS),
        icon: searchJobsIcon,
        category: "Jobs",
      };
    default:
      return { label: `View`, url: "/", icon: DefaultIcon };
  }
};

export const INITIAL_VISA_FILTERS = {
  job_classification: null,
  job_title: null,
  visa: "h1b",
  year: 2024,
  industryType: "industry",
  industry: null,
  company: null,
  city: null,
  state: null,
  experience: null,
  graduationYear: null,
  functionFilter: null,
  presetFilter: null,
};

export const sortLabelsAndData = (input) => {
  const { labels, data } = input;

  // Combine labels and data into an array of objects
  const combined = labels.map((label, index) => ({
    label,
    value: data[index],
  }));

  // Sort the combined array by label
  combined.sort((a, b) => a.label.localeCompare(b.label));

  // Separate the sorted labels and data back into their respective arrays
  const sortedLabels = combined.map((item) => item.label);
  const sortedData = combined.map((item) => item.value);

  return { labels: sortedLabels, data: sortedData };
};

export const parseEmploymentType = (employment_type) =>
  employment_type === "stem_opt"
    ? "STEM OPT"
    : employment_type === "full_time"
    ? "Full-time"
    : employment_type === "opt"
    ? "OPT"
    : employment_type === "cpt"
    ? "CPT"
    : employment_type;

export const parseEmploymentPayload = (employment_type) =>
  employment_type === "STEM OPT"
    ? "stem_opt"
    : employment_type === "Full-time"
    ? "full_time"
    : employment_type === "OPT"
    ? "opt"
    : employment_type === "CPT"
    ? "cpt"
    : employment_type === "N/A"
    ? ""
    : employment_type;
