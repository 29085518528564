import React, { useEffect } from "react";
import { useDebouncedCallback } from "use-debounce";

import MuiTable from "../../../../components/atoms/MuiTable/MuiTable";
import DropdownCustom from "../../../../components/DropdownCustom/DropdownCustom";
import TextboxCustom from "../../../../components/Textbox/TextboxCustom";
import { createModuleStyleExtractor } from "../../../../utils/css";
import { getGraphTableData } from "../../../../services/employmentDataService";
import { dataTableHeaders } from "../Utils/EmploymentDataConstants";
import {
  getValidSearchText,
  createFriendlyTitle,
  getCapitalizedHeader,
  parseEmploymentType,
  parseEmploymentPayload,
} from "../../../../helper/helper";
import styles from "./EmploymentDataTable.module.scss";

import FilterIcon from "../../../../assets/Icons/FilterIcon";

const cx = createModuleStyleExtractor(styles);

export default function EmploymentDataTable({
  widget = false,
  tableState,
  setTableState = () => {},
  tableSearch = "",
  setTableSearch = () => {},
  openFilter,
  toggleFilter = () => {},
  filters,
  dataSchool = null,
}) {
  // Call table data api upon filter change change and component load
  useEffect(() => {
    getOPTListingData({
      page: 1,
      per_page: 10,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableState?.selectedFilters, dataSchool]);

  // Debounce search callback
  const debouncedSearchCallback = useDebouncedCallback(async (value) => {
    setTableState((current) => ({
      ...current,
      loader: true,
    }));
    getOPTListingData({ search: value });
  }, 1000);

  const handlePaginationChange = (page, per_page) => {
    getOPTListingData({ search: null, page, per_page });
  };

  const getOPTListingData = async ({
    search = null,
    page = null,
    per_page = null,
    order_by = null,
    order_direction = null,
  }) => {
    try {
      let response = {};
      if (widget) {
        // Dummy sample data for widget
        response = {
          success: true,
          message: "Success",
          data: {
            opts: [
              {
                id: 839,
                school_id: 12295,
                company: "Deolittle & Touche LLP",
                job_title: "Cyber Advisory Analyst",
                year: "2022",
                major: "Information Technology Project Management",
                degree: "Associate",
                nationality: "Canada",
                created_at: "2023-12-15T09:03:57.000Z",
                updated_at: "2023-12-15T09:03:57.000Z",
                employment_type: "OPT",
              },
              {
                id: 840,
                school_id: 12295,
                company: "Devior Software Solutions",
                job_title: "Data Engineer",
                year: "2022",
                major: "Information Technology Project Management",
                degree: "Degree\r",
                nationality: "India",
                created_at: "2023-12-15T09:03:57.000Z",
                updated_at: "2023-12-15T09:03:57.000Z",
                employment_type: "OPT",
              },
              {
                id: 841,
                school_id: 12295,
                company: "Redifcard Technologies LLC",
                job_title: "Data Analyst",
                year: "2022",
                major: "Information Technology Project Management",
                degree: "Bachelor",
                nationality: "India",
                created_at: "2023-12-15T09:03:57.000Z",
                updated_at: "2023-12-15T09:03:57.000Z",
                employment_type: "OPT",
              },
            ],
          },
        };
      } else {
        const { major, year, nationality, degree, employment_type, country } =
          tableState?.selectedFilters || {};

        response = await getGraphTableData({
          dataSchool: dataSchool,
          page: page || tableState.page,
          per_page: per_page || tableState.per_page,
          search: search?.trim() || tableSearch?.trim(),
          major: major?.value,
          year: year?.value,
          nationality: nationality?.value,
          degree: degree?.value,
          employment_type: parseEmploymentPayload(employment_type?.value),
          country: country?.value,
          order_by: order_by || tableState?.order_by,
          order_direction: order_direction || tableState?.order_direction,
        });
      }
      if (response?.success && response?.data?.opts?.length > 0) {
        // Process listing data
        let _data = [];
        for (let i = 0; i < response.data.opts.length; i++) {
          const dataRecord = response?.data?.opts[i] || {};
          _data.push([
            getCapitalizedHeader(dataRecord?.company),
            getCapitalizedHeader(dataRecord?.job_title),
            getCapitalizedHeader(dataRecord?.degree),
            createFriendlyTitle(dataRecord?.major),
            createFriendlyTitle(dataRecord?.country),
            dataRecord?.year === "0" ? "-" : dataRecord?.year,
            parseEmploymentType(dataRecord?.employment_type),
            createFriendlyTitle(dataRecord?.nationality),
          ]);
        }
        setTableState((current) => ({
          ...current,
          loader: false,
          data: _data,
          page: parseInt(response?.data?.current_page),
          per_page: per_page || tableState.per_page,
          total_pages: response?.data?.total_pages,
        }));
        // Scroll to top
        window.scrollTo({ top: 0, behavior: "smooth" });
        return;
      }
      setTableState((current) => ({
        ...current,
        loader: false,
        data: null,
        page: 1,
        per_page: 10,
        total_pages: 1,
      }));
    } catch (error) {
      setTableState((current) => ({
        ...current,
        loader: false,
        data: null,
        page: 1,
        per_page: 10,
        total_pages: 1,
      }));
    }
  };

  const changeSortingFn = (orderByName, order_direction) => {
    // Process propertyName to keyname for BE API
    let order_by = null;
    if (orderByName === "Job Title") order_by = "job_title";
    else if (orderByName === "Type") order_by = "employment_type";
    else if (orderByName === "Job location") order_by = "country";
    else if (orderByName === "Year joined") order_by = "year";
    else order_by = orderByName?.toLowerCase();

    // Update table state
    setTableState((current) => ({
      ...current,
      order_by,
      order_direction,
    }));

    getOPTListingData({
      search: tableSearch,
      page: tableState.page,
      per_page: tableState.per_page,
      order_by,
      order_direction,
    });
  };

  //  CPT, OPT and STEM OPT are only relevant for US
  const onEmploymentTypeChange = (option) => {
    const isUSSpecific =
      option?.value &&
      (option.value === "CPT" ||
        option.value === "OPT" ||
        option.value === "STEM OPT");
    // clearing residue country value conditionally

    setTableState((current) => ({
      ...current,
      selectedFilters: {
        ...current.selectedFilters,
        country: isUSSpecific ? null : current.selectedFilters.country,
        employment_type: option,
      },
    }));
  };

  return (
    <div
      className={cx([
        "m-emp-table",
        ...(widget ? ["m-emp-table--widget"] : []),
      ])}
    >
      {!widget && (
        <div
          className={`collapsible-section ${
            openFilter ? "collapsible-section--open" : ""
          }`}
        >
          <div className={cx("m-emp-table__header")}>
            <div id={"collapsible-section-header"}>
              <h2>Filters</h2>
              <a
                href="/#"
                onClick={(e) => {
                  e.preventDefault();
                  toggleFilter(!openFilter);
                }}
                id="collapsible-section-header-icon"
              >
                <FilterIcon fill={openFilter} />
              </a>
            </div>
            <div id="collapsible-section-search-filter">
              <div className={cx("m-emp-table__header__filter")}>
                <TextboxCustom
                  name="search"
                  type="text"
                  placeholder="Search"
                  lableaignment="left"
                  value={tableSearch}
                  onChange={(e) => {
                    const text = getValidSearchText(e.target.value);
                    setTableSearch(text);
                    debouncedSearchCallback(text);
                  }}
                  isDisable={tableState.loader}
                />
              </div>
              <label>Filter</label>
              <div className={cx("m-emp-table__header__filter--degree")}>
                <DropdownCustom
                  name="degree"
                  placeholder="Degree"
                  value={tableState?.selectedFilters?.degree}
                  justifyContent="left"
                  options={filters?.degree}
                  onChange={(option) =>
                    setTableState((current) => ({
                      ...current,
                      selectedFilters: {
                        ...current.selectedFilters,
                        degree: option,
                      },
                    }))
                  }
                  isClearable
                />
              </div>
              <div className={cx("m-emp-table__header__filter")}>
                <DropdownCustom
                  name="major"
                  placeholder="Major"
                  value={tableState?.selectedFilters?.major}
                  justifyContent="left"
                  options={filters?.major}
                  onChange={(option) =>
                    setTableState((current) => ({
                      ...current,
                      selectedFilters: {
                        ...current.selectedFilters,
                        major: option,
                      },
                    }))
                  }
                  isClearable
                />
              </div>
              <div className={cx("m-emp-table__header__filter--year")}>
                <DropdownCustom
                  name="year"
                  placeholder="Year"
                  value={tableState?.selectedFilters?.year}
                  justifyContent="left"
                  options={filters?.year}
                  onChange={(option) =>
                    setTableState((current) => ({
                      ...current,
                      selectedFilters: {
                        ...current.selectedFilters,
                        year: option,
                      },
                    }))
                  }
                  isClearable
                />
              </div>
              {tableState?.selectedFilters?.employment_type?.value === "CPT" ||
              tableState?.selectedFilters?.employment_type?.value === "OPT" ||
              tableState?.selectedFilters?.employment_type?.value ===
                "STEM OPT" ? (
                <></>
              ) : (
                <div
                  className={cx("m-emp-table__header__filter--job_location")}
                >
                  <DropdownCustom
                    name="country"
                    placeholder="Job Location"
                    value={tableState?.selectedFilters?.country}
                    justifyContent="left"
                    options={filters?.country}
                    onChange={(option) =>
                      setTableState((current) => ({
                        ...current,
                        selectedFilters: {
                          ...current.selectedFilters,
                          country: option,
                        },
                      }))
                    }
                    isClearable
                  />
                </div>
              )}
              <div className={cx("m-emp-table__header__filter--type")}>
                <DropdownCustom
                  name="Employment Type"
                  placeholder="Type"
                  value={tableState?.selectedFilters?.employment_type}
                  justifyContent="left"
                  options={filters?.employment_type}
                  onChange={onEmploymentTypeChange}
                  isClearable
                />
              </div>
              <div className={cx("m-emp-table__header__filter--nationality")}>
                <DropdownCustom
                  name="nationality"
                  placeholder="Nationality"
                  value={tableState?.selectedFilters?.nationality}
                  justifyContent="left"
                  options={filters?.nationality}
                  onChange={(option) =>
                    setTableState((current) => ({
                      ...current,
                      selectedFilters: {
                        ...current.selectedFilters,
                        nationality: option,
                      },
                    }))
                  }
                  isClearable
                />
              </div>
            </div>
          </div>
        </div>
      )}

      <div className={cx("m-emp-table__body")}>
        <MuiTable
          tableHeaders={dataTableHeaders}
          tableData={tableState.data}
          pagination={widget ? false : true}
          handlePaginationChange={handlePaginationChange}
          page={tableState.page}
          total_pages={tableState?.total_pages}
          tableLoader={tableState?.loader}
          per_page={tableState?.per_page}
          noDataTextHeader="Looks like there are no employment data with this search."
          noDataTextBody="Try removing some filters to expand your search and get more results."
          changeSortingFn={changeSortingFn}
        />
      </div>
    </div>
  );
}
