import React, { memo, useEffect, useRef } from "react";
import { ShimmerCategoryList } from "react-shimmer-effects";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { useFetchConversation } from "../../../../hooks/useFetchConversation";
import Messages from "../Messages/Messages";
import ReceiverProfile from "../ReceiverProfile/ReceiverProfile";
import GroupProfile from "../GroupProfile/GroupProfile";
import Textbox from "../TextBox/TextBox";
import InitChatStyles from "./InitChatStyles";
import { useAppState } from "../../../../context";
import { extractMessage } from "../../../../utils/common";
import {
  fetchChatsList,
  fetchConversation,
  sendChatMessageAttachmentAPI,
  createGroupChatAPI,
  updateGroupChatAPI,
} from "../../../../services/chat.service";
import EmptyChatView from "../EmptyChatView/EmptyChatView";
import SavedPostsWidget from "../../../SavedPostWidget/SavedPostWidget";
import { routes } from "../../../../routes";
import { toastify } from "../../../../helper/helper";
import { chatFilterOptions } from "../../../../utils/common";

const InitChat = ({ redirectUserId }) => {
  const navigate = useNavigate();
  const {
    chats,
    attachment,
    setChats,
    isFreshChat,
    setGroupTags,
    pushNewMessage,
    setReloadChats,
    setIsAttachment,
    updateGroupDetails,
    setIsGroupCreating,
    activeConversation,
    setIsMessageSending,
    updateLatestMessage,
    removeUserFromFreshChat,
    setCurrentConversationId,
    setConversationInformation,
    setCurrentSearchMessageItem,
    getChatPreviewByConversationId,
    getConversationByConversationID,
    removeConversationByConversationID,
    setTriggerReloadUnreadCountUpdate,
    groupCreateRequestData,
    setGroupCreateRequestData,
    isGroupCreating,
    favoriteUsersCount,
    setChatFilterTab,
    broadcastMessage,
    setConversationChannelBroadcast,
    broadcastMessageReactions,
    setConversationReactionsChannelBroadcast,
    replyChatData,
    updateMessageReplyData,
  } = useAppState("chat");
  const { users = {} } = useSelector((store) => store.userStore);
  const { id: userId } = users || {};

  const preview = getChatPreviewByConversationId();
  const { conversation, loading, fresh } = useFetchConversation(redirectUserId);
  const { current_page } = conversation || {};
  const { receiver_id } = preview || {};

  const { conversationChannel } = useSelector((store) => store.webSocketStore);

  const container = useRef();
  const isUserDeactivated =
    preview &&
    preview?.receiver_id !== "" &&
    (preview?.user_deleted || preview?.user_blocked);

  /**
   * Trigger when message is submitted
   * @param {String} content
   * @param {File} attachment
   * @param {Number} parent_message_id
   */
  const onSubmit = async (
    content = null,
    attachment = null,
    parent_message_id = null
  ) => {
    const { group_details, chat } = conversation || {};

    if (attachment) {
      const response = await sendChatMessageAttachmentAPI(
        chat?.conversation_window_id,
        attachment.content,
        content
      );
      setIsMessageSending(false);
      if (response.success && response.data) {
        setConversationChannelBroadcast({
          ...response?.data,
          receiver_id: response?.data?.sender_id,
          read_by: [response?.data?.sender_id],
          receiver_conversation_id: response?.data?.sender_conversation_id,
        });
      } else {
        toastify("error", response.message);
      }
    } else {
      const { conversation_window_id } = group_details || {};
      const body = {
        content,
        ...(receiver_id && {
          receiver_id,
          conversation_window_id: chat?.conversation_window_id || null,
        }),
        ...(!receiver_id &&
          conversation_window_id && { conversation_window_id }),
        ...(parent_message_id ? { parent_message_id } : {}),
      };
      setCurrentSearchMessageItem(null);
      // Send admission portal status if conversation belongs to admission portal
      if (
        chats &&
        chats.filter((chat) => chat.conversation_id === activeConversation)[0]
          ?.portal_type === "admission"
      ) {
        body["admission_portal"] = true;
      }
      conversationChannel?.create && conversationChannel.create(body); // Calls websocket speak method
    }
  };

  const scrollToTheBottom = () => {
    if (!current_page || current_page <= 1) return;
    try {
      const element = document.getElementById(
        "student-chat-portal__messages-container"
      );
      if (element) {
        element.scrollTop = element.scrollHeight;
      } else {
        if (container) {
          container.current.scrollTop = container.current.scrollHeight;
        }
      }
    } catch (error) {
      console.log("error", error?.message);
    }
  };
  /**
   * This calls after the message is sent or received via socket
   * @param {*} id
   * @param {*} content
   */
  const postProcessMessageAction = (id, content) => {
    pushNewMessage(id, content, scrollToTheBottom);
    setIsMessageSending(false);
    setIsAttachment(false);
  };

  /**
   * Check if the user is admin of group
   * @param {*} participants
   * @returns
   */
  const isAdmin = (participants, getUser = false) => {
    const item = participants.find((item) => item.user_id === userId);
    if (getUser) {
      return item;
    }
    return item.is_group_admin || false;
  };

  /**
   * Update the group preview; details on right side after it is updated and its messages
   * @param {Array} participants
   */
  const updateGroupPreview = async (participants = []) => {
    try {
      const user = participants?.find((item) => item.user_id === userId);
      if (user) {
        const { conversation_id } = user || {};
        if (conversation_id) {
          const response = await fetchConversation(conversation_id);
          if (response) {
            const group_details = response.data?.group_details;
            const group_messages = response.data?.messages || [];
            if (group_details) {
              updateGroupDetails(
                conversation_id,
                group_details,
                group_messages
              );
            }
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * Calls when message is recieved || sent ; right after receving response from socket and before updating state
   * @param {*} data
   * @returns
   */
  const processMessageAction = async (data) => {
    const {
      receiver_id,
      sender_id,
      receiver_conversation_id,
      sender_conversation_id,
      type,
      group_created,
      group_updated,
      group_user_details,
      error_code,
      error_message,
    } = data || {};

    // Clear text box reply data if present
    if (replyChatData.message_id) {
      updateMessageReplyData(null, null, "", "", "");
    }

    if (error_code || error_message) {
      error_message && toastify("error", error_message);
      setIsGroupCreating(false);
      setIsMessageSending(false);
      setIsAttachment(false);
      if (error_message === "You are no longer part of this conversation.") {
        setReloadChats(true);
      }
      return;
    }

    const message = extractMessage(data);

    /**
     * This is placeholder text for chat preview at left
     * @param {*} meta
     * @returns
     */
    const fetchTextMessage = (meta) => {
      const { message, shared_file } = meta || {};
      if (shared_file) {
        if (!message) return "File Attachment";
      }
      return message || "";
    };

    if (type === "Group Chat") {
      // case when group is either created or updated
      if (group_created || group_updated) {
        setReloadChats(true);

        if (group_created) {
          setGroupTags([]);
          if (chats.length === 0) {
            setReloadChats(true);
          }
        }

        const admin = isAdmin(group_user_details || []);
        if (group_updated) {
          updateGroupPreview(group_user_details || []);
        }

        //if group created by me
        if (admin) {
          setIsGroupCreating(false);
          const _user = isAdmin(group_user_details || [], true);
          const { conversation_id } = _user || {};
          if (conversation_id) {
            setCurrentConversationId(conversation_id);
          }
        }
        return;
      }

      //message sent as group
      if (sender_id === userId && receiver_id === userId) {
        postProcessMessageAction(sender_conversation_id, message);
        updateLatestMessage(fetchTextMessage(message), sender_conversation_id);
        return;
      }
      // message received
      if (userId === receiver_id) {
        setReloadChats(true);
        postProcessMessageAction(receiver_conversation_id, message);
        setTriggerReloadUnreadCountUpdate(true);
      }
      return;
    }

    /// message sent
    if (receiver_id === sender_id || sender_id === userId) {
      //if it is the fresh chat
      if (isFreshChat(activeConversation)) {
        const oldConversationId = activeConversation;
        const conversation = await fetchConversation(
          `${sender_conversation_id}`
        );
        setConversationInformation(sender_conversation_id, conversation.data); // update conversation

        const preview = await fetchChatsList({
          conversation_id: sender_conversation_id,
          filter: chatFilterOptions[0], // with all filter
        }); //retrieve preview

        // Set filter tab to all
        setChatFilterTab(chatFilterOptions[0]);

        if (preview?.data && preview?.data?.conversation) {
          const filtered_chats = chats.filter(
            (chat) => chat.conversation_id !== oldConversationId
          );
          const items = [preview.data.conversation, ...filtered_chats];
          setChats(items);
        } else {
          setReloadChats(true); //reload sidebar chats
        }
        setCurrentConversationId(sender_conversation_id);
        removeUserFromFreshChat(oldConversationId);
        removeConversationByConversationID(oldConversationId);
        setIsMessageSending(false);
        return;
      }

      postProcessMessageAction(sender_conversation_id, message);
      const text = fetchTextMessage(message);
      updateLatestMessage(text, sender_conversation_id);
    } else {
      // Message received
      postProcessMessageAction(receiver_conversation_id, message);
      setReloadChats(true);
      setTriggerReloadUnreadCountUpdate(true);
    }
  };

  // Calls when message reactions data is received form socket server broadcast
  const processReactionsMessageAction = async (data = {}) => {
    // Data destructure
    const {
      count,
      emoji,
      emoji_unicode,
      message_id,
      user,
      reacted_users = [],
    } = data;

    // Get active conversation
    let _conversation = getConversationByConversationID(activeConversation);

    // Find index of message on which reaction is applied to
    const messageIndex = _conversation?.messages?.findIndex(
      (item) => item?.id === message_id
    );

    // If no message found, exit out of function (Safety check)
    if (messageIndex === -1) return;

    // Find reactions data of the message
    let _reactions_data =
      _conversation?.messages[messageIndex]?.reactions?.reactions_data || [];

    // Find index of reaction belonging to the socket emoji
    const messageReactionsIndex = _reactions_data?.findIndex(
      (item) => item.emoji_unicode === emoji_unicode
    );

    if (messageReactionsIndex === -1) {
      _reactions_data.push({
        count,
        emoji,
        emoji_unicode,
        reacted_by_current_user: user?.id === userId,
        reacted_users,
      });
    } else if (messageReactionsIndex !== -1 && count === 0) {
      _reactions_data.splice(messageReactionsIndex, 1);
    } else if (messageReactionsIndex !== -1 && count > 0) {
      _reactions_data[messageReactionsIndex].count = count;
      _reactions_data[messageReactionsIndex].reacted_users = reacted_users;
    }

    // Use case handle. BE gives empty reactions object in case none is present.
    // Hence we have to manually create a placeholder, in order to insert values
    if (_conversation?.messages[messageIndex]?.reactions === undefined) {
      _conversation.messages[messageIndex].reactions = {};
    }

    // Update placeholder conversation object
    _conversation.messages[messageIndex].reactions.reactions_data =
      _reactions_data;

    // Update App state
    setConversationInformation(activeConversation, _conversation, true);
  };

  // This tracks the broadcast message and performs side effects
  useEffect(() => {
    if (!broadcastMessage) return;
    processMessageAction(broadcastMessage); // Consume broadcast message
    // Clear broadcast message after consuming it from useChat app state
    setConversationChannelBroadcast(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [broadcastMessage]);

  // This tracks the reaction channel broadcast message and performs side effects
  useEffect(() => {
    if (!broadcastMessageReactions) return;
    processReactionsMessageAction(broadcastMessageReactions);
    // Clear reactions broadcast message after consuming it from useChat app state
    setConversationReactionsChannelBroadcast(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [broadcastMessageReactions]);

  // Track groupCreateRequestData and create or update group over API
  useEffect(() => {
    if (groupCreateRequestData !== null) {
      (async () => {
        if (groupCreateRequestData?.group_edit === false) {
          // Creating new Group
          const response = await createGroupChatAPI(
            groupCreateRequestData?.group_title,
            groupCreateRequestData?.user_ids,
            groupCreateRequestData?.group_info,
            groupCreateRequestData.group_icon
          );
          setGroupCreateRequestData(null);
          if (response.success && response?.data) {
            setConversationChannelBroadcast(response?.data);
          }
        } else if (groupCreateRequestData?.group_edit === true) {
          // Editing existing group
          const response = await updateGroupChatAPI(
            groupCreateRequestData?.conversation_window_id,
            null,
            groupCreateRequestData?.user_ids,
            null,
            null
          );
          setGroupCreateRequestData(null);
          if (response.success && response?.data) {
            setConversationChannelBroadcast({
              ...response?.data,
              receiver_id: response?.data?.sender_id,
              read_by: [response?.data?.sender_id],
              receiver_conversation_id: response?.data?.sender_conversation_id,
            });
          }
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupCreateRequestData]);

  return (
    <React.Fragment>
      {!chats || chats.length === 0 ? (
        <EmptyChatView />
      ) : (
        <InitChatStyles>
          {isGroupCreating ? (
            <div className="loader-container">
              <ShimmerCategoryList
                title={"Chats"}
                items={6}
                categoryStyle="STYLE_THREE"
                text
              />
            </div>
          ) : (
            <div className="init-chat-container">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                  {/* ------------------ Message Area ------------------- */}
                  <div className="chats-section-wrapper">
                    <div className="message-section">
                      <div className="message-section-title">
                        <h4>
                          {receiver_id && "Message with "}{" "}
                          {conversation?.chat?.conversation_window_title || ""}
                        </h4>
                        {conversation?.user_info?.account_type ===
                          "prospect_user" && (
                          <span className="message-section-badge">
                            Prospect
                          </span>
                        )}
                      </div>
                      <div
                        id="chat-container__interstride"
                        className={`chat-container ${
                          isUserDeactivated ? "chat-container__deactivated" : ""
                        } ${attachment ? "AttachmentAdded" : ""}`}
                      >
                        <Messages
                          loading={loading || fresh}
                          messages={conversation?.messages || []}
                          ref={container}
                        />
                      </div>
                      <div>
                        {isUserDeactivated && (
                          <div className="deactivated_info">
                            <div className="divider">
                              <span className="date">
                                {" "}
                                This user is no longer active{" "}
                              </span>
                            </div>
                          </div>
                        )}
                        {!loading && !isUserDeactivated && (
                          <Textbox
                            disabled={
                              preview &&
                              preview?.receiver_id !== "" &&
                              (preview?.user_deleted || preview?.user_blocked)
                            }
                            className={
                              conversation?.messages &&
                              conversation?.messages.length > 0
                                ? "start-chat-textbox"
                                : ""
                            }
                            onSubmit={(
                              text,
                              attachment = null,
                              parent_message_id = null
                            ) => {
                              onSubmit(text, attachment, parent_message_id);
                            }}
                            rows={4}
                            maxRows={4}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                  <div>
                    <div className="nmessage-profile-section">
                      {receiver_id ? <ReceiverProfile /> : <GroupProfile />}
                    </div>
                    <div className="saved-network-widget">
                      <SavedPostsWidget
                        widgetText="Your saved network"
                        count={favoriteUsersCount || 0}
                        onClick={(e) => {
                          e.preventDefault();
                          if (favoriteUsersCount && favoriteUsersCount > 0) {
                            navigate(routes.NETWORK.SAVED_NETWORK);
                          }
                        }}
                      />
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          )}
        </InitChatStyles>
      )}
    </React.Fragment>
  );
};

export default memo(InitChat);
