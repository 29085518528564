import styled from "styled-components";
import { PRIMARY_COLOR } from "../../../styles/variables";

export default styled.div.attrs({ className: "" })`
  .webinars-wrapper,
  .appointments-wrapper {
    margin: 10px 0px 30px 0px;
    .header {
      display: flex;
      align-items: center;
      margin-bottom: 15px !important;
      .title {
        font-family: "TTCommons-Medium";
        font-size: 22px;
        font-weight: 500;
        line-height: 20px;
        padding-top: 4px;
        margin: 0;
      }
      .actions {
        margin-left: auto;
      }
    }
  }

  .webinar-items {
    .webinar-item {
      .title {
        font-family: "TTCommons-Medium";
        color: ${PRIMARY_COLOR};
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 19px;
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
        text-overflow: ellipsis;
      }
      .sub-title {
        margin-top: 10px;
        font-family: "TTCommons-Regular";
        color: ${PRIMARY_COLOR};
        font-size: 18px;
        letter-spacing: 0;
        line-height: 19px;
        margin-bottom: 5px;
      }
      .time {
        color: var(--gray-text-color);
      }
    }
    + .webinar-items {
      margin-top: 12px;
    }
  }

  .webinar-previews-loading-container {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
  }
  .components-wrapper-right-sidebar {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100%;
  }
`;
