//--------------- Updated methods

import axiosInstance from "../config/axiosInterceptors";
import { headers_with_token } from "../helper/helper";

/**
 * Fetch Chats List
 * @returns
 */
export const fetchChatsList = async (options = {}) => {
  var link = `network_modules/list_messages`;
  const queryString = Object.keys(options)
    .map((option) => `${option}=${options[option]}`)
    .join("&");
  link = Object.keys(options).length > 0 ? `${link}?${queryString}` : link;

  const response = await axiosInstance.get(`${link}`, headers_with_token());
  return response.data;
};

/**
 * Fetch conversation
 * @param {*} conversationId
 * @returns
 */
export const fetchConversation = async (
  conversationId,
  options = {},
  userId
) => {
  let link = userId
    ? `network_modules/view_messages?user_id=${userId}`
    : `network_modules/view_messages?conversation_id=${conversationId}`;
  const queryString = Object.keys(options)
    .map((option) => `${option}=${options[option]}`)
    .join("&");
  link = Object.keys(options).length > 0 ? `${link}&${queryString}` : link;
  const response = await axiosInstance.get(link, headers_with_token());
  return response.data;
};

/**
 * Fetch users list
 * @returns
 */
export const fetchContactsList = async (options = {}) => {
  let link = "network_modules/discover_network";
  const queryString = Object.keys(options)
    .map((option) => `${option}=${options[option]}`)
    .join("&");
  link = Object.keys(options).length > 0 ? `${link}?${queryString}` : link;
  const response = await axiosInstance.get(`${link}`, headers_with_token());
  return response.data;
};

/**
 * Get all filters for dropdown
 * @returns
 */
export const fetchDiscoverNetworkFilters = async () => {
  const response = await axiosInstance.get(
    `network_modules/discover_search_list`,
    headers_with_token()
  );
  return response.data;
};

/**
 * Remove conversation/chat
 * @param {*} conversationId
 * @returns
 */
export const deleteChat = async (conversationId) => {
  const response = await axiosInstance.get(
    `network_modules/leave_or_delete_chat?conversation_id=${conversationId}`,
    headers_with_token()
  );
  return response.data;
};

/**
 * Mute or Unmute chat
 * @param {*} conversationId
 * @returns
 */
export const muteOrUnmuteChat = async (conversationId) => {
  const response = await axiosInstance.get(
    `network_modules/mute_chat?conversation_id=${conversationId}`,
    headers_with_token()
  );
  return response.data;
};

/**
 * Report chat
 * @param {*} data
 * @returns
 */
export const reportChat = async (data) => {
  const response = await axiosInstance.post(
    `network_modules/report_chat`,
    data,
    headers_with_token()
  );
  return response.data;
};

/**
 * Search Conversation
 * @param {*} search
 * @returns
 */
export const searchConversation = async (search, page = 1) => {
  const response = await axiosInstance.get(
    `network_modules/search_messages?search=${search}&page=${page}`,
    headers_with_token()
  );
  return response.data;
};

/**
 * Get total unread messages count
 * @returns
 */
export const fetchTotalUnreadMessagesCount = async () => {
  const response = await axiosInstance.get(
    "/network_modules/total_unread_messages",
    headers_with_token()
  );
  return response.data;
};

/**
 * Normally we sent message over websocket but if a message has an attachment
 * we will instead send the message over API.
 * @param {Number} conversation_window_id conversation_window_id of chat.
 * @param {File} file The file to be uploaded (can be image, video, PDF, or document).
 * @param {String} content Optional: A text caption associated with the file.
 * @returns API response data
 */
export const sendChatMessageAttachmentAPI = async (
  conversation_window_id = null,
  file = null,
  content = ""
) => {
  const payload = new FormData();
  payload.append("id", conversation_window_id);
  payload.append("file", file);
  payload.append("content", content);

  const response = await axiosInstance.post(
    `/conversations/${conversation_window_id}/attachments`,
    payload,
    {
      headers: {
        ...headers_with_token().headers,
        "Content-Type": "multipart/form-data", // changing this to form-data to support as per API
      },
    }
  );
  return response.data;
};

/**
 * This method creates group chat over API
 * @param {String} group_title
 * @param {Array} user_ids Array of Integer user IDs to be added to the group, should include creator user ID
 * @param {String} group_info
 * @param {File} group_icon Optional
 * @returns API response data
 */
export const createGroupChatAPI = async (
  group_title = "",
  user_ids = [],
  group_info = "",
  group_icon = null
) => {
  const payload = new FormData();
  payload.append("group_title", group_title);
  user_ids?.forEach((id) => payload.append("user_ids[]", id));
  payload.append("group_info", group_info);
  payload.append("group_icon", group_icon);

  const response = await axiosInstance.post("groups", payload, {
    headers: {
      ...headers_with_token().headers,
      "Content-Type": "multipart/form-data", // changing this to form-data to support as per API
    },
  });
  return response.data;
};

/**
 * This method updates group over API
 * @param {Number} id Conversation Window ID
 * @param {String} group_title Optional - The updated name of the group
 * @param {Array} user_ids Optional - Updated list of user IDs for group members
 * @param {String} group_info Optional - Updated details about the group
 * @param {File} group_icon Optional - New group avatar image file
 * @returns API's response data
 */
export const updateGroupChatAPI = async (
  id = null,
  group_title = "",
  user_ids = [],
  group_info = "",
  group_icon = null
) => {
  const payload = new FormData();
  payload.append("id", id);
  group_title && payload.append("group_title", group_title);
  user_ids?.forEach((id) => payload.append("user_ids[]", id));
  group_info && payload.append("group_info", group_info);
  group_icon && payload.append("group_icon", group_icon);

  const response = await axiosInstance.put("groups/" + id, payload, {
    headers: {
      ...headers_with_token().headers,
      "Content-Type": "multipart/form-data", // changing this to form-data to support as per API
    },
  });
  return response.data;
};
