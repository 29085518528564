import React from "react";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";

import "./index.css";
import App from "./App";
import ErrorWrapper from "./ErrorWrapper";
import * as serviceWorker from "./serviceWorker";
import { config } from "./constant/config";
import { store, persistor } from "./redux/store";

const BUGSNAG_API_KEY = config().BUGSNAG_API_KEY;
try {
  if (process.env.NODE_ENV !== "development") console.log = () => {};
} catch (error) {
  //ignore it atm
  console.error(error);
}
Bugsnag.start({
  apiKey: BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: ["production", "staging"],
});

const BugSnagErrorBoundary =
  Bugsnag.getPlugin("react").createErrorBoundary(React);

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <BugSnagErrorBoundary FallbackComponent={ErrorWrapper}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <GoogleOAuthProvider clientId={config().GOOGLE_CLIENT_ID}>
            <App />
          </GoogleOAuthProvider>
        </PersistGate>
      </Provider>
    </BugSnagErrorBoundary>
  </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
var isSafari =
  /constructor/i.test(window.HTMLElement) ||
  (function (p) {
    return p.toString() === "[object SafariRemoteNotification]";
  })(
    !window["safari"] ||
      (typeof safari !== "undefined" && window["safari"].pushNotification)
  );

if (!isSafari && window.location.protocol == "https:") {
  serviceWorker.registerServiceWorker(); //firebase cloud messaging
}

serviceWorker.unregister();
