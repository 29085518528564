import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  margin-left: auto;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  @media (max-width: 767px) {
    margin: 8px auto 0;
  }
  a {
    margin-right: 8px;
  }
  svg {
    margin: 0px 0px -4px 0px;
  }
  @media (max-width: 767px) {
    order: 2;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .nlink-secondary {
    @media (max-width: 767px) {
      margin: 0 4px 0 0;
    }
  }
`;
