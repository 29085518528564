import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { filter, values } from "underscore";
import { ShimmerCategoryItem } from "react-shimmer-effects";
import moment from "moment";
import { get, isNull } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import DashboardRightSidebarStyles from "./DashboardRightSidebarStyles";

import { routes } from "../../../routes";
import EventCard from "../../EventCard/EventCard";
import usePromise from "../../../hooks/usePromise/usePromise";
import { useAppState } from "../../../context";
import {
  calculateLocalEndTimeFromEpoch,
  getLocalTzAbbr,
} from "../../../helper/helper";
import { EmptyMyWebinar } from "../../Learn/Comman/MyWebinar/MyWebinar";
import EventList from "../../Tools/EventsList/EventList";

import BoostPopup from "../../../components/BoostDialog/BoostDialog";
import RecommendedNetworkCard from "../../RecommendedNetworkCard/RecommendedNetworkCard";
import {
  getUpcomingWebinarsAPI,
  getMyWebinarsAPI,
} from "../../../services/webinarServices";
import { getRecommendedNetworksAPI } from "../../../services/networkServices";
import {
  getEventList,
  getAppointments,
} from "../../../services/calendarServices";
import { getJobsCountriesAPI } from "../../../services/JobServices";
import { config } from "../../../constant/config";
import { setJobsCountryList } from "../../../redux/reducers/jobReducer";
import DashboardSlider from "../DashboardSlider/DashboardSlider";

const JOBS_COUNTRY_LIST_VERSION = config().VERSIONS.JOBS_COUNTRY_LIST;

const DashboardRightSidebar = ({ isResponsive = false }) => {
  const dispatch = useDispatch();
  const accessibilityHelp = localStorage.getItem("accessibilityHelp");

  const { jobsCountryListVersion, jobsCountryList = [] } = useSelector(
    (store) => store.jobStore
  );
  const { permissions = {}, users = {} } = useSelector(
    (store) => store.userStore
  );
  const { discoverNetworks, setDiscoverNetworks } = useAppState("network");
  const { nationality = "United States" } = users;
  const { setCalendarEvents, setCalendarAppointments, upCommingEvents } =
    useAppState("tools");
  const { statusList, setStatusList } = useAppState("boostProfile");
  const { myWebinars, setMyWebinars } = useAppState("learn");

  const [callGetUpcominWebinarsAPI, refreshGetUpcomingWebinarsAPI] = usePromise(
    getUpcomingWebinarsAPI
  );
  const [callingGetMyWebinarsAPI, refreshGetMyWebinarsAPI] =
    usePromise(getMyWebinarsAPI);
  const [callgetEventList, refreshgetEventList] = usePromise(getEventList);
  const [callgetAppointment, refreshgetAppointmentList] =
    usePromise(getAppointments);
  const [
    callingGetRecommendedNetWorkPeopleAPI,
    refreshGetRecommendedNetWorkPeopleAPI,
  ] = usePromise(getRecommendedNetworksAPI);
  const [callingGetJobsCountriesAPI, refreshGetJobsCountriesAPI] =
    usePromise(getJobsCountriesAPI);

  const [totalTrueValues, settotalTrueValues] = useState(0);
  const [showBoostPopup, setShowBoostPopup] = useState(false);
  const [upcomingWebinars, setUpcomingWebinars] = useState([]);
  const [networkPeopleList, setNetworkPeopleList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");

  useEffect(() => {
    refreshgetEventList();
    if (permissions["appointment"]) {
      let dateObj = new Date();
      let month = dateObj.getUTCMonth() + 1; //months from 1-12
      let year = dateObj.getUTCFullYear();
      refreshgetAppointmentList(month, year);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      callgetEventList.hasFetched() &&
      callgetEventList.hasErrors() === false &&
      callgetEventList.data() &&
      callgetEventList.data().data
    ) {
      setCalendarEvents(callgetEventList.data().data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callgetEventList.isFetching()]);

  useEffect(() => {
    if (
      callgetAppointment.hasFetched() &&
      callgetAppointment.hasErrors() === false
    ) {
      setCalendarAppointments(callgetAppointment.data());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callgetAppointment.isFetching()]);

  useEffect(() => {
    refreshGetUpcomingWebinarsAPI();
    refreshGetMyWebinarsAPI();

    if (accessibilityHelp === "true") {
      const nextButtons = document.getElementsByClassName("slick-next");
      const prevButtons = document.getElementsByClassName("slick-prev");
      if (nextButtons && nextButtons.length > 0) {
        nextButtons[0].setAttribute("aria-label", "Next Slide");
        if (nextButtons[1])
          nextButtons[1].setAttribute("aria-label", "Next Slide");
      }
      if (prevButtons && prevButtons.length > 0) {
        prevButtons[0].setAttribute("aria-label", "Previous Slide");
        if (prevButtons[1])
          prevButtons[1].setAttribute("aria-label", "Previous Slide");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      callGetUpcominWebinarsAPI.hasFetched() &&
      callGetUpcominWebinarsAPI.hasErrors() === false &&
      callGetUpcominWebinarsAPI.data() &&
      callGetUpcominWebinarsAPI.data().data
    ) {
      setUpcomingWebinars(callGetUpcominWebinarsAPI.data().data);
      if (
        callGetUpcominWebinarsAPI.data().data &&
        callGetUpcominWebinarsAPI.data().data.boost_profile &&
        callGetUpcominWebinarsAPI.data().data.boost_profile.registered_webinar
      ) {
        setStatusList(callGetUpcominWebinarsAPI.data().data.boost_profile);

        const response = filter(
          values(callGetUpcominWebinarsAPI.data().data.boost_profile),
          function (data) {
            return data === true;
          }
        ).length;

        settotalTrueValues(response);
        setShowBoostPopup(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callGetUpcominWebinarsAPI.isFetching()]);

  useEffect(() => {
    if (
      callingGetMyWebinarsAPI.hasFetched() &&
      callingGetMyWebinarsAPI.hasErrors() === false &&
      callingGetMyWebinarsAPI.data() &&
      callingGetMyWebinarsAPI.data().data
    ) {
      setMyWebinars(callingGetMyWebinarsAPI.data().data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingGetMyWebinarsAPI.isFetching()]);

  useEffect(() => {
    if (
      jobsCountryList.length === 0 ||
      !jobsCountryListVersion ||
      jobsCountryListVersion !== JOBS_COUNTRY_LIST_VERSION
    ) {
      refreshGetJobsCountriesAPI();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      callingGetJobsCountriesAPI.hasFetched() &&
      callingGetJobsCountriesAPI.hasErrors() === false &&
      callingGetJobsCountriesAPI.data() &&
      callingGetJobsCountriesAPI.data().data &&
      callingGetJobsCountriesAPI.data().data.countries
    ) {
      dispatch(
        setJobsCountryList({
          data: callingGetJobsCountriesAPI.data().data.countries,
          version: JOBS_COUNTRY_LIST_VERSION,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingGetJobsCountriesAPI.isFetching()]);

  useEffect(() => {
    if (nationality && jobsCountryList?.length > 0) {
      const selectedCountry = jobsCountryList.find(
        (rec) => rec.label === nationality
      );
      setSelectedCountry(selectedCountry);
      if (permissions["chat"] && selectedCountry?.label)
        refreshGetRecommendedNetWorkPeopleAPI({
          country: selectedCountry?.label,
          page: 1,
          per_page: 5,
        });
      else setNetworkPeopleList([]);
    }
  }, [nationality, jobsCountryList]);

  useEffect(() => {
    if (
      callingGetRecommendedNetWorkPeopleAPI.hasFetched() &&
      callingGetRecommendedNetWorkPeopleAPI.hasErrors() === false
    ) {
      const { recommended_network = [], current_page } =
        callingGetRecommendedNetWorkPeopleAPI.data()?.data;

      const _discoverNetworks =
        current_page === 1
          ? [...recommended_network]
          : [...discoverNetworks, ...recommended_network];

      setNetworkPeopleList(recommended_network);
      setDiscoverNetworks(_discoverNetworks);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingGetRecommendedNetWorkPeopleAPI.isFetching()]);

  const closeFN = () => {
    setShowBoostPopup(false);
  };

  return (
    <DashboardRightSidebarStyles>
      <div className="components-wrapper-right-sidebar">
        <DashboardSlider />
        <div className="sticky-right-content">
          {permissions["appointment"] && upCommingEvents.length > 0 ? (
            <div className="appointments-wrapper">
              <div className="header">
                <h2 className="title text-primary">Appointments</h2>
                <div className="actions">
                  <Link
                    to={routes.TOOLS.MEET}
                    className="nlink-secondary link-focus"
                  >
                    View all{" "}
                    {accessibilityHelp === "true" ? " appointments" : ""}
                  </Link>
                </div>
              </div>
              <EventList events={upCommingEvents} />
            </div>
          ) : null}
          <div className="mb-20">
            <RecommendedNetworkCard
              networkPeopleList={networkPeopleList}
              selectedCountry={selectedCountry}
            />
          </div>
          <div className="webinars-wrapper">
            {permissions["webinar"] &&
              permissions["pastOrUpcomingWebinarsAvailable"] && (
                <React.Fragment>
                  <div className="header">
                    <h2 className="title text-primary">Upcoming webinars</h2>
                    <div className="actions">
                      <Link
                        to={routes.LEARN.WEBINARS}
                        className="nlink-secondary link-focus"
                      >
                        Go to webinars
                      </Link>
                    </div>
                  </div>

                  {callGetUpcominWebinarsAPI.isFetching() ? (
                    <div className="webinar-previews-loading-container">
                      {Array.from(Array(5).keys()).map((item, index) => (
                        <ShimmerCategoryItem key={index} />
                      ))}
                    </div>
                  ) : upcomingWebinars && upcomingWebinars.length > 0 ? (
                    upcomingWebinars.map((item, i) => {
                      if (i < 2) {
                        return (
                          <div className="webinar-items" key={i}>
                            <EventCard
                              key={i}
                              id={`${
                                isResponsive ? "responsive-" : ""
                              }webinar-${item.id}`}
                              className="event-card cursor-default limit-webinar-content"
                              title={get(item, "name", "")}
                              subTitle={moment
                                .unix(item?.epoch_time)
                                .local()
                                .format("ddd MMMM Do, YYYY")}
                              myWebinars={myWebinars}
                              duration={`${moment
                                .unix(item?.epoch_time)
                                .local()
                                .format(
                                  "hh:mm a"
                                )} - ${calculateLocalEndTimeFromEpoch(
                                item?.epoch_time,
                                item && item.duration && !isNull(item.duration)
                                  ? item.duration.split(" ")[0]
                                  : 0,
                                "minutes"
                              )} (${getLocalTzAbbr()})`}
                              tagName={item.webinar_category_name}
                              actionName="View details"
                            />
                          </div>
                        );
                      }
                    })
                  ) : (
                    <EmptyMyWebinar className="empty-web" />
                  )}
                </React.Fragment>
              )}
          </div>
        </div>
      </div>
      {showBoostPopup && (
        <BoostPopup
          message={
            totalTrueValues === statusList.length
              ? "Your profile boost is complete"
              : "You've just registered for your first webinar."
          }
          sub_message={
            totalTrueValues === statusList.length
              ? "You're ready to gain the most from the Interstride platform."
              : "You're on the way to gaining the most from the Interstride platform."
          }
          totalSteps={statusList.length}
          totalTrueValues={parseInt(totalTrueValues)}
          closeFn={closeFN}
        />
      )}
    </DashboardRightSidebarStyles>
  );
};

export default DashboardRightSidebar;
