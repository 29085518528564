import React, { useState, useRef, useEffect, memo } from "react";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { CircleSpinner } from "react-spinners-kit";
import { TextField, ClickAwayListener } from "@mui/material";
import Picker from "emoji-picker-react";

import TextBoxStyles from "./TextBoxStyles";
import { Button, ButtonModifier } from "../../../atoms/Button/Button";
import { validateChatAttachment } from "../../../../utils/common";
import { toastify } from "../../../../helper/helper";
import { useAppState } from "../../../../context";
import { WHITE_COLOR } from "../../../../styles/variables";

import deleteIcon from "../../../../assets/svg/f-delete-icon.svg";
import emojiIcon from "../../../../assets/svg/f-emoji-icon.svg";
import closeIcon from "../../../../assets/svg/close.svg";

const Textbox = ({
  className,
  rows = 4,
  maxRows = 4,
  onSubmit,
  disabled = false,
}) => {
  const {
    setIsMessageSending,
    messageSending,
    setIsAttachment,
    updateMessageReplyData,
    replyChatData,
  } = useAppState("chat");
  const [attachment, setAttachment] = useState(null);
  const [message, setMessage] = useState("");
  const [emojiContainer, setEmojiContainer] = useState(false);
  const selectedInputFile = useRef(null);
  const ref = useRef(null);

  /**
   * Called on File select
   */
  const onFileSelectButtonClick = () => {
    if (selectedInputFile) {
      selectedInputFile.current.click();
    }
  };

  /**
   * Onchange handler for the file
   * @param {*} e
   * @returns
   */
  const onFileChange = (e = {}) => {
    const file = e?.target?.files[0];
    const attachment = validateChatAttachment(file);
    if (attachment === 200) {
      setAttachment({
        name: file?.name,
        content: file,
      });
      setIsAttachment(true);
    } else {
      selectedInputFile.current.value = ""; // Clear Input
      toastify("error", attachment);
    }
  };

  /**
   * Call once the message is submitted
   * @returns
   */
  const onSubmitText = () => {
    if (messageSending) return;
    if (!message && !attachment) return;
    if (!attachment && message.trim() === "") return;

    let isValidAction = false;
    if (message || attachment || (!attachment && message.trim() !== "")) {
      isValidAction = true;
    }

    if (isValidAction) {
      onSubmit(
        message.trim() === "" ? "" : message,
        attachment,
        replyChatData?.message_id || null
      );
      setIsMessageSending(true);
      selectedInputFile.current.value = ""; // Clear Input
    }
  };

  useEffect(() => {
    if (!messageSending) {
      setMessage("");
      setAttachment(null);
    }
  }, [messageSending]);

  useEffect(() => {
    return () => {
      updateMessageReplyData(null, null, "", "", "");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeHandlerText = (e) => {
    setMessage(
      e.key === "Enter" && e.shiftKey ? `${e.target.value}\\n` : e.target.value
    );
  };

  const handleRemoveAttachment = () => {
    setIsAttachment(false);
    setAttachment(null);
    selectedInputFile.current.value = ""; // Clear Input
  };

  const onEmojiClick = (emojiObject) => {
    if (emojiObject?.emoji)
      setMessage((current) => current + emojiObject.emoji);
    setEmojiContainer(false);
  };

  return (
    <TextBoxStyles>
      <div className={["chat-textbox", className ?? ""].join(" ")}>
        {replyChatData?.message_id && (
          <div className="chat-textbox__reply">
            <div className="chat-textbox__reply__divider" />
            <div className="chat-textbox__reply__content">
              <div className="chat-textbox__reply__content__header">
                <span>{replyChatData.user_name}</span>
                <img
                  src={closeIcon}
                  alt="close"
                  onClick={() => updateMessageReplyData(null, null, "", "", "")}
                />
              </div>
              <p className="chat-textbox__reply__content__body">
                {replyChatData?.message || replyChatData?.file_name}
              </p>
            </div>
          </div>
        )}
        <input
          id="_upload-file_"
          type="file"
          ref={selectedInputFile}
          onChange={onFileChange}
          className={"hidden-input"}
          aria-label="Upload file Input field"
          name="_upload-file_"
          title="Upload file Input field"
        />
        <TextField
          disabled={disabled}
          ref={ref}
          placeholder="Write a message"
          value={message || ""}
          onChange={(e) => onChangeHandlerText(e)}
          className="chat-text-area-facelift"
          aria-label="Write a message"
          multiline
          inputProps={{
            "aria-label": "Write a message", // https://github.com/mui/material-ui/issues/25697
          }}
          title="Write a message"
          minRows={rows}
          maxRows={maxRows}
        />
        <div className="chat-textbox__actions">
          {attachment && (
            <div className="chat-attachments">
              <div className="chat-attachments__item">
                <span className="file">
                  {" "}
                  {attachment?.name?.length > 20
                    ? attachment?.name?.slice(0, 20) + "..."
                    : attachment?.name}{" "}
                </span>
                <span className="icon" onClick={() => handleRemoveAttachment()}>
                  <img src={deleteIcon} alt="Delete Icon" />
                </span>
              </div>
            </div>
          )}
          <a
            href="/#"
            onClick={(e) => {
              e.preventDefault();
              setEmojiContainer((current) => !current);
            }}
            className="chat-textbox__actions--icon emoji-icon"
          >
            <img src={emojiIcon} alt="emoji" />
          </a>
          <a
            href="/#"
            onClick={(e) => {
              e.preventDefault();
              !disabled && onFileSelectButtonClick();
              emojiContainer && setEmojiContainer(false);
            }}
            className="chat-textbox__actions--icon attachment-icon"
          >
            <AttachFileIcon
              style={{ cursor: disabled ? "not-allowed" : "pointer" }}
            />
          </a>
          <div className="chat-textbox__actions--send-button">
            <Button
              disabled={messageSending || disabled}
              onClick={onSubmitText}
              type={"button"}
              modifier={
                disabled
                  ? ButtonModifier.CHAT_SEND_BUTTON_DISABLED
                  : ButtonModifier.CHAT_SEND_BUTTON
              }
              isSubmitting={!messageSending}
            >
              {messageSending ? (
                <CircleSpinner color={WHITE_COLOR} size={18} />
              ) : (
                "Send"
              )}
            </Button>
          </div>
          {emojiContainer && (
            <ClickAwayListener onClickAway={() => setEmojiContainer(false)}>
              <div className="chat-textbox__actions--emoji-wrapper">
                <Picker
                  onEmojiClick={onEmojiClick}
                  skinTonesDisabled
                  style={{ width: "100%" }}
                />
              </div>
            </ClickAwayListener>
          )}
        </div>
      </div>
    </TextBoxStyles>
  );
};

export default memo(Textbox);
