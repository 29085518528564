import { Avatar } from "@mui/material";
import React from "react";

/** OPT Calculator */
import { clone } from "lodash";

/** FAQ Images */
import WelcomeTutorial from "../assets/FAQ/f-welcome-tutorial.svg";
import General from "../assets/FAQ/f-general-tab.svg";
import SettingProfile from "../assets/FAQ/f-profile-tab.svg";
import Networking from "../assets/FAQ/f-networking-tab.svg";
import JobSearch from "../assets/FAQ/f-jobs-tab.svg";
import VisaInsights from "../assets/FAQ/f-visa-tab.svg";
import CountryInsights from "../assets/FAQ/f-country-tab.svg";
import WebinarTools from "../assets/FAQ/f-webinars-tab.svg";
/**------------------------------------------------------- */

import Clock from "../assets/WhyMatters/m1/m1-clock.svg";

import StaticLabel2 from "../assets/WhyMatters/m2/m2-text.png";

import StaticLabel3 from "../assets/WhyMatters/m3/m3-text.svg";
import NotifyIcon from "../assets/WhyMatters/m3/m3-notify.svg";
import SubScribeIcon from "../assets/WhyMatters/m3/m3-subscribe.svg";

import M4Icon1 from "../assets/WhyMatters/m4/m4-icon1.svg";
import M4Icon2 from "../assets/WhyMatters/m4/m4-icon2.svg";
import M4Icon3 from "../assets/WhyMatters/m4/m4-icon3.svg";

import ChatICon from "../assets/WhyMatters/m5/m5-chat.svg";
import LikeIcon from "../assets/WhyMatters/m5/m5-like.svg";
import StarICon from "../assets/WhyMatters/m5/m5-star.svg";

/** OPT Calculator */
import InfoIconTooltip from "../components/InfoIconTooltip/InfoIconTooltip";

/**------------------------------------------------------- */

export const SHOW_TOPICS = 3;
export const SHOW_MEMBERS = 3;
export const RATE_TYPE = {
  HOURLY: "hourly_rate",
  FIXED: "fixed_rate",
};
export const RECOMMENDED_NETWORK_USERS = 5;
export const NETWORK_DASHBOARD = 3;
export const BACKEND_FLAG_URL =
  process.env.REACT_APP_ENV === "stage"
    ? "https://interstride-staging.s3.amazonaws.com/staging/flags/4x3/"
    : "https://interstride.s3.amazonaws.com/production/flags/4x3/";
export const MAX_TITLE_LENGTH = 65;

/** Boost Profile Why Matters Images */
export const M1_USER_IMAGE =
  "https://interstride.s3.amazonaws.com/miscellaneous/assets/WhyMatters/m1/m1-user.png";
export const M1_FLAG_IMAGE =
  "https://interstride.s3.amazonaws.com/miscellaneous/assets/WhyMatters/m1/m1-flag.png";
export const GROUP_CHAT_IMAGE =
  "https://interstride.s3.amazonaws.com/miscellaneous/assets/group-chat.png";

export const BOOST_PROFILE_STATUS_LIST = [
  {
    title: "Complete your profile",
    subText: "Hey there, newbie!",
    key: "completed_user_profile",
    isCompleted: false,
    matters: [
      {
        image: <Avatar src={M1_USER_IMAGE} alt="Profile Picture" />,
        title: "Let other users put a face to the name.",
        description:
          "Having a sense of community can make you feel at home and empower you to thrive together.",
      },
      {
        image: <img alt="Flag" src={M1_FLAG_IMAGE} />,
        title: "Connect with others from your home country.",
        description:
          "Having a sense of community can make you feel at home and empower you to thrive together.",
      },
      {
        image: <img alt="Clock" src={Clock} />,
        title: "Customize your profile for tailored results.",
        description:
          "Sharing your location syncs the platform to your time zone. Listing your interests helps us collect resources tailored just for you.",
      },
    ],
  },
  {
    title: "Network with someone",
    subText: "Your journey starts here.",
    key: "network_connection",
    isCompleted: false,
    matters: [
      {
        image: <img alt="Static Label" src={StaticLabel2} />,
        title: "Get access to mentors, administrators, and alumni.",
        description:
          "People who have been in your shoes can offer valuable insights into your journey.",
      },
      {
        image: <img alt="Flag" src={M1_FLAG_IMAGE} />,
        title: "Connect with others from your home country.",
        description:
          "Having a sense of community can make you feel at home and empower you to thrive together.",
      },
      {
        image: <img alt="Group Chat" src={GROUP_CHAT_IMAGE} />,
        title: "Create group chats to keep everyone in the loop.",
        description:
          "Creating group chats with peers can help you organize events, study groups, and more.",
      },
    ],
  },
  {
    title: "Create a job search",
    subText: "You’re on a roll now!",
    key: "job_search",
    isCompleted: false,
    matters: [
      {
        image: <img alt="Static Label" src={StaticLabel3} />,
        title: "Filter your jobs by country, position, and more.",
        description:
          "Create the perfect search based on where you want to work, what suits your profile, and more.",
      },
      {
        image: <img alt="Notify Icon" src={NotifyIcon} />,
        title: "Save your searches and potential jobs for later.",
        description:
          "Create a collection of potential jobs that you can access at any time.",
      },
      {
        image: <img alt="SubScribe Icon" src={SubScribeIcon} />,
        title: "Subscribe to alerts for your saved searches.",
        description:
          "Receive a notification every time a new job is posted based on your saved searches.",
      },
    ],
  },
  {
    title: "Register for a webinar",
    subText: "You’re more than halfway there. Keep it up!",
    key: "registered_webinar",
    isCompleted: false,
    matters: [
      {
        image: <img alt="M4Icon1" src={M4Icon1} />,
        title: "Get ahead by learning about interviews, jobs, and more.",
        description:
          "Our webinars are specially designed to give international students all the knowledge and resources they need.",
      },
      {
        image: <img alt="M4Icon2" src={M4Icon2} />,
        title: "Check your calendar to keep track of your webinars.",
        description:
          "Click on Calendar View to see all your upcoming and past webinars in calendar format.",
      },
      {
        image: <img alt="M4Icon3" src={M4Icon3} />,
        title: "Unlimited access to all webinars from any date.",
        description:
          "Missed that webinar you were planning to attend? Don't worry, it'll always be there for you to access.",
      },
    ],
  },
  {
    title: "Rate Interstride",
    subText: "Last step and you’re good to go!",
    key: "rate_interstride",
    isCompleted: false,
    matters: [
      {
        image: <img alt="Like Icon" src={LikeIcon} />,
        title: "Tell us what you like and what you don't.",
        description:
          "Your feedback can help Interstride improve the user experience.",
      },
      {
        image: <img alt="Chat Icon" src={ChatICon} />,
        title: "Suggest features you'd like to see.",
        description:
          "Thought of a feature you would really make use of? We’d love to hear it and make it a reality.",
      },
      {
        image: <img alt="Star ICon" src={StarICon} />,
        title: "Support Interstride by rating your experience.",
        description:
          "Sharing your rating with universities enables us to expand our platform to help other students too!",
      },
    ],
  },
];

export const BOOST_PROFILE_STATUS_LIST_WITHOUT_NETWORK = [
  {
    title: "Complete your profile",
    subText: "Hey there, newbie!",
    key: "completed_user_profile",
    isCompleted: false,
    matters: [
      {
        image: <Avatar src={M1_USER_IMAGE} alt="Profile Picture" />,
        title: "Let other users put a face to your name.",
        description:
          "Having a sense of community can make you feel at home and empower you to thrive together.",
      },
      {
        image: <img alt="Flag" src={M1_FLAG_IMAGE} />,
        title: "Connect with others from your home country.",
        description:
          "Learn from students and alumni who have been through the international student journey.",
      },
      {
        image: <img alt="Clock" src={Clock} />,
        title: "Customize your profile for tailored results.",
        description:
          "Sharing your location syncs the platform to your time zone. Listing your interests helps us collect resources tailored just for you.",
      },
    ],
  },

  {
    title: "Create a job search",
    subText: "You’re on a roll now!",
    key: "job_search",
    isCompleted: false,
    matters: [
      {
        image: <img alt="Static Label" src={StaticLabel3} />,
        title: "Filter your jobs by country, position, and more.",
        description:
          "Create the perfect search based on where you want to work, what suits your profile, and more.",
      },
      {
        image: <img alt="Notify Icon" src={NotifyIcon} />,
        title: "Save your searches and potential jobs for later.",
        description:
          "Create a list of potential jobs that you can access at any time.",
      },
      {
        image: <img alt="SubScribe Icon" src={SubScribeIcon} />,
        title: "Subscribe to alerts for your saved searches.",
        description:
          "Receive a notification every time a new job is posted based on your saved searches.",
      },
    ],
  },
  {
    title: "Register for a webinar",
    subText: "You’re more than halfway there. Keep it up!",
    key: "registered_webinar",
    isCompleted: false,
    matters: [
      {
        image: <img alt="M4Icon1" src={M4Icon1} />,
        title: "Get ahead by learning about interviews, jobs, and more.",
        description:
          "Our webinars are specially designed to give international students all the knowledge and resources they need.",
      },
      {
        image: <img alt="M4Icon2" src={M4Icon2} />,
        title: "Check your calendar to keep track of your webinars.",
        description:
          "Click on Calendar view to see all your upcoming and past webinars in calendar format.",
      },
      {
        image: <img alt="M4Icon3" src={M4Icon3} />,
        title: "Unlimited access to all webinars from any date.",
        description:
          "Missed that webinar you were planning to attend? Don’t worry, the recording will be there for you to access.",
      },
    ],
  },
  {
    title: "Rate Interstride",
    subText: "Last step and you’re good to go!",
    key: "rate_interstride",
    isCompleted: false,
    matters: [
      {
        image: <img alt="Like Icon" src={LikeIcon} />,
        title: "Tell us what you like and what you don’t.",
        description:
          "Your feedback can help Interstride improve the user experience.",
      },
      {
        image: <img alt="Chat Icon" src={ChatICon} />,
        title: "Suggest features you'd like to see.",
        description:
          "Any feature or functionality that could be beneficial in your student journey?",
      },
      {
        image: <img alt="Star Icon" src={StarICon} />,
        title: "Support Interstride by rating your experience.",
        description:
          "Sharing your experience can help us optimize the portal for all students.",
      },
    ],
  },
];

export const BOOST_PROFILE_STATUS_LIST_WITHOUT_WEBINAR = [
  {
    title: "Complete your profile",
    subText: "Hey there, newbie!",
    key: "completed_user_profile",
    isCompleted: false,
    matters: [
      {
        image: <Avatar src={M1_USER_IMAGE} alt="Profile Picture" />,
        title: "Let other users put a face to your name.",
        description:
          "Having a sense of community can make you feel at home and empower you to thrive together.",
      },
      {
        image: <img alt="Flag" src={M1_FLAG_IMAGE} />,
        title: "Connect with others from your home country.",
        description:
          "Learn from students and alumni who have been through the international student journey.",
      },
      {
        image: <img alt="Clock" src={Clock} />,
        title: "Customize your profile for tailored results.",
        description:
          "Sharing your location syncs the platform to your time zone. Listing your interests helps us collect resources tailored just for you.",
      },
    ],
  },
  {
    title: "Network with someone",
    subText: "Your journey starts here.",
    key: "network_connection",
    isCompleted: false,
    matters: [
      {
        image: <img alt="Static Label2" src={StaticLabel2} />,
        title: "Get access to mentors, administrators, and alumni.",
        description:
          "People who have been in your shoes can offer valuable insights into your journey.",
      },
      {
        image: <img alt="Flag" src={M1_FLAG_IMAGE} />,
        title: "Connect with others from your home country.",
        description:
          "Having a sense of community can make you feel at home and empower you to thrive together.",
      },
      {
        image: <img alt="Group Chat" src={GROUP_CHAT_IMAGE} />,
        title: "Create group chats to keep everyone in the loop.",
        description:
          "Creating group chats with peers can help you organize events, study groups, and more.",
      },
    ],
  },
  {
    title: "Create a job search",
    subText: "You’re on a roll now!",
    key: "job_search",
    isCompleted: false,
    matters: [
      {
        image: <img alt="Static Label3" src={StaticLabel3} />,
        title: "Filter your jobs by country, position, and more.",
        description:
          "Create the perfect search based on where you want to work, what suits your profile, and more.",
      },
      {
        image: <img alt="Notify Icon" src={NotifyIcon} />,
        title: "Save your searches and potential jobs for later.",
        description:
          "Create a list of potential jobs that you can access at any time.",
      },
      {
        image: <img alt="SubScribe Icon" src={SubScribeIcon} />,
        title: "Subscribe to alerts for your saved searches.",
        description:
          "Receive a notification every time a new job is posted based on your saved searches.",
      },
    ],
  },
  {
    title: "Rate Interstride",
    subText: "Last step and you’re good to go!",
    key: "rate_interstride",
    isCompleted: false,
    matters: [
      {
        image: <img alt="Like Icon" src={LikeIcon} />,
        title: "Tell us what you like and what you don’t.",
        description:
          "Your feedback can help Interstride improve the user experience.",
      },
      {
        image: <img alt="Chat Icon" src={ChatICon} />,
        title: "Suggest features you'd like to see.",
        description:
          "Any feature or functionality that could be beneficial in your student journey?",
      },
      {
        image: <img alt="Star Icon" src={StarICon} />,
        title: "Support Interstride by rating your experience.",
        description:
          "Sharing your experience can help us optimize the portal for all students.",
      },
    ],
  },
];

export const BOOST_PROFILE_STATUS_LIST_WITHOUT_NETWORK_WEBINAR = [
  {
    title: "Complete your profile",
    subText: "Hey there, newbie!",
    key: "completed_user_profile",
    isCompleted: false,
    matters: [
      {
        image: <Avatar src={M1_USER_IMAGE} alt="Profile Picture" />,
        title: "Let other users put a face to your name.",
        description:
          "Having a sense of community can make you feel at home and empower you to thrive together.",
      },
      {
        image: <img alt="Flag" src={M1_FLAG_IMAGE} />,
        title: "Connect with others from your home country.",
        description:
          "Learn from students and alumni who have been through the international student journey.",
      },
      {
        image: <img alt="Clock" src={Clock} />,
        title: "Customize your profile for tailored results.",
        description:
          "Sharing your location syncs the platform to your time zone. Listing your interests helps us collect resources tailored just for you.",
      },
    ],
  },
  {
    title: "Network with someone",
    subText: "Your journey starts here.",
    key: "network_connection",
    isCompleted: false,
    matters: [
      {
        image: <img alt="Static Label2" src={StaticLabel2} />,
        title: "Get access to mentors, administrators, and alumni.",
        description:
          "People who have been in your shoes can offer valuable insights into your journey.",
      },
      {
        image: <img alt="Flag" src={M1_FLAG_IMAGE} />,
        title: "Connect with others from your home country.",
        description:
          "Having a sense of community can make you feel at home and empower you to thrive together.",
      },
      {
        image: <img alt="Group Chat" src={GROUP_CHAT_IMAGE} />,
        title: "Create group chats to keep everyone in the loop.",
        description:
          "Creating group chats with peers can help you organize events, study groups, and more.",
      },
    ],
  },
  {
    title: "Create a job search",
    subText: "You’re on a roll now!",
    key: "job_search",
    isCompleted: false,
    matters: [
      {
        image: <img alt="Static Label3" src={StaticLabel3} />,
        title: "Filter your jobs by country, position, and more.",
        description:
          "Create the perfect search based on where you want to work, what suits your profile, and more.",
      },
      {
        image: <img alt="Notify Icon" src={NotifyIcon} />,
        title: "Save your searches and potential jobs for later.",
        description:
          "Create a list of potential jobs that you can access at any time.",
      },
      {
        image: <img alt="SubScribe Icon" src={SubScribeIcon} />,
        title: "Subscribe to alerts for your saved searches.",
        description:
          "Receive a notification every time a new job is posted based on your saved searches.",
      },
    ],
  },
  {
    title: "Rate Interstride",
    subText: "Last step and you’re good to go!",
    key: "rate_interstride",
    isCompleted: false,
    matters: [
      {
        image: <img alt="Like Icon" src={LikeIcon} />,
        title: "Tell us what you like and what you don’t.",
        description:
          "Your feedback can help Interstride improve the user experience.",
      },
      {
        image: <img alt="Chat Icon" src={ChatICon} />,
        title: "Suggest features you'd like to see.",
        description:
          "Any feature or functionality that could be beneficial in your student journey?",
      },
      {
        image: <img alt="Star Icon" src={StarICon} />,
        title: "Support Interstride by rating your experience.",
        description:
          "Sharing your experience can help us optimize the portal for all students.",
      },
    ],
  },
];

export const PROFILE_INTEREST = [
  { title: "Networking", checked: false },
  { title: "Webinars", checked: false },
  { title: "Jobs", checked: true },
  { title: "Resources", checked: false },
  { title: "Mentorship", checked: false },
  { title: "University", checked: true },
  { title: "Hiring", checked: false },
];

export const NOTIFICATION_FILTER = [
  { id: 1, title: "Networking", key: "networking", checked: true },
  { id: 2, title: "Webinars", key: "webinars", checked: true },
  { id: 3, title: "Jobs", key: "job", checked: true },
  { id: 4, title: "Other Resources", key: "other_resources", checked: true },
  { id: 5, title: "Calendar", key: "calendar", checked: true },
  { id: 6, title: "Community", key: "topics", checked: true },
  { id: 7, title: "Book Appointment", key: "appointment", checked: true },
];

export const US_VISA_LIST = [
  {
    label: "Top 500 H-1B",
    value: "top_500_employers",
  },
  {
    label: "Top 1000 H-1B",
    value: "top_1000_employers",
  },
  {
    label: "Sponsored last year",
    value: "sponsered_last_year",
  },
  {
    label: "Cap-exempt",
    value: "cap_exempt",
  },
  {
    label: "International organizations",
    value: "international_organizations",
  },
  {
    label: "All Companies",
    value: "all_companies",
  },
];

export const US_VISA_LIST_DOMESTIC_VIEW = [
  {
    label: "Fortune 500",
    value: "fortune_500_employers",
  },
  {
    label: "Fortune 1000",
    value: "fortune_1000_employers",
  },
  {
    label: "Crunchbase Unicorn",
    value: "unicorn_1000_employers",
  },
  {
    label: "International organizations",
    value: "international_organizations",
  },
  {
    label: "All Companies",
    value: "all_companies",
  },
];

export const NOTIFICATION_FREQUENCY = [
  {
    label: "Weekly",
    value: "weekly",
  },
  {
    label: "Bi-Weekly",
    value: "bi-weekly",
  },
  {
    label: "Monthly",
    value: "monthly",
  },
  {
    label: "No notifications",
    value: "no_notification",
  },
];

export const TERMS_AND_CONDITIONS = [
  {
    id: 1,
    category: "Overview",
    details: `Please read these Terms of Use (“Terms”, “Terms of Use”) carefully before using the Interstride mobile application or website (the “Service”) operated by Interstride LLC (“Interstride”, “us”, “we”, or “our”).<br/>
    The following are terms of a legal agreement between you (“You” or “Your”) and Interstride. Your access to and use of the Service is conditioned upon your acceptance of and compliance with these Terms.<br/>
    These Terms apply to all visitors, users and others who wish to access or use the Service. By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms then you do not have permission to access the Service.`,
  },
  {
    id: 2,
    category: "Privacy",
    details: `Your privacy is very important to us. Your use of our Services is also subject to our Privacy Policy.`,
  },
  {
    id: 3,
    category: "Accounts",
    details: `You need to have an account to use the Service. You can create an account with Interstride if you are a student at an institution that has partnered with Interstride or if you are an Employer looking to hire students using the Service. Institutions that have partnered with Interstride have administrative access to the Service.<br/><br/>
    When you create an account with us, you guarantee that you are above the age of 18, and that the information you provide us is accurate, complete, and current at all times. Inaccurate, incomplete, or obsolete information may result in the immediate termination of your account on the Service. You are responsible for maintaining the confidentiality of your account and password, including but not limited to the restriction of access to your computer and/or account. You agree to accept responsibility for any and all activities or actions that occur under your account and/or password, whether your password is with our Service or a third-party service. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account. You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than you, without appropriate authorization. You may not use as a username any name that is offensive, vulgar or obscene.`,
  },
  {
    id: 4,
    category: "Communications",
    details: `By creating an Account on our Service, you agree to subscribe to newsletters, marketing or promotional materials and other information we may send. However, you may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or instructions provided in any email we send.`,
  },
  {
    id: 5,
    category: "Content",
    details: `Our Service allows you to post, upload, input, link, store, share and otherwise make available (“Share”) certain information, text, graphics, videos, or other material (“Content”). You are responsible for the Content that you Share on or through the Service, including its legality, reliability, and appropriateness. By Sharing Content on or through the Service, you represent and warrant that: (i) the Content is yours (you own it) and/or you have the right to use it and the right to grant us the rights and license as provided in these Terms, and (ii) that the Sharing of your Content on or through the Service does not violate the privacy rights, publicity rights, copyrights, contract rights or any other rights of any person or entity. We reserve the right to terminate the account of anyone found to be infringing on a copyright. You retain any and all of your rights to any Content you Share through the Service and you are responsible for protecting those rights.<br/><br/>
    We take no responsibility and assume no liability for Content you Share or for any Content you or any third-party Shares on or through the Service. However, by Sharing Content using the Service you grant us the right and license to use, modify, publicly perform, publicly display, reproduce, and distribute such Content on and through the Service. You agree that this license includes the right for us to make your Content available to other users of the Service, who may also use your Content subject to these Terms. Interstride has the right but not the obligation to monitor and edit all Content provided by users. In addition, Content found on or through this Service are the property of Interstride or used with permission. You may not distribute, modify, transmit, reuse, download, repost, copy, or use said Content, whether in whole or in part, for commercial purposes or for personal gain, without express advance written permission from us.`,
  },
  {
    id: 6,
    category: "Copyright Policy",
    details: `We respect the intellectual property rights of others. It is our policy to respond to any claim that Content posted on the Service infringes on the copyright or other intellectual property rights (“Infringement”) of any person or entity. If you are a copyright owner, or authorized on behalf of one, and you believe that the copyrighted work has been copied in a way that constitutes copyright infringement, please submit your claim via email to contact@interstride.com, with the subject line: “Copyright Infringement” and include in your claim a detailed description of the alleged Infringement as detailed below, under “DMCA Notice and Procedure for Copyright Infringement Claims” You may be held accountable for damages (including costs and attorneys’ fees) for misrepresentation or bad-faith claims on the infringement of any Content found on and/or through the Service on your copyright.<br/><br/>
    DMCA Notice and Procedure for Copyright Infringement Claims<br/><br/>
    You may submit a notification pursuant to the Digital Millennium Copyright Act (DMCA) by providing our Copyright Agent with the following information in writing (see 17 U.S.C 512(c)(3) for further detail): identification of the URL or other specific location on the Service where the material that you claim is infringing is located; your address, telephone number, and email address; a statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law; a statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner’s behalf. You can contact our Copyright Agent via email at contact@interstride.com`,
  },
  {
    id: 7,
    category: "Intellectual Property",
    details: `The Service and its original content (excluding Content provided by users), features and functionality are and will remain the exclusive property of Interstride and its licensors. The Service is protected by copyright, trademark, and other laws of both the United States and foreign countries. Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of Interstride.`,
  },
  {
    id: 8,
    category: "Job Portal",
    details: `The Service also assists with the hiring process. You acknowledge and understand that Employers on the Interstride platform are responsible for their job postings, job requirements, and hiring needs. Each Employer is responsible for complying with applicable laws regarding employment and hiring practices, including Title VII. Interstride disclaims all liability and makes no warranty that the Employer’s use of the Services complies with Title IV or any similar law.<br/><br/>
    Interstride does not control the hiring or decision-making process regarding each Employer’s hiring efforts. Interstride is not an employment agency but rather provides a platform, network, and various tools to assist with the hiring needs of Employers.`,
  },
  {
    id: 9,
    category: "Links To Other Websites",
    details: `Our Service contains links to third party websites or services that are not owned or controlled by Interstride. Interstride has no control over, and assumes no responsibility for the content, privacy policies, or practices of any third-party websites or services. We do not warrant the offerings of any of these entities/individuals or their websites. You acknowledge and agree that Interstride shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such third-party web sites or services. We strongly advise you to read the terms and conditions and privacy policies of any third-party web sites or services that you visit.`,
  },
  {
    id: 10,
    category: "Termination",
    details: `We may terminate or suspend your account and bar access to the Service immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of the Terms. If you wish to terminate your account, you may simply discontinue using the Service. All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.`,
  },
  {
    id: 11,
    category: "Indemnification",
    details: `You agree to defend, indemnify and hold harmless Interstride and its licensee and licensors, and their employees, contractors, agents, officers and directors, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney’s fees), resulting from or arising out of a) your use and access of the Service, by you or any person using your account and password; b) a breach of these Terms, or c) Content posted on the Service.`,
  },
  {
    id: 12,
    category: "Limitation Of Liability",
    details: `In no event shall Interstride, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Service; (ii) any conduct or content of any third party on the Service; (iii) any content obtained from the Service; and (iv) unauthorized access, use or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.`,
  },
  {
    id: 13,
    category: "Disclaimer",
    details: `Your use of the Service is at your sole risk. The Service is provided on an “AS IS” and “AS AVAILABLE” basis. The Service is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement or course of performance. Interstride LLC its subsidiaries, affiliates, and its licensors do not warrant that a) the Service will function uninterrupted, secure or available at any particular time or location; b) any errors or defects will be corrected; c) the Service is free of viruses or other harmful components; or d) the results of using the Service will meet your requirements.`,
  },
  {
    id: 14,
    category: "Exclusions",
    details: `Some jurisdictions do not allow the exclusion of certain warranties or the exclusion or limitation of liability for consequential or incidental damages, so the limitations above may not apply to you.`,
  },
  {
    id: 15,
    category: "Governing Law",
    details: `These Terms shall be governed and construed in accordance with the laws of California, United States, without regard to its conflict of law provisions. Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements we might have had between us regarding the Service.`,
  },
  {
    id: 16,
    category: "Changes",
    details: `Interstride may revise the Terms of Use and Privacy Policy at any time without notice to you. The revised Terms of Use and Privacy Policy will be effective when posted. By continuing to access or use our Service after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use the Service.`,
  },
  {
    id: 17,
    category: "Employers",
    details: `In addition to the standard Terms of Use above, the following additional terms and conditions apply to all Employers (including recruiters and third parties using the Service to hire potential employees) (“you” or “your” refers to the Employer in the section below):<br/>
    <ul>
    <li>When you create an Interstride account, you agree to allow Interstride to verify your identity.</li>
    <li>As an Employer, your account is for business use and not for personal use.</li>
    <li>As an Employer using the Service, you may have access to student information and data. You are prohibited from disclosing or sharing this information with other parties, and agree to keep student data confidential. You shall only use student data to provide employment related services to the student and not use, sell, or disclose such data for any purposes including data analytics, data monetization, advertising, marketing or any other purpose. You agree to handle and maintain collected student data with equivalent or superior standards as those described in our Privacy Policy, including the requirements of the General Data Protection Regulation (GDPR).</li>
    <li>You also agree not to use the Service to send spam or other unauthorized communications. </li>
    <li>You agree that through your use of the Service you will maintain fair and equitable recruiting and hiring practices. </li>
    <li>You agree to allow Interstride to market and promote your job posting or hiring needs through its network and as it sees fit.</li>
    <li>Interstride, in its sole discretion, may add labels or badges to Employers based on their responsiveness, hiring needs, activeness on the Interstride platform.</li>
    <li>The Employer is responsible for the contents of its job posting, application form, screener questions.</li>
    <li>You agree that Interstride may reject or remove any job posting, any part of any Employer for any or no reason.</li>
    <li>Interstride does not guarantee applicants for your job posting or delivery of any communication or application material or that there will be no mistakes in the transmission of information.</li>
    <li>Interstride provides no guarantee as to the qualifications or interest in your job posting by students or alumni on the Interstride platform.</li>
    <li>You agree and consent that Interstride may review and present potential applicants to your job posting based on information provided by the applicant and based on Interstride’s analysis and opinion</li>
    </ul>
    You shall indemnify, defend and hold harmless Interstride, its agents, affiliates, and licensors from any third-party claim or liability (including without limitation reasonable legal fees) arising out of any of your job postings on the Interstride platform.`,
  },
  {
    id: 18,
    category: "Contact Us",
    details: `If you have any questions about these Terms, please contact us at <a href="mailto:contact@interstride.com">contact@interstride.com</a>.<br/><br/>
    Mailing address: 5127 Meadow Creek Dr., Austin, TX 77085 `,
  },
];

export const PRIVACY_POLICY = [
  {
    id: 1,
    category: "Overview",
    details: `This page is used to inform mobile app users and website visitors regarding Interstride LLC’s (“Interstride”) policies with the collection, use, and disclosure of Personal Information in connection with use of the Interstride mobile application or website (the “Service”). Through your interaction with and use of the Service, Interstride may collect Personal Information, which is information that identifies an individual or relates to an identifiable individual.<br/><br/>
    If you choose to use our Service, then you agree to the collection and use of Personal Information in relation to this policy. The Personal Information that we collect is used for providing and improving the Service. We will not sell or share your information with third parties except as described in this Privacy Policy.<br/><br/>
    The terms used in this Privacy Policy have the same meanings as in Interstride’s Terms of Use. This policy is part of the Terms of Use. By using the Service, you agree to both the Terms of Use and this Privacy Policy, if you do not agree, please do not use the Service.`,
  },
  {
    id: 2,
    category: "Information Collection and Use",
    details: `For a better experience, while using our Service, we may require you to provide us with certain Personal Information, including but not limited to Email address, Name (first and last name), Telephone number. Such information may also include demographic information, geolocation information (IP Address), educational information, or commercial information. The information that we request will be retained by us and used as described in this privacy policy.<br/><br/>
    The Service does use third party services that may collect information used to identify you.<br/><br/>
    Links to privacy policies of third-party service providers used by the Service include but are not limited to:<br/>
    <ul>
    <li>Google</li>
    <li>LinkedIn</li>
    <li>Crunchbase</li>
    <li>Wikipedia</li>
    <li>Indeed</li>
    <li>U.S. Department of Labor</li>
    </ul>`,
  },
  {
    id: 3,
    category: "Personal and Other Information",
    details: `Through your interaction with and use of the Service, Interstride may collect Personal Information, including, but not limited to:<br/>
    <ul>
    <li>Your name and physical address, email addresses, and telephone numbers</li>
    <li>Traits, skills, knowledge or demographic attributes</li>
    <li>Information regarding your interests, job search inquiries and networking</li>
    <li>Webchat data that is captured by the system, encrypted and stored for up to 30 days. Users should not enter sensitive information into the webchat services</li> 
    </ul>
    Interstride may also collect Other Information through your interaction with and use of the Service and non-Interstride websites, which does not directly reveal your identity. Other Information may include, but is not limited to:<br/>
    <ul>
    <li>Unique IDs such as a cookie placed on your computer, mobile or device IDs</li>
    <li>Internet Protocol address (“IP address”) and information derived from your IP address such as your geographic location</li>
    <li>Usage data such as modules you accessed, webpages clicked, websites and content areas visited, date and time of activities</li>
    </ul>
    <b>Interstride uses and shares the Personal Information and Other Information it collects (unless otherwise restricted by applicable law), to:</b><br/>
    <ul>
    <li>Engage in Transactions. Interstride may use Personal Information to engage in transactions with you.</li>
    <li>Engage with your University. Interstride may use Personal Information and Other Information to provide insight and statistics to your institution.</li>
    <li>Provide Support or Other Services. Interstride may use your Personal Information to provide you support, and other similar operational communications and in helping identify potential employment opportunities for you.</li>
    <li>Tailor Marketing to Your Needs. Interstride may use your Personal Information and Other Information to notify you about new application releases and service developments.</li>
    <li>Support Student Online Community. Interstride permits you to participate in interactive discussions; post comments, opportunities or other content; communicate directly with another users; or otherwise engage in networking activities. Some of these services are moderated and all may be accessed for technical reasons. Interstride does not control the content that users post to this forum. You should carefully consider whether you wish to submit Personal Information to these forums.</li>
    <li>Interact with You on Third Party Social Networks. Interstride may use your Personal Information to interact with you on third-party social networks. Interstride’s interactions with you on a third-party social network would be subject to that network’s privacy policies and terms of use.</li>
    <li>Select Content, Improve Quality and Facilitate Use of the Service. Interstride may use your Personal Information to help create and personalize content on our Service, facilitate your use of the Service, evaluate and improve our Service.</li>
    </ul>
    <b>Interstride will only share your personal information under the following circumstances:</b><br/>
    <ul>
        <li>With your consent. For example, you may choose to make your profile available to employers through the Service. You may choose to apply to a job and share your profile information, resume and transcript with employers. You may choose to make your profile available to students at your institution or with students outside your institution.</li>
        <li>With your institution — Your institution administrators, faculty, staff, students, and alumni will be able to access and view personal data uploaded by you.</li>
        <li>With Vendors. Vendors that are contractually engaged by Interstride to provide services. These companies must comply with confidentiality and non-disclosure obligations with regards to any Personal Information.</li>
        <li>With Law Enforcement. Interstride may disclose your Personal Information as we believe to be necessary or appropriate: (a) under applicable law, including laws outside your country of residence; (b) to comply with legal process; (c) to respond to requests from public and government authorities, including public and government authorities outside your country of residence, for national security and/or law enforcement purposes; (d) to enforce our terms and conditions; and (e) to allow us to pursue available remedies or limit the damages that we may sustain.</li>
        <li>With Professional Services. In the event of a reorganization, merger, sale, joint venture, assignment, transfer or other disposition of all or any portion of our business, assets or stock (including in connection with any bankruptcy or similar proceedings), we may transfer the Personal Information we have collected to the relevant third party.</li>
    </ul>`,
  },
  {
    id: 4,
    category: "Other Information",
    details: `We may use, transfer, and disclose Other Information we collect for any purpose, except where applicable law requires otherwise. If we are required to treat Other Information as Personal Information under applicable law, then we will only use it in the same way that we are permitted to use and disclose Personal Information.`,
  },
  {
    id: 5,
    category: "Retention and Deletion",
    details: `Interstride will retain your Personal Information for as long as your account is active with the Service; as needed to provide you Service: or as needed for the purposes outlined in this Policy.`,
  },
  {
    id: 6,
    category: "FERPA – Family Rights and Privacy Act",
    details: `Interstride complies with FERPA guidelines and allows students the following rights regarding their educational records:
    <ul>
    <li>The right to access the student’s educational records to the extent any are stored on the Service.</li>
    <li>The right to demand the student’s educational records be disclosed only with student consent.</li>
    <li>The right to amend/modify the student’s educational records and Personal Information, and deactivate their account.</li>
    </ul>`,
  },
  {
    id: 7,
    category: "Log Data",
    details: `We want to inform you that whenever you use our Service, in a case of an error in the Service we collect data and information (through third party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the application when utilizing our Service, the time and date of your use of the Service, and other statistics.`,
  },
  {
    id: 8,
    category: "Cookies",
    details: `Cookies are files with small amount of data that are commonly used an anonymous unique identifier. These are sent to your browser from the website that you visit and are stored on your device internal memory.<br/><br/>
    This Service does not use these “cookies” explicitly. However, the app may use third party code and libraries that use “cookies” to collection information and to improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.`,
  },
  {
    id: 9,
    category: "Security",
    details: `We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.`,
  },
  {
    id: 10,
    category: "Do Not Track Requests",
    details: `We do not respond to the browser “Do Not Track” (DNT) signal if enabled by the user in their web browser or mobile application. When we set or read cookies on non-Interstride services that have been integrated into our Service and display features, we do so, for example, to provide a personalized search experience to users on our mobile application. We do not share any information about your use of Interstride with those third parties. When third parties that we have integrated into Interstride sites set or read their own third-party cookies, they may or may not respond to the DNT signal.`,
  },
  {
    id: 11,
    category: "Links to Other Sites",
    details: `The Service may make available links to other websites. When you click on such links, you may leave our Service. The Service may also make available Company Pages, which may contain information provided by third-party Employers. WE ARE NOT RESPONSIBLE FOR THE INFORMATION COLLECTION, USE, DISCLOSURE OR OTHER PRIVACY PRACTICES OF ANY THIRD PARTY, INCLUDING OUR AFFILIATES, THIRD-PARTY SERVICE PROVIDERS, ANY EMPLOYERS (INCLUDING ANY EMPLOYER THAT PROVIDES A COMPANY PAGE), ANY THIRD-PARTY SOCIAL MEDIA PLATFORM, ANY THIRD PARTY MAKING AVAILABLE THE DEVICES OR OPERATING SYSTEMS FOR WHICH THE SITE IS AVAILABLE, AND ANY THIRD PARTY OPERATING ANY WEBSITE TO WHICH THE SITE CONTAINS A LINK. YOU MAY HAVE RIGHTS DIRECTLY ENFORCEABLE AGAINST THESE THIRD PARTIES SO YOU SHOULD CONSIDER THEIR PRIVACY POLICIES TO LEARN MORE.`,
  },
  {
    id: 12,
    category: "Children’s Privacy",
    details: `These Services do not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to take necessary actions.`,
  },
  {
    id: 13,
    category: "Acquisitions and Other Reorganizations",
    details: `Information collected pursuant to this Privacy Policy, including Personal Information, may be disclosed to one or more third parties in connection with any change of ownership or control in Interstride’s business (whether by merger, sale, or otherwise), or any other reorganization or joint venture, or assignment, transfer or other disposition of all or any portion of our business, assets or stock (including in connection with any bankruptcy or similar proceeding), and any such information may be used by such third party in accordance with this Privacy Policy. Equally, information collected pursuant to this Privacy Policy, including Personal Information, may be disclosed to one or more third parties in connection with any acquisition or merger carried out by Interstride of such third parties.`,
  },
  {
    id: 14,
    category: "Data deletion request",
    details: `For any requests related to data deletion or removal of personal information, please send us your request by emailing us at <a href="mailto:contact@interstride.com">contact@interstride.com</a>. We will remove your personal information from our systems at our earliest convenience.`,
  },
  {
    id: 15,
    category: "Contact Us",
    details: `If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us:<br/><br/>
    Email: <a href="mailto:contact@interstride.com">contact@interstride.com</a><br/>
    Address: 5127 Meadow Creek Dr., Austin, TX 78745<br/><br/>
    Interstride’s use and transfer of information received from Google APIs to any other app will adhere to <a href="https://developers.google.com/terms/api-services-user-data-policy" target="_blank">Google API Services User Data Policy</a>, including the Limited Use requirements.
    `,
  },
];

export const FAQ = [
  {
    id: 1,
    category: "Welcome tutorial",
    image: <img alt="" src={WelcomeTutorial} />,
  },
  {
    id: 2,
    category: "General inquiries",
    image: <img alt="" src={General} />,
    QA: [
      {
        id: 1,
        question: "What is Interstride?",
        showVideo: true,
        answer:
          "Interstride is a technology platform that assists students with finding job opportunities, immigration hurdles, community integration, and overall university engagement.",
      },
      {
        id: 2,
        question: "Who can use Interstride?",
        answer:
          "Both domestic and international students can use Interstride. Interstride is for any undergraduate and graduate student interested in exploring employment opportunities in the U.S. or abroad. Interstride is especially useful for international students pursuing opportunities in the U.S.",
      },
      {
        id: 3,
        question: "How do I access Interstride?",
        answer: `Students whose college or university has subscribed to Interstride’s services can log in using their university-affiliated email address at <b>student.interstride.com</b> or through your university’s <b>single sign-on page</b>, if available. Please reach out to your career services office directly for any questions. If your university hasn’t subscribed to Interstride, let your career services know you’d like them to make this valuable resource available to you.`,
      },
      {
        id: 4,
        question:
          "My university email is about to expire. How do I continue to access Interstride?",
        answer:
          "Go to your Interstride profile and update your email in the ‘My Info’ section to ensure that you don’t lose access. You can also connect your LinkedIn account and login using your LinkedIn credentials.",
      },
      {
        id: 5,
        question: "Can I still access Interstride as an alumni?",
        answer:
          "Not at all! Alumni are welcome to use Interstride. You can request access from your university by going to students.interstride.com and entering your information. Be sure to include your institution’s name, and an administrator will verify and approve your request for Interstride access. To ensure that you receive access in a timely manner, please reach out to your career services office to let them know you have submitted a request.",
      },
      {
        id: 6,
        question: "How do I download the Interstride app?",
        answer:
          "Download the Interstride app directly from the iOS App store or Google Play.",
      },
      {
        id: 7,
        question: "Is Interstride available in China without a VPN?",
        answer:
          "In our experience, students in China can access the platform if their institution has an active subscription to Interstride.",
      },
      {
        id: 8,
        question:
          "Does Interstride have options for students with accessibility needs?",
        answer:
          "You can enable the ‘Enable Accessibility Mode’ feature in the Profile section.",
      },
      {
        id: 9,
        question: "How do I report a technical issue I am experiencing?",
        answer:
          "Send us an email at contact@interstride.com. We will respond to you in 1-2 days.",
      },
    ],
  },
  {
    id: 3,
    category: "Setting up your profile",
    image: <img alt="" src={SettingProfile} />,
    QA: [
      {
        id: 1,
        question: "Can I login using a different email?",
        answer:
          "If you prefer to login using your personal email, you can update your email under the ‘My Info’ section. Alternatively, you can connect your LinkedIn account and login using your LinkedIn credentials. If you are about to graduate, we recommend you update your email so you do not lose access after your institutional email expires.",
      },
      {
        id: 2,
        question: "Who can see my Interstride profile?",
        answer:
          "Admins, students, and alumni at your university can view your profile.",
      },
      {
        id: 3,
        question: "How do I make my profile private?",
        answer:
          "A public profile allows you to network with fellow students and alumni. To opt out, go to your profile and toggle off the ‘Profile visible to network’ button at the bottom of the screen. You may update your profile visibility at anytime.",
      },
      {
        id: 4,
        question: "How do I change my password?",
        answer:
          "If you are signing in via single sign-on (SSO), you will <b>not</b> be able to change your password on Interstride. Please contact your institution directly. For non-SSO users, please sign out and click on forgot password to reset your password.",
      },
      {
        id: 5,
        question: "How do I upload my resume or CV to Interstride?",
        answer:
          "Upload your CV to your profile to help you connect with employers and fellow student. On your profile, click the ‘Upload your CV’ button in the ‘My Career’ section.",
      },
      {
        id: 6,
        question:
          "How do I link my LinkedIn and social media accounts to my Interstride profile?",
        answer:
          "Go to your profile and enter the URL to your LinkedIn, Twitter, or Facebook profiles in the ‘My Career’ section.",
      },
      {
        id: 7,
        question: "How do I set up or adjust notifications?",
        answer:
          'To view your notifications, click the bell icon next to your profile picture on the upper-right corner of the screen. Go to "Notifications" and click "Notification Settings." You can adjust or turn off push and email notifications for networking alerts, job alerts, and more.',
      },
    ],
  },
  {
    id: 4,
    category: "Networking on Interstride",
    image: <img alt="" src={Networking} />,
    permission: "chat",
    QA: [
      {
        id: 1,
        question: "How do I network on Interstride?",
        answer:
          "Students can opt into the networking module to meet other domestic and international students and alumni worldwide. Go to Network and explore your network, direct message, and join or create new Community. Students can chat with their network through direct messaging or make audio and video calls. Whether you are looking for job search advice or support from international alumni, you can find it all here from your network!",
      },
      {
        id: 2,
        question:
          "Can I chat with university administrators directly through Interstride",
        answer:
          "Yes, students and alumni can chat directly with university admin through the networking and chat module.",
      },
      {
        id: 3,
        question: "How do I delete, report, or mute a chat?",
        answer:
          "Click on the three dots on the upper-right corner of the screen (above the person’s icon) to delete, mute, or report your chat.",
      },
      {
        id: 4,
        question:
          "What are Communities and how do I create or follow a new Community?",
        answer:
          "Interstride Communities are like Facebook Groups. Students may join or create new Communities, and invite and chat with fellow students. Like a Facebook Group, students may post content and updates in a Community. To create or follow a new Community, go to Network > Discover Communities > Create New Community (upper-right corner of the screen).",
      },
      {
        id: 5,
        question:
          "What is a group chat and how is this different from a Community?",
        answer:
          "A chat group is private, direct messaging between two or more people, whereas a Community is a public page that students may join and follow.",
      },
      {
        id: 6,
        question: "How do I create a new group chat or leave a group chat?",
        answer:
          "Go to Network > Messages > and click the blue + button to the right of the search bar to create a new chat or group chat. To leave a group chat, click on the three dots on the upper right-hand corner of the screen (above the group icon) to leave, mute, or report a group chat.",
      },
      {
        id: 7,
        question:
          "How do I report inappropriate behavior on a Community or block a user on Interstride?",
        answer:
          "Report any complains, abusive language, or inappropriate behavior or content by sending us an email at <b>contact@interstride.com</b>.",
      },
      {
        id: 8,
        question: "How do I manage notifications on Communities I follow?",
        answer:
          "Click the bell icon next to your profile in the upper-right corner > select Communities > and adjust notifications to your preferences.",
      },
    ],
  },
  {
    id: 5,
    category: "Job Search",
    image: <img alt="" src={JobSearch} />,
    QA: [
      {
        id: 1,
        question: "How are your job and internship listings found and vetted?",
        answer:
          "Interstride displays <b>full-time opportunities or paid internships</b> from large global employers. There are no contract or part-time positions. This removes noise from listings. Interstride cross-verifies Job listing with our own database of companies to display the most attractive employers first. This especially beneficial for international students as most jobs that provide sponsorship come from large global employers.",
      },
      {
        id: 2,
        question:
          "Does Interstride also list non-profit, government, and NGO jobs in addition to private sector opportunities?",
        answer:
          "Yes. The job search function is not limited to jobs in the for-profit or private sector. Students can use filter options or keywords to search for jobs in various industries. Be sure to explore H-1B cap-exempt opportunities with non-profit organizations and higher education institutions.",
      },
      {
        id: 3,
        question: "Can I search jobs by country or U.S. visa type?",
        answer:
          "Students can filter jobs by keyword, country, job title, U.S. visa type, and more. Interstride’s algorithms will also recommend searches based on the student’s school, field, major, and interests.",
      },
      {
        id: 4,
        question:
          "How do I make sure I’m notified when relevant jobs become available?",
        answer:
          "To create a job alert, click ‘Save Search’ at the bottom of the filter menu (below ‘Apply Changes’). Name your search and select your preferred notification frequency. Your saved searches will now appear under the ‘Job Alerts’ tab and you will receive email updates according to your selected notification frequency.",
      },
      {
        id: 5,
        question: "How do I save a job on my dashboard?",
        answer:
          "You can save a job by clicking the star next to the job listing. Saved jobs will display along with saved searches in the ‘Job Alerts’ tab.",
      },
      {
        id: 6,
        question: "How do I unsubscribe from a job alert?",
        answer:
          "Click the bell icon next to your profile in the upper-right corner > select Jobs > and adjust notifications to your preferences.",
      },
      {
        id: 7,
        question: "Why are there fewer results when I search by keywords?",
        answer:
          "Keyword results are based off job listing titles. Companies may use different job titles so be sure to search using different keywords.",
      },
    ],
  },
  {
    id: 6,
    category: "US Visa Insights",
    image: <img alt="" src={VisaInsights} />,
    permission: "visa_insights",
    QA: [
      {
        id: 1,
        question:
          "What are U.S. Visa Insights and how do I take advantage of this in my job search?",
        answer:
          "Interstride’s U.S. Visa Insights provide you with the latest and most accurate hiring trends of companies offering visa sponsorship. You can search job opportunities by U.S. visa type, company, country, experience level, and more. International students can view the number of visa petitions or applications submitted for each company or position at the company.",
      },
      {
        id: 2,
        question:
          "What is a visa petition and why is this important for an international student’s job search journey?",
        answer:
          "In U.S. immigration law, a petition is a request made by a foreign national to U.S. Citizenship and Immigration Services (USCIS), which upon approval, allows the foreign national to submit an official visa application. Reviewing visa petitions allows you to gauge whether a company is likely to offer visa sponsorship for your position of interest. Additionally, you can see whether or not that company will hire at your specific experience level (e.g. entry level). If a position does not show any entry level petitions, it means that the company does not typically hire an entry level worker. We recommend you adjust your search according to positions that hire at the entry level to maximize your job hunt efforts.",
      },
      {
        id: 3,
        question: "Where does your data & insights come from?",
        answer:
          "We source this information from the U.S. Department of Labor, Student and Exchange Visitor Program (SEVIS), and United States Citizenship and Immigration Services (USCIS).",
      },
      {
        id: 4,
        question: "Can I search by U.S. visa type?",
        answer:
          "Yes. You may filter searches by the following visa types: H-1B, Green Card, OPT, STEM OPT, and CPT.",
      },
      {
        id: 5,
        question: "What do the different job experience levels mean?",
        answer: `
          <ul>
            <li>Experience levels are defined by the U.S. Department of Labor.</li>
            <li><b>Entry (Level I)</b> - wage rates are assigned to job offers for beginning level employees who have only a basic understanding of the occupation. These employees perform routine tasks that require limited, if any, exercise of judgment. The tasks provide experience and familiarization with the employer’s methods, practices, and programs. The employees may perform higher level work for training and developmental purposes. <ul><li>These employees work under close supervision and receive specific instructions on required tasks and results expected. Their work is closely monitored and reviewed for accuracy. Statements that the job offer is for a research fellow, a worker in training, or an internship are indicators that a Level I wage should be considered.</li></ul></li>
            <li><b>Qualified (Level II)</b> wage rates are assigned to job offers for qualified employees who have attained, either through education or experience, a good understanding of the occupation. They perform moderately complex tasks that require limited judgment. An indicator that the job request warrants a wage determination at Level II would be a requirement for years of education and/or experience that are generally required as described in the O*NET Job Zones.</li>
            <li><b>Experienced (Level III)</b> wage rates are assigned to job offers for experienced employees who have a sound understanding of the occupation and have attained, either through education or experience, special skills or knowledge. They perform tasks that require exercising judgment and may coordinate the activities of other staff. They may have supervisory authority over those staff. A requirement for years of experience or educational degrees that are at the higher ranges indicated in the O*NET Job Zones would be indicators that a Level III wage should be considered. <ul><li>Frequently, key words in the job title can be used as indicators that an employer’s job offer is for an experienced worker. Words such as ‘lead’ (lead analyst), ‘senior’ (senior programmer), ‘head’ (head nurse), ‘chief’ (crew chief), or ‘journeyman’ (journeyman plumber) would be indicators that a Level III wage should be considered.</li></ul></li>
            <li><b>Fully Competent (Level IV)</b> wage rates are assigned to job offers for competent employees who have sufficient experience in the occupation to plan and conduct work requiring judgment and the independent evaluation, selection, modification, and application of standard procedures and techniques. Such employees use advanced skills and diversified knowledge to solve unusual and complex problems. These employees receive only technical guidance and their work is reviewed only for application of sound judgment and effectiveness in meeting the establishment’s procedures and expectations. They generally have management and/or supervisory responsibilities.</li>
          </ul>
        `,
      },
    ],
  },
  {
    id: 7,
    category: "Country Insights",
    image: <img alt="" src={CountryInsights} />,
    permission: "country_insights",
    QA: [
      {
        id: 1,
        question:
          "What are Country Insights and how does this benefit domestic and international students?",
        answer:
          "Country Insights offer in-depth information for over 180+ countries. Whether you are a domestic student looking to study abroad or find an international job, or you’re an international student looking into job opportunities in other countries, country insights is the perfect place to jumpstart your search.",
      },
      {
        id: 2,
        question:
          "How do I research specific countries I’m interested in living in?",
        answer:
          "Go to Jobs > Country Insights. You can search countries by name and learn more about visa & work permits, immigration, health & travel advisories, and more.",
      },
      {
        id: 3,
        question: "Where does your data and country insights come from?",
        answer:
          "We source real-time information for 180+ countries from 16 different sources.",
      },
    ],
  },
  {
    id: 8,
    category: "Webinars, resources, & tools",
    image: <img alt="" src={WebinarTools} />,
    QA: [
      {
        id: 1,
        permission: "webinar",
        question:
          "What kind of webinars do you offer and how do I register for a webinar?",
        answer:
          "Each month, Interstride offers a variety of webinars on visa and immigration, job search strategy, career development, and more. To register for webinars, go to Learn >  Webinars.  Be sure to add the webinar event details to your calendar so you don’t miss it! In case you miss a webinar, you can access the recording on the Interstride portal afterwards.",
      },
      {
        id: 2,
        permission: "webinar",
        question: "I cannot attend a webinar. How do I access the recording?",
        answer:
          "We recommend you register for a webinar even if you cannot attend. If the webinar is hosted by an Interstride partner, they may email you a link to the recording afterwards. If a webinar is hosted by Interstride, go to Learn > Webinars > On Demand. You can access the recording link here and watch at your convenience.",
      },
      {
        id: 3,
        permission: "webinar",
        question: "Which webinars do you recommend?",
        answer:
          "We highly recommend you register for Interstride’s monthly ‘Online Training for Students’ and ‘Job Search Strategy for International Students’. Go to Learn > Webinars to register now.",
      },
      {
        id: 4,
        permission: "gameplan",
        question:
          "What is the Career Gameplan and why should I take this quiz?",
        answer:
          "The Career Gameplan is a self assessment test that aims to highlight some of the important attributes that employers look at when hiring entry-level candidates, including cultural knowledge, professional experience, communication, professional skills, and more. This tool is based on the National Association of Colleges and Employers’ (NACE) 8 common Career Readiness Competencies. To take this 3-minute quiz, go to Tools > Career Gameplan. Based on your score, Interstride will provide actionable tips and suggest resources to improve your employment profile.",
      },
      {
        id: 5,
        permission: "gameplan",
        question: "Can I take the Career Gameplan quiz more than once?",
        answer:
          "Yes. We recommend that students retake the quiz throughout their job search journey to measure and track their progress.",
      },
      {
        id: 6,
        permission: "resources",
        question:
          "Where can I find relevant articles, videos and resources on job search or immigration?",
        answer:
          "Go to Learn > Resources to find articles, videos, and other resources on various Topics. Your university will also add recommendations and resources for you so be sure to check often for new updates.",
      },
      {
        id: 7,
        permission: "resources",
        question:
          "How do I save a resource and view my library of saved resources?",
        answer:
          "Click the star to save a resource. You can view your saved videos and resources at any time in the Saved Resources section in the bottom-right corner of the Resources page.",
      },
      {
        id: 8,
        permission: "student_services",
        question: "What student service recommendations do you offer?",
        answer:
          "Interstride offers service recommendations and resources for a wide range of Topics including scholarships, credit cards, insurance, travel, and more. Browse them all by going to Learn > Student Services. Your university will also add recommendations and resources for you so be sure to check often for new updates.",
      },
      {
        id: 9,
        permission: "student_services",
        question:
          "Can I receive alerts for new resources or student services that are added to the Interstride platform?",
        answer:
          'Yes. You can sign up for alerts from any one of our resource categories, including General Career Advice, Networking, Non-Career Resources, and more.  Similarly, you can subscribe to student services alerts for scholarships, housing, and more. To view and adjust your notifications, click the bell icon next to your profile picture on the upper-right corner of the screen. Go to "Notifications" and click "Notification Settings” to adjust or turn off push and email notifications.',
      },
      {
        id: 10,
        permission: "checklist",
        question: "What is the Tools checklist and how do I create one?",
        answer:
          "The Tools checklist is great for organizing and prioritizing tasks throughout your job search. To create a checklist, go to Tools > Checklists. Add tasks by clicking the ‘Add a new task’ button, and add and edit subtasks within each task. While you can create your own personalized checklists, Interstride offers a number of curated checklists complete with specific resources that can help you complete each task.",
      },
      {
        id: 11,
        permission: "appointment",
        question: "Can I sync my Interstride calendar with my other calendars?",
        answer:
          "Yes. Go to > click on ‘ Sync with your calendar’ in the top-right corner to link your Google, Outlook, or Apple account.",
      },
    ],
  },
];

export const TIMEZONE_LIST = [
  {
    value: "(GMT-05:00) Eastern Time (US & Canada)",
    label: "(GMT-05:00) Eastern Time (US & Canada)",
  },
  {
    value: "(GMT-06:00) Central Time (US & Canada)",
    label: "(GMT-06:00) Central Time (US & Canada)",
  },
  {
    value: "(GMT-07:00) Mountain Time (US & Canada)",
    label: "(GMT-07:00) Mountain Time (US & Canada)",
  },
  {
    value: "(GMT-08:00) Pacific Time (US & Canada)",
    label: "(GMT-08:00) Pacific Time (US & Canada)",
  },
  {
    value: "(GMT-12:00) International Date Line West",
    label: "(GMT-12:00) International Date Line West",
  },
  {
    value: "(GMT-11:00) Midway Island, Samoa",
    label: "(GMT-11:00) Midway Island, Samoa",
  },
  { value: "(GMT-10:00) Hawaii", label: "(GMT-10:00) Hawaii" },
  { value: "(GMT-09:00) Alaska", label: "(GMT-09:00) Alaska" },
  {
    value: "(GMT-08:00) Tijuana, Baja California",
    label: "(GMT-08:00) Tijuana, Baja California",
  },
  { value: "(GMT-07:00) Arizona", label: "(GMT-07:00) Arizona" },
  {
    value: "(GMT-07:00) Chihuahua, La Paz, Mazatlan",
    label: "(GMT-07:00) Chihuahua, La Paz, Mazatlan",
  },
  {
    value: "(GMT-06:00) Central America",
    label: "(GMT-06:00) Central America",
  },
  {
    value: "(GMT-06:00) Guadalajara, Mexico City, Monterrey",
    label: "(GMT-06:00) Guadalajara, Mexico City, Monterrey",
  },
  { value: "(GMT-06:00) Saskatchewan", label: "(GMT-06:00) Saskatchewan" },
  {
    value: "(GMT-05:00) Bogota, Lima, Quito, Rio Branco",
    label: "(GMT-05:00) Bogota, Lima, Quito, Rio Branco",
  },
  { value: "(GMT-05:00) Indiana (East)", label: "(GMT-05:00) Indiana (East)" },
  {
    value: "(GMT-04:00) Atlantic Time (Canada)",
    label: "(GMT-04:00) Atlantic Time (Canada)",
  },
  {
    value: "(GMT-04:00) Caracas, La Paz",
    label: "(GMT-04:00) Caracas, La Paz",
  },
  { value: "(GMT-04:00) Manaus", label: "(GMT-04:00) Manaus" },
  { value: "(GMT-04:00) Santiago", label: "(GMT-04:00) Santiago" },
  { value: "(GMT-03:30) Newfoundland", label: "(GMT-03:30) Newfoundland" },
  { value: "(GMT-03:00) Brasilia", label: "(GMT-03:00) Brasilia" },
  {
    value: "(GMT-03:00) Buenos Aires, Georgetown",
    label: "(GMT-03:00) Buenos Aires, Georgetown",
  },
  { value: "(GMT-03:00) Greenland", label: "(GMT-03:00) Greenland" },
  { value: "(GMT-03:00) Montevideo", label: "(GMT-03:00) Montevideo" },
  { value: "(GMT-02:00) Mid-Atlantic", label: "(GMT-02:00) Mid-Atlantic" },
  { value: "(GMT-01:00) Cape Verde Is.", label: "(GMT-01:00) Cape Verde Is." },
  { value: "(GMT-01:00) Azores", label: "(GMT-01:00) Azores" },
  {
    value: "(GMT+00:00) Casablanca, Monrovia, Reykjavik",
    label: "(GMT+00:00) Casablanca, Monrovia, Reykjavik",
  },
  {
    value:
      "(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London",
    label:
      "(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London",
  },
  {
    value: "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
    label: "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
  },
  {
    value: "(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
    label: "(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
  },
  {
    value: "(GMT+01:00) Brussels, Copenhagen, Madrid, Paris",
    label: "(GMT+01:00) Brussels, Copenhagen, Madrid, Paris",
  },
  {
    value: "(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
    label: "(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
  },
  {
    value: "(GMT+01:00) West Central Africa",
    label: "(GMT+01:00) West Central Africa",
  },
  { value: "(GMT+02:00) Amman", label: "(GMT+02:00) Amman" },
  {
    value: "(GMT+02:00) Athens, Bucharest, Istanbul",
    label: "(GMT+02:00) Athens, Bucharest, Istanbul",
  },
  { value: "(GMT+02:00) Beirut", label: "(GMT+02:00) Beirut" },
  { value: "(GMT+02:00) Cairo", label: "(GMT+02:00) Cairo" },
  {
    value: "(GMT+02:00) Harare, Pretoria",
    label: "(GMT+02:00) Harare, Pretoria",
  },
  {
    value: "(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
    label: "(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
  },
  { value: "(GMT+02:00) Jerusalem", label: "(GMT+02:00) Jerusalem" },
  { value: "(GMT+02:00) Minsk", label: "(GMT+02:00) Minsk" },
  { value: "(GMT+02:00) Windhoek", label: "(GMT+02:00) Windhoek" },
  {
    value: "(GMT+03:00) Kuwait, Riyadh, Baghdad",
    label: "(GMT+03:00) Kuwait, Riyadh, Baghdad",
  },
  {
    value: "(GMT+03:00) Moscow, St. Petersburg, Volgograd",
    label: "(GMT+03:00) Moscow, St. Petersburg, Volgograd",
  },
  { value: "(GMT+03:00) Nairobi", label: "(GMT+03:00) Nairobi" },
  { value: "(GMT+03:00) Tbilisi", label: "(GMT+03:00) Tbilisi" },
  { value: "(GMT+03:30) Tehran", label: "(GMT+03:30) Tehran" },
  {
    value: "(GMT+04:00) Abu Dhabi, Muscat",
    label: "(GMT+04:00) Abu Dhabi, Muscat",
  },
  { value: "(GMT+04:00) Baku", label: "(GMT+04:00) Baku" },
  { value: "(GMT+04:00) Yerevan", label: "(GMT+04:00) Yerevan" },
  { value: "(GMT+04:30) Kabul", label: "(GMT+04:30) Kabul" },
  { value: "(GMT+05:00) Yekaterinburg", label: "(GMT+05:00) Yekaterinburg" },
  {
    value: "(GMT+05:00) Islamabad, Karachi, Tashkent",
    label: "(GMT+05:00) Islamabad, Karachi, Tashkent",
  },
  {
    value: "(GMT+05:30) Sri Jayawardenapura",
    label: "(GMT+05:30) Sri Jayawardenapura",
  },
  {
    value: "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi",
    label: "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi",
  },
  { value: "(GMT+05:45) Kathmandu", label: "(GMT+05:45) Kathmandu" },
  {
    value: "(GMT+06:00) Almaty, Novosibirsk",
    label: "(GMT+06:00) Almaty, Novosibirsk",
  },
  { value: "(GMT+06:00) Astana, Dhaka", label: "(GMT+06:00) Astana, Dhaka" },
  {
    value: "(GMT+06:30) Yangon (Rangoon)",
    label: "(GMT+06:30) Yangon (Rangoon)",
  },
  {
    value: "(GMT+07:00) Bangkok, Hanoi, Jakarta",
    label: "(GMT+07:00) Bangkok, Hanoi, Jakarta",
  },
  { value: "(GMT+07:00) Krasnoyarsk", label: "(GMT+07:00) Krasnoyarsk" },
  {
    value: "(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
    label: "(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
  },
  {
    value: "(GMT+08:00) Kuala Lumpur, Singapore",
    label: "(GMT+08:00) Kuala Lumpur, Singapore",
  },
  {
    value: "(GMT+08:00) Irkutsk, Ulaan Bataar",
    label: "(GMT+08:00) Irkutsk, Ulaan Bataar",
  },
  { value: "(GMT+08:00) Perth", label: "(GMT+08:00) Perth" },
  { value: "(GMT+08:00) Taipei", label: "(GMT+08:00) Taipei" },
  {
    value: "(GMT+09:00) Osaka, Sapporo, Tokyo",
    label: "(GMT+09:00) Osaka, Sapporo, Tokyo",
  },
  { value: "(GMT+09:00) Seoul", label: "(GMT+09:00) Seoul" },
  { value: "(GMT+09:00) Yakutsk", label: "(GMT+09:00) Yakutsk" },
  { value: "(GMT+09:30) Adelaide", label: "(GMT+09:30) Adelaide" },
  { value: "(GMT+09:30) Darwin", label: "(GMT+09:30) Darwin" },
  { value: "(GMT+10:00) Brisbane", label: "(GMT+10:00) Brisbane" },
  {
    value: "(GMT+10:00) Canberra, Melbourne, Sydney",
    label: "(GMT+10:00) Canberra, Melbourne, Sydney",
  },
  { value: "(GMT+10:00) Hobart", label: "(GMT+10:00) Hobart" },
  {
    value: "(GMT+10:00) Guam, Port Moresby",
    label: "(GMT+10:00) Guam, Port Moresby",
  },
  { value: "(GMT+10:00) Vladivostok", label: "(GMT+10:00) Vladivostok" },
  {
    value: "(GMT+11:00) Magadan, Solomon Is., New Caledonia",
    label: "(GMT+11:00) Magadan, Solomon Is., New Caledonia",
  },
  {
    value: "(GMT+12:00) Auckland, Wellington",
    label: "(GMT+12:00) Auckland, Wellington",
  },
  {
    value: "(GMT+12:00) Fiji, Kamchatka, Marshall Is.",
    label: "(GMT+12:00) Fiji, Kamchatka, Marshall Is.",
  },
  { value: "(GMT+13:00) Nuku'alofa", label: "(GMT+13:00) Nuku'alofa" },
];

export const IMMIGRATION_STATUS_OPTIONS = [
  { label: "International student", value: "International student" },
  { label: "DACA", value: "DACA" },
  { label: "U.S. Citizen", value: "U.S. Citizen" },
  { label: "Permanent Resident", value: "Permanent Resident" },
  { label: "Other U.S. visa", value: "Other U.S. visa" },
  { label: "Prefer not to say", value: "Prefer not to say" },
];

export const LANGUAGE_LIST = [
  {
    value: "Afrikaans",
    label: "Afrikaans",
  },
  {
    value: "Albanian",
    label: "Albanian",
  },
  {
    value: "Arabic",
    label: "Arabic",
  },
  {
    value: "Armenian",
    label: "Armenian",
  },
  {
    value: "Basque",
    label: "Basque",
  },
  {
    value: "Bengali",
    label: "Bengali",
  },
  {
    value: "Bulgarian",
    label: "Bulgarian",
  },
  {
    value: "Catalan",
    label: "Catalan",
  },
  {
    value: "Cambodian",
    label: "Cambodian",
  },
  {
    value: "Chinese",
    label: "Chinese",
  },
  {
    value: "Croatian",
    label: "Croatian",
  },
  {
    value: "Czech",
    label: "Czech",
  },
  {
    value: "Danish",
    label: "Danish",
  },
  {
    value: "Dutch",
    label: "Dutch",
  },
  {
    value: "English",
    label: "English",
  },
  {
    value: "Estonian",
    label: "Estonian",
  },
  {
    value: "Fiji",
    label: "Fiji",
  },
  {
    value: "Finnish",
    label: "Finnish",
  },
  {
    value: "French",
    label: "French",
  },
  {
    value: "Georgian",
    label: "Georgian",
  },
  {
    value: "German",
    label: "German",
  },
  {
    value: "Greek",
    label: "Greek",
  },
  {
    value: "Gujarati",
    label: "Gujarati",
  },
  {
    value: "Hebrew",
    label: "Hebrew",
  },
  {
    value: "Hindi",
    label: "Hindi",
  },
  {
    value: "Hungarian",
    label: "Hungarian",
  },
  {
    value: "Icelandic",
    label: "Icelandic",
  },
  {
    value: "Indonesian",
    label: "Indonesian",
  },
  {
    value: "Irish",
    label: "Irish",
  },
  {
    value: "Italian",
    label: "Italian",
  },
  {
    value: "Japanese",
    label: "Japanese",
  },
  {
    value: "Javanese",
    label: "Javanese",
  },
  {
    value: "Korean",
    label: "Korean",
  },
  {
    value: "Latin",
    label: "Latin",
  },
  {
    value: "Latvian",
    label: "Latvian",
  },
  {
    value: "Lithuanian",
    label: "Lithuanian",
  },
  {
    value: "Macedonian",
    label: "Macedonian",
  },
  {
    value: "Malay",
    label: "Malay",
  },
  {
    value: "Malayalam",
    label: "Malayalam",
  },
  {
    value: "Maltese",
    label: "Maltese",
  },
  {
    value: "Maori",
    label: "Maori",
  },
  {
    value: "Marathi",
    label: "Marathi",
  },
  {
    value: "Mongolian",
    label: "Mongolian",
  },
  {
    value: "epali",
    label: "epali",
  },
  {
    value: "orwegian",
    label: "orwegian",
  },
  {
    value: "Persian",
    label: "Persian",
  },
  {
    value: "Polish",
    label: "Polish",
  },
  {
    value: "Portuguese",
    label: "Portuguese",
  },
  {
    value: "Punjabi",
    label: "Punjabi",
  },
  {
    value: "Quechua",
    label: "Quechua",
  },
  {
    value: "Romanian",
    label: "Romanian",
  },
  {
    value: "Russian",
    label: "Russian",
  },
  {
    value: "Samoan",
    label: "Samoan",
  },
  {
    value: "Serbian",
    label: "Serbian",
  },
  {
    value: "Slovak",
    label: "Slovak",
  },
  {
    value: "Slovenian",
    label: "Slovenian",
  },
  {
    value: "Spanish",
    label: "Spanish",
  },
  {
    value: "Swahili",
    label: "Swahili",
  },
  {
    value: "Swedish ",
    label: "Swedish ",
  },
  {
    value: "Tamil",
    label: "Tamil",
  },
  {
    value: "Tatar",
    label: "Tatar",
  },
  {
    value: "Telugu",
    label: "Telugu",
  },
  {
    value: "Thai",
    label: "Thai",
  },
  {
    value: "Tibetan",
    label: "Tibetan",
  },
  {
    value: "Tonga",
    label: "Tonga",
  },
  {
    value: "Turkish",
    label: "Turkish",
  },
  {
    value: "Ukrainian",
    label: "Ukrainian",
  },
  {
    value: "Urdu",
    label: "Urdu",
  },
  {
    value: "Uzbek",
    label: "Uzbek",
  },
  {
    value: "Vietnamese",
    label: "Vietnamese",
  },
  {
    value: "Welsh",
    label: "Welsh",
  },
  {
    value: "Xhosa",
    label: "Xhosa",
  },
];

export const US_STATE_OPTIONS = [
  {
    value: "AK",
    label: "Alaska",
  },
  {
    value: "AL",
    label: "Alabama",
  },
  {
    value: "AR",
    label: "Arkansas",
  },
  {
    value: "AZ",
    label: "Arizona",
  },
  {
    value: "CA",
    label: "California",
  },
  {
    value: "CO",
    label: "Colorado",
  },
  {
    value: "CT",
    label: "Connecticut",
  },
  {
    value: "DC",
    label: "District of Columbia",
  },
  {
    value: "DE",
    label: "Delaware",
  },
  {
    value: "FL",
    label: "Florida",
  },
  {
    value: "GA",
    label: "Georgia",
  },
  {
    value: "GU",
    label: "Guam",
  },
  {
    value: "HI",
    label: "Hawaii",
  },
  {
    value: "IA",
    label: "Iowa",
  },
  {
    value: "ID",
    label: "Idaho",
  },
  {
    value: "IL",
    label: "Illinois",
  },
  {
    value: "IN",
    label: "Indiana",
  },
  {
    value: "KS",
    label: "Kansas",
  },
  {
    value: "KY",
    label: "Kentucky",
  },
  {
    value: "LA",
    label: "Louisiana",
  },
  {
    value: "MA",
    label: "Massachusetts",
  },
  {
    value: "MD",
    label: "Maryland",
  },
  {
    value: "ME",
    label: "Maine",
  },
  {
    value: "MI",
    label: "Michigan",
  },
  {
    value: "MN",
    label: "Minnesota",
  },
  {
    value: "MO",
    label: "Missouri",
  },
  {
    value: "MS",
    label: "Mississippi",
  },
  {
    value: "MT",
    label: "Montana",
  },
  {
    value: "NC",
    label: "North Carolina",
  },
  {
    value: "ND",
    label: "North Dakota",
  },
  {
    value: "NE",
    label: "Nebraska",
  },
  {
    value: "NH",
    label: "New Hampshire",
  },
  {
    value: "NJ",
    label: "New Jersey",
  },
  {
    value: "NM",
    label: "New Mexico",
  },
  {
    value: "NV",
    label: "Nevada",
  },
  {
    value: "NY",
    label: "New York",
  },
  {
    value: "OH",
    label: "Ohio",
  },
  {
    value: "OK",
    label: "Oklahoma",
  },
  {
    value: "OR",
    label: "Oregon",
  },
  {
    value: "PA",
    label: "Pennsylvania",
  },
  {
    value: "PR",
    label: "Puerto Rico",
  },
  {
    value: "RI",
    label: "Rhode Island",
  },
  {
    value: "SC",
    label: "South Carolina",
  },
  {
    value: "SD",
    label: "South Dakota",
  },
  {
    value: "TN",
    label: "Tennessee",
  },
  {
    value: "TX",
    label: "Texas",
  },
  {
    value: "UT",
    label: "Utah",
  },
  {
    value: "VA",
    label: "Virginia",
  },
  {
    value: "VT",
    label: "Vermont",
  },
  {
    value: "WA",
    label: "Washington",
  },
  {
    value: "WI",
    label: "Wisconsin",
  },
  {
    value: "WV",
    label: "West Virginia",
  },
  {
    value: "WY",
    label: "Wyoming",
  },
];

export const JOB_POSITION_OPTIONS = [
  { value: "full_time", label: "Full-time" },
  { value: "part_time", label: "Part-time" },
  { value: "internship", label: "Internship" },
];

export const JOB_WORK_TYPE_OPTIONS = [
  { value: "work_type_remote", label: "Remote" },
  { value: "hybrid", label: "Hybrid" },
  { value: "office", label: "In office" },
];

export const JOB_DATE_POSTED_OPTIONS = [
  { value: "", label: "Anytime" },
  { value: "30", label: "Within 30 days" },
  { value: "15", label: "Within 15 days" },
  { value: "7", label: "Within 7 days" },
  { value: "3", label: "Within 3 days" },
  { value: "1", label: "Since Yesterday" },
];

export const DEGREE_OPTIONS = [
  {
    label: "Associate",
    value: "Associate",
  },
  {
    label: "Bachelors",
    value: "Bachelors",
  },
  {
    label: "Masters",
    value: "Masters",
  },
  {
    label: "Doctoral",
    value: "Doctoral",
  },
  {
    label: "Post-doctoral",
    value: "Post-doctoral",
  },
  { label: "Others", value: "Others" },
];

export const JOB_DEGREE_OPTIONS = [
  {
    label: "Bachelor",
    value: "bachelors",
  },
  {
    label: "Master",
    value: "masters",
  },
  {
    label: "Doctoral",
    value: "doctoral",
  },
];

export const JOB_EXPERIENCE_OPTIONS = [
  {
    label: "Entry-level",
    value: "entry_level",
  },
  {
    label: "Intermediate",
    value: "intermediate_level",
  },
  {
    label: "Experienced",
    value: "experience_level",
  },
  {
    label: "Advanced",
    value: "senior_level",
  },
];

export const STAFF_DEGREE_OPTIONS = [
  {
    label: "Staff & Faculty",
    value: "Staff & Faculty",
  },
];

export const TIME_OPTIONS = [
  {
    label: "12:00 AM",
    value: 0,
  },
  {
    label: "12:15 AM",
    value: 15,
  },
  {
    label: "12:30 AM",
    value: 30,
  },
  {
    label: "12:45 AM",
    value: 45,
  },
  {
    label: "01:00 AM",
    value: 60,
  },
  {
    label: "01:15 AM",
    value: 75,
  },
  {
    label: "01:30 AM",
    value: 90,
  },
  {
    label: "01:45 AM",
    value: 105,
  },
  {
    label: "02:00 AM",
    value: 120,
  },
  {
    label: "02:15 AM",
    value: 135,
  },
  {
    label: "02:30 AM",
    value: 150,
  },
  {
    label: "02:45 AM",
    value: 165,
  },
  {
    label: "03:00 AM",
    value: 180,
  },
  {
    label: "03:15 AM",
    value: 195,
  },
  {
    label: "03:30 AM",
    value: 210,
  },
  {
    label: "03:45 AM",
    value: 225,
  },
  {
    label: "04:00 AM",
    value: 240,
  },
  {
    label: "04:15 AM",
    value: 255,
  },
  {
    label: "04:30 AM",
    value: 270,
  },
  {
    label: "04:45 AM",
    value: 285,
  },
  {
    label: "05:00 AM",
    value: 300,
  },
  {
    label: "05:15 AM",
    value: 315,
  },
  {
    label: "05:30 AM",
    value: 330,
  },
  {
    label: "05:45 AM",
    value: 345,
  },
  {
    label: "06:00 AM",
    value: 360,
  },
  {
    label: "06:15 AM",
    value: 375,
  },
  {
    label: "06:30 AM",
    value: 390,
  },
  {
    label: "06:45 AM",
    value: 405,
  },
  {
    label: "07:00 AM",
    value: 420,
  },
  {
    label: "07:15 AM",
    value: 435,
  },
  {
    label: "07:30 AM",
    value: 450,
  },
  {
    label: "07:45 AM",
    value: 465,
  },
  {
    label: "08:00 AM",
    value: 480,
  },
  {
    label: "08:15 AM",
    value: 495,
  },
  {
    label: "08:30 AM",
    value: 510,
  },
  {
    label: "08:45 AM",
    value: 525,
  },
  {
    label: "09:00 AM",
    value: 540,
  },
  {
    label: "09:15 AM",
    value: 555,
  },
  {
    label: "09:30 AM",
    value: 570,
  },
  {
    label: "09:45 AM",
    value: 585,
  },
  {
    label: "10:00 AM",
    value: 600,
  },
  {
    label: "10:15 AM",
    value: 615,
  },
  {
    label: "10:30 AM",
    value: 630,
  },
  {
    label: "10:45 AM",
    value: 645,
  },
  {
    label: "11:00 AM",
    value: 660,
  },
  {
    label: "11:15 AM",
    value: 675,
  },
  {
    label: "11:30 AM",
    value: 690,
  },
  {
    label: "11:45 AM",
    value: 705,
  },
  {
    label: "12:00 PM",
    value: 720,
  },

  {
    label: "12:15 PM",
    value: 735,
  },
  {
    label: "12:30 PM",
    value: 750,
  },
  {
    label: "12:45 PM",
    value: 765,
  },
  {
    label: "01:00 PM",
    value: 780,
  },
  {
    label: "01:15 PM",
    value: 795,
  },
  {
    label: "01:30 PM",
    value: 810,
  },
  {
    label: "01:45 PM",
    value: 825,
  },
  {
    label: "02:00 PM",
    value: 840,
  },
  {
    label: "02:15 PM",
    value: 855,
  },
  {
    label: "02:30 PM",
    value: 870,
  },
  {
    label: "02:45 PM",
    value: 885,
  },
  {
    label: "03:00 PM",
    value: 900,
  },
  {
    label: "03:15 PM",
    value: 915,
  },
  {
    label: "03:30 PM",
    value: 930,
  },
  {
    label: "03:45 PM",
    value: 945,
  },
  {
    label: "04:00 PM",
    value: 960,
  },
  {
    label: "04:15 PM",
    value: 975,
  },
  {
    label: "04:30 PM",
    value: 990,
  },
  {
    label: "04:45 PM",
    value: 1005,
  },
  {
    label: "05:00 PM",
    value: 1020,
  },
  {
    label: "05:15 PM",
    value: 1035,
  },
  {
    label: "05:30 PM",
    value: 1050,
  },
  {
    label: "05:45 PM",
    value: 1065,
  },
  {
    label: "06:00 PM",
    value: 1080,
  },
  {
    label: "06:15 PM",
    value: 1095,
  },
  {
    label: "06:30 PM",
    value: 1110,
  },
  {
    label: "06:45 PM",
    value: 1125,
  },
  {
    label: "07:00 PM",
    value: 1140,
  },
  {
    label: "07:15 PM",
    value: 1155,
  },
  {
    label: "07:30 PM",
    value: 1170,
  },
  {
    label: "07:45 PM",
    value: 1185,
  },
  {
    label: "08:00 PM",
    value: 1200,
  },
  {
    label: "08:15 PM",
    value: 1215,
  },
  {
    label: "08:30 PM",
    value: 1230,
  },
  {
    label: "08:45 PM",
    value: 1245,
  },
  {
    label: "09:00 PM",
    value: 1260,
  },
  {
    label: "09:15 PM",
    value: 1275,
  },
  {
    label: "09:30 PM",
    value: 1290,
  },
  {
    label: "09:45 PM",
    value: 1305,
  },
  {
    label: "10:00 PM",
    value: 1320,
  },
  {
    label: "10:15 PM",
    value: 1335,
  },
  {
    label: "10:30 PM",
    value: 1350,
  },
  {
    label: "10:45 PM",
    value: 1365,
  },
  {
    label: "11:00 PM",
    value: 1380,
  },
  {
    label: "11:15 PM",
    value: 1395,
  },
  {
    label: "11:30 PM",
    value: 1410,
  },
  {
    label: "11:45 PM",
    value: 1425,
  },
  {
    label: "12:00 PM",
    value: 1440,
  },
];

export const DURATION_OPTIONS = [
  {
    value: 30,
    label: "30 Minutes",
  },
  {
    value: 45,
    label: "45 Minutes",
  },
  {
    value: 60,
    label: "60 Minutes",
  },
  {
    value: 90,
    label: "90 Minutes",
  },
];

export const AVAILABLE_PLATFORMS = {
  OUTLOOK: "outlook_calendar",
  GOOGLE: "google_calendar",
  ZOOM: "zoom",
};

export const SET_CALENDER_OPTIONS = {
  GOOGLE: "google",
  OUTLOOK: "outlook",
};

export const WEBINARS_TABS = {
  LIVE: "ongoing",
  ONDEMAND: "ondemand",
  REGISTERED: "registered",
};
export const JOB_SEARCH_TYPE = [
  {
    label: "Exact match",
    value: "exact",
    tooltip:
      "Exact search will search for those exact keywords in the job title and employer name",
  },
  {
    label: "Approximate match",
    value: "approx",
    tooltip:
      "Approximate search will look for any word in your search parameter within the job title and employer name",
  },
];

export const presetFilters = [
  {
    value: "top_employers_experience_level",
    label: "Top entry-level employers",
  },
  { value: "top_employers_new_york_city", label: "Top NYC employers" },
  {
    value: "top_employers_california",
    label: "Top CA employers",
  },
  {
    value: "top_employers_accounting",
    label: "Top Accounting employers",
  },
];

export const experienceOptions = [
  { value: "entry", label: "Entry" },
  { value: "qualified", label: "Qualified" },
  { value: "experienced", label: "Experienced" },
  { value: "fully_competent", label: "Fully Competent" },
];

export const accountTypeOptions = [
  {
    label: "Student",
    value: "Student",
  },
  {
    label: "Alumni",
    value: "Alumni",
  },
  {
    label: "Staff & Faculty",
    value: "staff",
  },
];

export const COURSES_LIST = [
  {
    id: "orientation",
    name: "Job search strategy",
    url: "https://learn.interstride.com/orientation",
    permission: "orientation_course",
  },
  {
    id: "work_authorization",
    name: "US work authorizations",
    url: "https://learn.interstride.com/immigration",
    permission: "work_authorization_course",
  },
  {
    id: "h1b",
    name: "Ultimate H-1B visa guide",
    url: "https://learn.interstride.com/h1b",
    permission: "h1b_course",
  },
  {
    id: "sponsorship_response_strategy",
    name: "Navigating sponsorship questions",
    url: "https://learn.interstride.com/sponsorship_response_strategy",
    permission: "sponsorship_response_strategy",
  },
];

export const OPT_CALCULATOR_STATUS_LIST = [
  {
    title: "OPT eligibility",
    key: "full_time_cpt_used",
    isCompleted: false,
    fullTitle: "OPT eligibility",
    subTitle:
      "Post-completion OPT is not available to those who have 365 days of CPT",
  },
  {
    title: "Available post-completion OPT",
    key: "available_post_completion_opt",
    isCompleted: false,
    fullTitle: "Calculate available post-completion OPT",
    subTitle: null,
  },
  {
    title: "Deadlines on post-completion OPT",
    key: "deadlines_post_completion_opt",
    isCompleted: false,
    fullTitle:
      "Determine general time limits and deadlines on post-completion OPT application based on program end date",
    subTitle: null,
  },
  {
    title: "Form I-765 filing deadline",
    key: "form_i765_filing_deadline",
    isCompleted: false,
    fullTitle: "",
    subTitle:
      "Complete this step after the DSO has recommended OPT in SEVIS. Form I-765 must be received by USCIS within 30 days from the date the DSO entered the OPT request into SEVIS. USCIS will deny it if it is received later.",
  },
  {
    title: "Preferred OPT start date",
    key: "preferred_opt_start_date",
    isCompleted: false,
    fullTitle: "Choose OPT start date",
    subTitle:
      "Enter the preferred start and end dates for post-completion OPT. Note that when the preferred OPT start date is entered, the latest available OPT end date displays.",
  },
];

const CPT_THRESHOLD = 365;

export const OPT_DAYS_PER_LEVEL = 364;

export const OPT_CALCULATOR_RIGHT_SIDEBAR_SCHEMA = [
  [
    {
      title: "Total days of full-time CPT used",
      valueType: "number",
      value: 0,
      threshold: CPT_THRESHOLD,
      warningMessage:
        "Unfortunately you are not eligible for OPT since your total exceeds 365 days.",
      successMessage:
        "Congratulations, you are eligible for post-completion OPT!",
    },
  ],
  [
    {
      title: "Days of OPT available per level",
      valueType: "number",
      value: OPT_DAYS_PER_LEVEL,
    },
    {
      title: "Full-time equivalent",
      valueType: "number",
      value: 0,
    },
    {
      title: "Available OPT",
      valueType: "number",
      value: 0,
    },
  ],
  [
    {
      title: "The last date the DSO can recommend OPT in SEVIS",
      valueType: "date",
      value: null,
    },
    {
      title: "The earliest date the Form I-765 can be filed with USCIS",
      valueType: "date",
      value: null,
    },
    {
      title: "Form I-765 must be filed with USCIS by",
      valueType: "date",
      value: null,
    },
    {
      title: "Post-completion OPT must begin between",
      valueType: "dateRange",
      value: null,
    },
  ],
  [
    {
      title: "USCIS must receive Form I-765 by",
      valueType: "date",
      value: null,
    },
  ],
];

export const optPeriodPlaceholder = {
  startDate: null,
  endDate: null,
};

export const optCalculatorInitialArgs = {
  full_time_cpt_used: {
    isAuthorized: false,
    value: [clone(optPeriodPlaceholder)],
    isCompleted: false,
  },
  available_post_completion_opt: {
    isAuthorized: false,
    value: [clone({ ...optPeriodPlaceholder, isPartTime: false })],
    isCompleted: false,
  },
  deadlines_post_completion_opt: {
    value: null,
    isCompleted: false,
  },
  preferred_opt_start_date: {
    value: {
      ...clone(optPeriodPlaceholder),
    },
    isCompleted: false,
  },
  form_i765_filing_deadline: {
    value: null,
    isCompleted: false,
  },
};

// Data format: {label: major_name, value: cip_code}
export const JOBS_MAJORS_OPTIONS = [
  {
    label: "Agriculture",
    value: 1,
  },
  {
    label: "Architecture",
    value: 4,
  },
  {
    label: "Biology",
    value: 26,
  },
  {
    label: "Business and Management",
    value: 52,
  },
  {
    label: "Communications and Journalism",
    value: 9,
  },
  {
    label: "Computer and Information Sciences",
    value: 11,
  },
  {
    label: "Education",
    value: 13,
  },
  {
    label: "Engineering",
    value: 14,
  },
  {
    label: "Foreign languages, literatures, and linguistics",
    value: 16,
  },
  {
    label: "Health Professions",
    value: 51,
  },
  {
    label: "History",
    value: 54,
  },
  {
    label: "Humanities",
    value: 24,
  },
  {
    label: "Legal Studies and Law Enforcement",
    value: 22,
  },
  {
    label: "Mathematics",
    value: 27,
  },
  {
    label: "Philosophy and religious studies",
    value: 38,
  },
  {
    label: "Physical Sciences",
    value: 40,
  },
  {
    label: "Psychology",
    value: 42,
  },
  {
    label: "Public Administration",
    value: 44,
  },
  {
    label: "Social Sciences",
    value: 45,
  },
  {
    label: "Visual and Performing Arts",
    value: 50,
  },
];

