import React, { useState } from "react";

import TaxPromoteSlideStyles from "./TaxPromoteSlideStyles";
import ButtonCustom from "../../../../ButtonCustom/ButtonCustom";
import CustomDialog from "../../../../CustomDialog/CustomDialog";

const TaxPromoteSlide = ({ promote = {}, takeAction = () => {} }) => {
  const [showPopup, setShowPopup] = useState(false);

  return (
    <TaxPromoteSlideStyles>
      <div className="tax-promote-slide">
        <div className="promote-content">
          <div className="title text-primary">{promote.title}</div>
          <div className="sub-title text-primary">{promote.subTitle}</div>
          <ButtonCustom
            width={265}
            onClick={() => setShowPopup(true)}
            keepLabelCase={true}
            className="bg-white"
          >
            {promote.buttonText}
          </ButtonCustom>
          <div className="links">
            <a
              href="https://interstride.com/blog/navigating-the-us-tax-maze/"
              target="_blank"
              rel="noreferrer"
            >
              Do I need to file taxes?
            </a>{" "}
            |{" "}
            <a
              href="https://interstride.com/tax/pricing/"
              target="_blank"
              rel="noreferrer"
            >
              Pricing
            </a>
          </div>
        </div>
      </div>

      {showPopup && (
        <CustomDialog
          open={showPopup}
          handleClose={() => setShowPopup(false)}
          isDisplayCloseButton={false}
        >
          <div className="model-body d-flex justify-content-center align-items-center flex-flow-column">
            <div className="mt-15 header-primary">
              You are now being directed to a third-party website. Any
              information you provide on the third-party site will be shared
              directly with the tax provider and will be subject to their terms
              of use and privacy policy.
            </div>
            <div className="btn mt-20 mb-20">
              <ButtonCustom
                width="200"
                height={50}
                onClick={(e) => {
                  setShowPopup(false);
                  takeAction(promote);
                }}
                aria-label="Cancel"
              >
                Get started
              </ButtonCustom>
            </div>
            <a
              href="/#"
              className="ngray-link"
              onClick={(e) => {
                e.preventDefault();
                setShowPopup(false);
              }}
            >
              Cancel
            </a>
          </div>
        </CustomDialog>
      )}
    </TaxPromoteSlideStyles>
  );
};

export default TaxPromoteSlide;
