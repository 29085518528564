import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .tax-promote-slide {
    .promote-content {
      .title {
        font-size: 25px;
        font-weight: 700;
        line-height: 24px;
        font-family: "TTCommons-DemiBold";
        color: #fff;
      }
      .sub-title {
        color: #fff;
        font-family: "TTCommons-Regular";
        font-size: 21px;
        font-weight: 400;
        line-height: 23px;
        text-align: center;
        max-width: 287px;
        margin: 15px 0 10px 5px !important;
      }
      button {
        color: #0030b2;
        background-color: #fff;
        box-shadow: none !important;
        &:hover {
          box-shadow: none !important;
        }
      }

      .links {
        margin: 15px 0px 0px 0px;
        font-size: 15px;
        line-height: 16px;
        font-family: "TTCommons-Medium";
        font-weight: 500;
        color: #fff;
        a {
          color: #fff;
          text-decoration: none;
          border-bottom: 2px solid #fff !important;
          cursor: pointer;
          transition: all 0.3s;
          &:hover {
            opacity: 0.9;
          }
        }
      }
    }
  }
`;
