import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Avatar } from "@mui/material";
import Slider from "react-slick";
import { useSelector } from "react-redux";
import {
  ShimmerText,
  ShimmerTitle,
  ShimmerButton,
} from "react-shimmer-effects";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import DashboardSliderStyles from "./DashboardSliderStyles";

import CardCustom from "../../CardCustom/CardCustom";
import ButtonCustom from "../../ButtonCustom/ButtonCustom";
import { routes } from "../../../routes";
import usePromise from "../../../hooks/usePromise/usePromise";
import { Mixpanel } from "../../../mixpanel";
import { getPromoteAPI } from "../../../services/promoteService";
import ChromeExtensionSlide from "./CustomSliders/ChromeExtensionSlide/ChromeExtensionSlide";
import MobileAppSlide from "./CustomSliders/MobileAppSlide/MobileAppSlide";
import OPTCalculculatorSlide from "./CustomSliders/OPTCalculculatorSlide/OPTCalculculatorSlide";
import TaxPromoteSlide from "./CustomSliders/TaxPromoteSlide/TaxPromoteSlide";
import { STUDENT_VIEW_PREFERENCES_OPTIONS } from "../../../utils/common";

import TaxPromoteBackgroundImg from "../../../assets/png/Tax_promote_background.png";

const DashboardSlider = ({ isResponsive = false }) => {
  const navigate = useNavigate();
  const accessibilityHelp = localStorage.getItem("accessibilityHelp");

  const { permissions = {}, users = {} } = useSelector(
    (store) => store.userStore
  );

  const [callPromoteAPI, refreshPromoteAPI] = usePromise(getPromoteAPI);
  const [promotions, setPromotions] = useState([]);
  const [nextSlide, setNextSlide] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);

  const takeAction = (item) => {
    if (item.isAdminPromote) {
      Mixpanel.track("Admin portal promote visited", {
        promote_id: item.id,
        promote_title: item.title,
        promote_link: item.buttonLink,
      });
    }

    if (item.newTab) window.open(item.buttonLink, "_blank");
    else navigate(item.buttonLink);
  };

  useEffect(() => {
    refreshPromoteAPI();

    if (accessibilityHelp === "true") {
      const nextButtons = document.getElementsByClassName("slick-next");
      const prevButtons = document.getElementsByClassName("slick-prev");
      if (nextButtons && nextButtons.length > 0) {
        nextButtons[0].setAttribute("aria-label", "Next Slide");
        if (nextButtons[1])
          nextButtons[1].setAttribute("aria-label", "Next Slide");
      }
      if (prevButtons && prevButtons.length > 0) {
        prevButtons[0].setAttribute("aria-label", "Previous Slide");
        if (prevButtons[1])
          prevButtons[1].setAttribute("aria-label", "Previous Slide");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      callPromoteAPI.hasFetched() &&
      callPromoteAPI.hasErrors() === false &&
      callPromoteAPI.data() &&
      callPromoteAPI._data.data &&
      callPromoteAPI._data.data.promotions
    ) {
      const { promotions = [] } = callPromoteAPI._data.data;

      const highPriorityPromotes = [];
      const adminPromotes = [];

      promotions.forEach((promote) => {
        let formattedPromote = {
          title: promote.title,
          topImage: promote.file_url,
          buttonText: promote.action_text,
          buttonLink: promote.link,
          subTitle: promote.description,
          className: "w-70",
          isAdminPromote: true,
          id: promote.id,
          newTab: true,
          permission:
            promote.student_audience_type ===
              STUDENT_VIEW_PREFERENCES_OPTIONS.BOTH ||
            (promote.student_audience_type ===
              STUDENT_VIEW_PREFERENCES_OPTIONS.INTERNATIONAL &&
              users?.student_view_preference ===
                STUDENT_VIEW_PREFERENCES_OPTIONS.INTERNATIONAL) ||
            (promote.student_audience_type ===
              STUDENT_VIEW_PREFERENCES_OPTIONS.DOMESTIC &&
              users?.student_view_preference ===
                STUDENT_VIEW_PREFERENCES_OPTIONS.DOMESTIC)
              ? true
              : false,
        };

        if (
          (promote.link ?? "").includes("https://interstride.legacytaxnr.com")
        ) {
          formattedPromote.isTaxPromote = true;
          formattedPromote.customUI = (
            <TaxPromoteSlide
              promote={formattedPromote}
              takeAction={takeAction}
            />
          );
          highPriorityPromotes.push(formattedPromote);
        } else adminPromotes.push(formattedPromote);
      });
      setPromotions([
        ...highPriorityPromotes,
        ...INTERSTRIDE_PRODUCTS_SLIDER,
        ...adminPromotes,
        ...HOME_SLIDER,
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callPromoteAPI.isFetching()]);

  let settings = {
    accessibility: true,
    dots: true,
    arrows: accessibilityHelp === "true" ? true : false,
    infinite:
      promotions.filter((promotion) => promotion.permission)?.length > 1
        ? true
        : false,
    speed: 100,
    pauseOnHover: true,
    autoplay: accessibilityHelp === "true" ? false : true,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: accessibilityHelp === "true" ? "accessibility-slider" : "",
    customPaging: function renderButtons(i) {
      return (
        <button
          aria-label={`slide ${i + 1}`}
          id={`${isResponsive ? "responsive-" : ""}btn-slide-${i}`}
          {...(i === currentSlide ? { "aria-current": true } : {})}
        >
          {i + 1}
        </button>
      );
    },
    beforeChange: function (_, next) {
      setNextSlide(next);
    },
    afterChange: function (i) {
      setCurrentSlide(i);
    },
  };

  // const LegalpadPromotes = [
  //   {
  //     isLegalpadPromote: true,
  //     image: "/images/Interstride-Banner-Option.png",
  //     buttonLink: "https://legalpad.io/partner/interstride/",
  //     promoteImageClassname: "us-work-visa__rounded",
  //   },
  // ];

  const INTERSTRIDE_PRODUCTS_SLIDER = [
    {
      customUI: <OPTCalculculatorSlide />,
      permission:
        permissions["opt_calculator"] &&
        users?.student_view_preference ===
          STUDENT_VIEW_PREFERENCES_OPTIONS.INTERNATIONAL,
    },
    {
      customUI: <ChromeExtensionSlide />,
      permission:
        users?.student_view_preference ===
        STUDENT_VIEW_PREFERENCES_OPTIONS.INTERNATIONAL,
    },
    {
      customUI: <MobileAppSlide />,
      permission:
        users?.student_view_preference ===
        STUDENT_VIEW_PREFERENCES_OPTIONS.INTERNATIONAL,
    },
  ];

  const HOME_SLIDER = [
    // ...(permissions["chat"]
    //   ? [
    //       {
    //         title: "Meet advisors, alumni mentors, and more.",
    //         subImage: [
    //           { name: "Mark Smith", imageSrc: STa3 },
    //           { name: "Mark Smith", imageSrc: STa2 },
    //           { name: "Mark Smith", imageSrc: STa1 },
    //           { name: "Mark Smith", imageSrc: STa4 },
    //         ],
    //         buttonText: "Network",
    //         buttonLink: routes.NETWORK.DASHBOARD,
    //         className: "",
    //       },
    //     ]
    //   : []),
    // ...(permissions["visa_insights"]
    //   ? [
    //       {
    //         title: "Looking for a job in the United States?",
    //         subTitle:
    //           "Get Visa Insights about companies based in the U.S. to understand what opportunities are available.",
    //         buttonText: "Get U.S. Visa Insights",
    //         buttonLink: routes.JOBS.VISA,
    //         className: "",
    //       },
    //     ]
    //   : []),
    // ...(permissions["topics"]
    //   ? [
    //       {
    //         title: "Stay up to date on Topics you care about.",
    //         subImage: [
    //           { name: "Mark Smith", imageSrc: STa5 },
    //           { name: "Mark Smith", imageSrc: STa6 },
    //           { name: "Mark Smith", imageSrc: STa7 },
    //           { name: "Mark Smith", imageSrc: STa8 },
    //         ],
    //         buttonText: "View Topics",
    //         buttonLink: routes.NETWORK.TOPICS,
    //         className: "",
    //       },
    //     ]
    //   : []),
    {
      title: "Discover orientation courses tailored for you.",
      subTitle:
        "Learn more about U.S. work authorization and job search startegies for international students.",
      buttonText: "View Courses",
      buttonLink: `${routes.LEARN.COURSES}?course=orientation`,
      className: "",
      permission:
        users?.student_view_preference ===
        STUDENT_VIEW_PREFERENCES_OPTIONS.INTERNATIONAL,
    },
    // {
    //   title: "Take our immigration course.",
    //   subTitle:
    //     "Get an in-depth understanding of all the work authorization options.",
    //   buttonText: "View Courses",
    //   buttonLink: routes.LEARN.COURSES,
    //   className: "",
    // },
  ];

  return (
    <DashboardSliderStyles
      // Display tax background image
      backgroundImage={
        promotions?.[currentSlide]?.isTaxPromote
          ? TaxPromoteBackgroundImg
          : false
      }
    >
      {callPromoteAPI.isFetching() ? (
        <div className="promote-previews-loading-container">
          <ShimmerTitle />
          <ShimmerText />
          <ShimmerButton size={"lg"} />
        </div>
      ) : promotions.filter((promotion) => promotion.permission)?.length > 0 ? (
        <React.Fragment>
          <Slider {...settings}>
            {promotions
              .filter((promotion) => promotion.permission)
              .map((item, i) => (
                <CardCustom
                  className={`slider-card ${
                    accessibilityHelp === "true"
                      ? "accessibility-slider-card"
                      : ""
                  }`}
                  key={i}
                >
                  {item.customUI ? (
                    item.customUI
                  ) : (
                    <>
                      {item.topImage && (
                        <div className={`top-image ${item.className}`}>
                          <img src={item.topImage} alt="Illustration" />
                        </div>
                      )}
                      {item.title && (
                        <div
                          className={`title text-primary ${item.titleClass}`}
                        >
                          {item.title}
                        </div>
                      )}
                      {item.subTitle && (
                        <div className="sub-title text-primary">
                          {item.subTitle}
                        </div>
                      )}
                      {item.subTitle2 && (
                        <div className="title title-visto text-primary">
                          {item.subTitle2}
                        </div>
                      )}
                      {item.subImage && (
                        <div className={`avatar-list ${item.className}`}>
                          <ul>
                            {item.subImage.map((item, i) => (
                              <li key={i}>
                                <Avatar src={item.imageSrc} alt="Subimage" />
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                      {item.isMobSquadPromote || item.isLegalpadPromote ? (
                        <div
                          className={`full-image ${
                            item.promoteImageClassname ?? ""
                          }`}
                        >
                          <a
                            href={item.buttonLink}
                            rel="noopener noreferrer"
                            target="_blank"
                            aria-label="promote ads"
                            onClick={(e) => {
                              e.preventDefault();
                              Mixpanel.track("Custom Ads Clicked", {
                                promote_title: item.isLegalpadPromote
                                  ? "Legalpad"
                                  : "Mobsquad",
                                page_source: "Dashboard -> Promotes",
                                promote_link: item.buttonLink,
                              });
                              window.open(item.buttonLink);
                            }}
                          >
                            <img
                              src={item.image}
                              alt={
                                item.isMobSquadPromote
                                  ? "Mobsquad Ad"
                                  : "Legalpad Ad"
                              }
                            />
                          </a>
                        </div>
                      ) : (
                        <ButtonCustom
                          width={265}
                          onClick={() => takeAction(item)}
                          keepLabelCase={true}
                          isDisabled={nextSlide !== i}
                          className="bg-blue"
                        >
                          {item.buttonText}
                        </ButtonCustom>
                      )}
                    </>
                  )}
                </CardCustom>
              ))}
          </Slider>
        </React.Fragment>
      ) : (
        <></>
      )}
    </DashboardSliderStyles>
  );
};

export default DashboardSlider;
