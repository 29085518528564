import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import ButtonCustom from "../../ButtonCustom/ButtonCustom";
import TextboxCustom from "../../Textbox/TextboxCustom";
import CustomDropzone from "../../CustomDropzone/CustomDropzone";
import { useAppState } from "../../../context";
import CropperPic from "../../Cropper/index";
import CustomSwitch from "../../CustomSwitch/CustomSwitch";
import { toastify, capitalizeFirstLetter } from "../../../helper/helper";
import { routes } from "../../../routes";

const Step1 = ({ step, getNextStep }) => {
  const navigate = useNavigate();
  const accessibilityHelp = localStorage.getItem("accessibilityHelp");
  const [cropperImage, setCropperImage] = useState();
  const [isNameError, setNameError] = useState(false);
  const [isDescriptionError, setDescriptionError] = useState(false);

  const {
    setNewTopicDetail,
    newTopicDetail: {
      topicName,
      description,
      file,
      topicProfile,
      topicType,
      selectedTagsForTopic,
    },
  } = useAppState("topic");

  const handleCallback = (file) => {
    file.forEach((item) => {
      if (!["image/png", "image/jpg", "image/jpeg"].includes(item.type)) {
        toastify("error", "Please select a valid image. (png, jpg, or jpeg)");
      } else if (parseInt(item.size / 1000) > 5000) {
        toastify("error", "Please select a file < 5MB.");
      } else {
        setNewTopicDetail({
          selectedTagsForTopic,
          topicName,
          description,
          topicType,
          file: item,
          topicProfile: URL.createObjectURL(item),
        });
        if (!(accessibilityHelp === "true"))
          setCropperImage(URL.createObjectURL(item));
      }
    });
  };

  const handleNext = () => {
    getNextStep(step + 1);
  };

  const getCropData = (file, url) => {
    setNewTopicDetail({
      selectedTagsForTopic,
      topicName,
      description,
      topicType,
      file: file,
      topicProfile: url,
    });
    setCropperImage(null);
  };

  const removeIconFn = () => {
    setNewTopicDetail({
      selectedTagsForTopic,
      topicName,
      description,
      topicType,
      topicProfile: "",
      file: "",
    });
  };

  const setError = (value, field) => {
    if (field === "topicName") {
      value.trim().length < 1 ? setNameError(true) : setNameError(false);
    }
    if (field === "description") {
      value.trim().length < 1
        ? setDescriptionError(true)
        : setDescriptionError(false);
    }
  };

  return (
    <div className="topic-setting-content-wrapper">
      <h2 className="title-primary mb-15 text-center">
        Your Community settings
      </h2>
      <div className="step1 group-profile card">
        {cropperImage && !(accessibilityHelp === "true") ? (
          <>
            <CropperPic image={cropperImage} getCropData={getCropData} />
            <br />
          </>
        ) : (
          <>
            <div className="image-upload">
              <label id="dropzone-label">
                Upload a Community profile picture
              </label>
              <CustomDropzone
                text="Upload picture"
                handleUploadFile={handleCallback}
                showPreview={true}
                preview={topicProfile}
                removeUploadFile={removeIconFn}
                remove={topicProfile ? true : false}
                removeText={"Remove Community icon"}
              />
            </div>
            <TextboxCustom
              name="topicName"
              placeholder="Give the Community a name"
              label="Community name"
              value={topicName}
              className="topic-name custom-textbox"
              onChange={(e) => {
                setNewTopicDetail({
                  selectedTagsForTopic,
                  file,
                  topicProfile,
                  topicName: capitalizeFirstLetter(e.target.value),
                  description,
                  topicType,
                });
                setError(capitalizeFirstLetter(e.target.value), "topicName");
              }}
              error={isNameError && topicName.trim().length < 1}
              helperText={
                isNameError &&
                topicName.trim().length < 1 &&
                "Community name should not empty"
              }
              maxLength={150} //As per Visa's confirmation
            />
            <TextboxCustom
              isMultiline={true}
              rows={3}
              name="description"
              className="description custom-textarea"
              placeholder="Give a short description for your Community."
              label="Community description"
              value={description}
              onChange={(e) => {
                setNewTopicDetail({
                  selectedTagsForTopic,
                  file,
                  topicProfile,
                  topicName,
                  description: capitalizeFirstLetter(e.target.value),
                  topicType,
                });
                setError(capitalizeFirstLetter(e.target.value), "description");
              }}
              error={isDescriptionError && description.trim().length < 1}
              helperText={
                isDescriptionError &&
                description.trim().length < 1 &&
                "Description should not empty"
              }
            />

            <CustomSwitch
              label="Community open to network"
              handleChange={() =>
                setNewTopicDetail({
                  selectedTagsForTopic,
                  file,
                  topicProfile,
                  topicName,
                  description,
                  topicType: !topicType,
                })
              }
              showLabel={true}
              checked={topicType}
            />
            <div className="btns-wrraper">
              <ButtonCustom
                isDisabled={
                  topicName === "" || description === "" || file === ""
                }
                onClick={handleNext}
                width="200"
              >
                Next
              </ButtonCustom>
              <br />
              <a
                href="/#"
                onClick={(e) => {
                  e.preventDefault();
                  navigate(routes.NETWORK.TOPICS);
                }}
                className="ngray-link"
              >
                Cancel
              </a>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default Step1;
