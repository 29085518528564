import React, { useEffect, useState } from "react";
import { Tooltip } from "@mui/material";
import { useSelector } from "react-redux";

import { CircularAvatar } from "../../../atoms/CircularAvatar/CircularAvatar";
import { createModuleStyleExtractor } from "../../../../utils/css";
import styles from "./SingleMessageItemV2.module.scss";
import { deliveryTime } from "../../../../helper/helper";
import { useAppState } from "../../../../context";
import { QUICK_REACTIONS_CHAT } from "../../../../utils/common";

import redCheckedIcon from "../../../../assets/svg/red-check-mark.svg";
import emojiPlusIcon from "../../../../assets/svg/emoji-plus.svg";
import emojiPlusExpandIcon from "../../../../assets/svg/emoji-expand.svg";
import chatReplyIcon from "../../../../assets/svg/chat-reply.svg";

const cx = createModuleStyleExtractor(styles);

export const SingleMessageItemV2 = ({
  item = {},
  isRecent = false,
  conversation = {},
  reactionsEmojiContainer = {},
  setReactionsEmojiContainer = () => {},
  handleReaction = () => {},
}) => {
  const { currentSearchMessageItem, updateMessageReplyData } =
    useAppState("chat");
  const { users = {} } = useSelector((store) => store.userStore);
  const [menuState, setMenuState] = useState({ message_id: null, open: false });

  const {
    message,
    sender_image,
    sender_name,
    send_at,
    shared_file,
    file_name,
    id,
    sender_id,
    conversation_type,
    reactions,
    parent_message = null,
  } = item;

  const { id: userId, admission_user_type } = users || {};
  const isOwnMessageAmbassador = userId === sender_id && admission_user_type;
  const isOtherMessageAmbassador =
    conversation?.user_info?.user_type?.includes("Ambassador");
  const isOwnMessage = sender_id === userId;

  const computeTooltipText = (usersList = [], maxUsersToDisplay = 10) => {
    let text = usersList
      ?.slice(0, maxUsersToDisplay)
      ?.map((user) => user?.name)
      .join(", ");
    if (usersList.length > maxUsersToDisplay) {
      text += `, and ${usersList.length - maxUsersToDisplay} ${
        usersList - maxUsersToDisplay > 1 ? "others" : "more"
      }`;
    }
    text += " reacted";
    return text;
  };

  const processEmojiExpandClick = () => {
    setReactionsEmojiContainer((current) => ({
      open: !current.open,
      messageId: current?.id ? null : id,
    }));
    setMenuState({ message_id: null, open: false });
  };

  const processReplyChatClick = () => {
    updateMessageReplyData(id, sender_id, sender_name, message, file_name);
    setMenuState({ message_id: null, open: false });
  };

  const processMenuEmojiClick = (reaction = {}) => {
    handleReaction(reaction, id);
    setMenuState({ message_id: null, open: false });
  };

  const processParentMessageClick = (parent_message_id = null) => {
    const element = document.getElementById(parent_message_id);
    element?.scrollIntoView && element.scrollIntoView({ behavior: "smooth" });
  };

  // Scroll into view the message upon search
  useEffect(() => {
    if (currentSearchMessageItem?.id === id) {
      const messageElement = document.getElementById(id);
      messageElement.scrollIntoView({ block: "end", behavior: "smooth" });
    }
  }, [currentSearchMessageItem, id]);

  // Use case handle
  useEffect(() => {
    if (
      reactions?.reactions_data?.length === 1 &&
      reactionsEmojiContainer.open
    ) {
      setReactionsEmojiContainer({
        open: false,
        messageId: null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reactions?.reactions_data?.length]);

  const MenuUi = () => (
    <div
      className={cx([
        "menu-ui",
        isOwnMessage ? "menu-ui__right" : "menu-ui__left",
      ])}
    >
      {QUICK_REACTIONS_CHAT?.map((reaction, index) => (
        <div
          key={index}
          className={cx("menu-ui__item")}
          onClick={() => processMenuEmojiClick(reaction)}
        >
          <p className={cx("menu-ui__item__emoji")}>{reaction.emoji}</p>
        </div>
      ))}
      <div className={cx("menu-ui__item")} onClick={processEmojiExpandClick}>
        <img
          className={cx("menu-ui__item__icon-emoji-expand")}
          src={emojiPlusExpandIcon}
          alt="emoji-plus"
        />
      </div>
      <div className={cx("menu-ui__item")} onClick={processReplyChatClick}>
        <img
          className={cx("menu-ui__item__icon-chat-reply")}
          src={chatReplyIcon}
          alt="chat-reply"
        />
      </div>
    </div>
  );

  return (
    <div className={cx("message")} id={id}>
      {isOwnMessage ? ( // Own Message
        <div
          className={cx("message__own")}
          style={{ marginTop: isRecent ? "-20px" : "0px" }}
        >
          <div className={cx("message__own__content")}>
            {!isRecent && (
              <div className={cx("message__own__content__title")}>
                <span className={cx("message__own__content__title__you")}>
                  You
                </span>
                {isOwnMessageAmbassador && (
                  <div className={cx("message__own__content__title__amb-tag")}>
                    <img src={redCheckedIcon} alt="red-check" />
                    <span>Ambassador</span>
                  </div>
                )}
                <span className={cx("message__own__content__title__delivery")}>
                  at {deliveryTime(send_at)}
                </span>
              </div>
            )}
            <div
              className={cx([
                "message__own__content__item",
                conversation_type === "system_generated"
                  ? "message__own__content__item__system"
                  : "",
              ])}
              onMouseEnter={() =>
                conversation_type !== "system_generated" &&
                setMenuState({ message_id: id, open: true })
              }
              onMouseLeave={() =>
                conversation_type !== "system_generated" &&
                setMenuState({ message_id: null, open: false })
              }
            >
              {parent_message?.id && (
                <div
                  className={cx("message__own__content__item__parent-message")}
                  onClick={() => processParentMessageClick(parent_message?.id)}
                >
                  <p
                    className={cx(
                      "message__own__content__item__parent-message__header"
                    )}
                  >
                    {userId === parent_message?.sender_id
                      ? "You"
                      : parent_message?.sender_name}
                  </p>
                  <p
                    className={cx(
                      "message__own__content__item__parent-message__body"
                    )}
                  >
                    {parent_message?.message}
                  </p>
                </div>
              )}
              <span className={cx("message__own__content__item__message")}>
                {message}
              </span>
              {message === "" && shared_file && file_name && (
                <em
                  className={cx(
                    "message__own__content__item__no-message-attachment"
                  )}
                >
                  New attachment
                </em>
              )}
              {shared_file && (
                <a
                  className={cx("message__own__content__item__attachment")}
                  href={shared_file}
                  target={"_blank"}
                  rel="noopener noreferrer"
                >
                  <span>{file_name || "Attachment"}</span>
                </a>
              )}
              {menuState.open && menuState.message_id === id && <MenuUi />}
            </div>
          </div>
          <div className={cx("message__own__info")}>
            <div
              className={cx("message__own__info__image")}
              style={{ visibility: isRecent ? "hidden" : "visible" }}
            >
              <CircularAvatar
                src={sender_image}
                round
                size={31}
                name={sender_name}
              />
            </div>
          </div>
        </div>
      ) : (
        // other message
        <div
          className={cx("message__other")}
          style={{ marginTop: isRecent ? "-20px" : "0px" }}
        >
          <div className={cx("message__other__info")}>
            <div
              className={cx("message__other__info__image")}
              style={{ visibility: isRecent ? "hidden" : "visible" }}
            >
              <CircularAvatar
                src={sender_image}
                round
                size={31}
                name={sender_name}
              />
            </div>
          </div>
          <div className={cx("message__other__content")}>
            {!isRecent && (
              <div className={cx("message__other__content__title")}>
                <span className={cx("message__other__content__title__name")}>
                  {sender_name?.split(" ")[0]}
                </span>
                {isOtherMessageAmbassador && (
                  <div
                    className={cx("message__other__content__title__amb-tag")}
                  >
                    <img src={redCheckedIcon} alt="red-check" />
                    <span>Ambassador</span>
                  </div>
                )}
                <span
                  className={cx("message__other__content__title__delivery")}
                >
                  at {deliveryTime(send_at)}
                </span>
              </div>
            )}
            <div
              className={cx([
                "message__other__content__item",
                conversation_type === "system_generated"
                  ? "message__other__content__item__system"
                  : "",
              ])}
              onMouseEnter={() =>
                conversation_type !== "system_generated" &&
                setMenuState({ message_id: id, open: true })
              }
              onMouseLeave={() =>
                conversation_type !== "system_generated" &&
                setMenuState({ message_id: null, open: false })
              }
            >
              {parent_message?.id && (
                <div
                  className={cx(
                    "message__other__content__item__parent-message"
                  )}
                  onClick={() => processParentMessageClick(parent_message?.id)}
                >
                  <p
                    className={cx(
                      "message__other__content__item__parent-message__header"
                    )}
                  >
                    {userId === parent_message?.sender_id
                      ? "You"
                      : parent_message?.sender_name}
                  </p>
                  <p
                    className={cx(
                      "message__other__content__item__parent-message__body"
                    )}
                  >
                    {parent_message?.message}
                  </p>
                </div>
              )}
              <span className={cx("message__other__content__item__message")}>
                {message}
              </span>
              {message === "" && shared_file && file_name && (
                <em
                  className={cx(
                    "message__other__content__item__no-message-attachment"
                  )}
                >
                  New attachment
                </em>
              )}
              {shared_file && (
                <a
                  className={cx("message__other__content__item__attachment")}
                  href={shared_file}
                  target={"_blank"}
                  rel="noopener noreferrer"
                >
                  <span>{file_name || "Attachment"}</span>
                </a>
              )}
              {menuState.open && menuState.message_id === id && <MenuUi />}
            </div>
          </div>
        </div>
      )}
      <div className={cx(["reaction", isOwnMessage ? "reaction--own" : ""])}>
        {reactions?.reactions_data?.length > 0 && (
          <img
            className={cx("reaction__icon")}
            src={emojiPlusIcon}
            alt="emoji-plus"
            onClick={processEmojiExpandClick}
          />
        )}
        {reactions?.reactions_data?.map((item, index) => (
          <div className={cx("reaction__item")}>
            <Tooltip
              key={index}
              placement="top"
              title={
                <div className={cx("reaction__item__tooltip")}>
                  <p className={cx("reaction__item__tooltip__emoji")}>
                    {item?.emoji}
                  </p>
                  <p className={cx("reaction__item__tooltip__text")}>
                    {computeTooltipText(item?.reacted_users)}
                  </p>
                </div>
              }
            >
              <div
                className={cx([
                  "reaction__item__emoji",
                  item.reacted_users?.map((user) => user.id)?.includes(userId)
                    ? "reaction__item__emoji--own"
                    : "",
                ])}
                onClick={() => handleReaction(item, id)}
              >
                <span>
                  {item?.emoji} {item?.count}
                </span>
              </div>
            </Tooltip>
          </div>
        ))}
      </div>
    </div>
  );
};
