import React, { useMemo, useState, useEffect } from "react";
import { get, orderBy } from "lodash";

import CardCustom from "../../../CardCustom/CardCustom";
import DiscoverFilterStyles from "./DiscoverFilterStyles";
import DropdownCustom from "../../../DropdownCustom/DropdownCustom";
import { optionsGenerator } from "../../../../helper/helper";
import AccordionView from "../../../AccordionView/AccordionView";
import CheckboxJob from "../../../atoms/CheckboxJob/CheckboxJob";
import TextboxCustom from "../../../Textbox/TextboxCustom";

const DiscoverFilter = ({
  netwokDiscoverOptions = {},
  currentFilters = {},
  setCurrentFilters = () => {},
}) => {
  const {
    country,
    city,
    school,
    degree,
    account_type,
    major,
    graduation_year,
  } = currentFilters;

  const [cityValue, setCityValue] = useState(city);

  useEffect(() => {
    // Reset cityValue on clear all
    if (!city || city === "") setCityValue("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [city]);

  const processCheckboxFilter = (value = null, filter = null) => {
    let _filterValues = [...(currentFilters[filter] || [])];
    if (_filterValues?.includes(value)) {
      _filterValues = _filterValues.filter((item) => item !== value);
    } else {
      _filterValues.push(value);
    }
    setCurrentFilters({
      ...currentFilters,
      [filter]: _filterValues,
    });
  };

  const countryOptions = useMemo(() => {
    return optionsGenerator(get(netwokDiscoverOptions, "countries", []));
  }, [netwokDiscoverOptions]);

  const selectedCountryOptions = useMemo(() => {
    return optionsGenerator(country);
  }, [country]);

  const majorOptions = useMemo(() => {
    return orderBy(
      optionsGenerator(get(netwokDiscoverOptions, "major", [])),
      "label",
      "asc"
    );
  }, [netwokDiscoverOptions]);

  const selectedMajorOptions = useMemo(() => {
    return optionsGenerator(major);
  }, [major]);

  const graduationYearOptions = useMemo(() => {
    return optionsGenerator(get(netwokDiscoverOptions, "gradutaion_year", []));
  }, [netwokDiscoverOptions]);

  const selectedGraduationOptions = useMemo(() => {
    return optionsGenerator(graduation_year);
  }, [graduation_year]);

  // Need to hide All option from FE for account type
  const accountTypeOptions = useMemo(() => {
    return get(netwokDiscoverOptions, "user_types", []).filter(
      (value) => value !== "All"
    );
  }, [netwokDiscoverOptions]);

  return (
    <DiscoverFilterStyles>
      <CardCustom className="search-card">
        <div className="form-field-group">
          <DropdownCustom
            isMulti
            name="country"
            label="Select country"
            placeholder="Choose countries"
            justifyContent="left"
            className={country.length !== 0 ? "active" : ""}
            value={selectedCountryOptions ?? []}
            options={countryOptions}
            onChange={(values) => {
              setCurrentFilters({
                ...currentFilters,
                country: (values ?? []).map((rec) => rec.value),
              });
            }}
          />
        </div>
        <div className="form-field-group">
          <TextboxCustom
            name="city"
            type="text"
            aria-labelledby="city-label"
            placeholder="Enter city"
            label="City"
            lableaignment="left"
            value={cityValue}
            onChange={(e) => {
              setCityValue(e.target.value);
              // Apply filter dynamically if applied city is removed
              if (e.target.value?.length === 0) {
                setCurrentFilters({ ...currentFilters, city: "" });
              }
            }}
            onEnterKey={() =>
              setCurrentFilters({ ...currentFilters, city: cityValue })
            }
          />
        </div>

        {netwokDiscoverOptions &&
          netwokDiscoverOptions.schools &&
          netwokDiscoverOptions.schools.length > 0 && (
            <div className="form-field-group checkbox-filter">
              <span className="checkbox-filter-title">Select department</span>
              <div className="checkbox-filter-list">
                {(netwokDiscoverOptions.schools ?? [])?.map(
                  (item = {}, index) => {
                    return (
                      <CheckboxJob
                        label={item.name}
                        key={index}
                        checked={school?.includes(item.id)}
                        onChange={() =>
                          processCheckboxFilter(item.id, "school")
                        }
                      />
                    );
                  }
                )}
              </div>
            </div>
          )}

        <div className="form-field-group checkbox-filter">
          <span className="checkbox-filter-title">Select account type</span>
          <div className="checkbox-filter-list">
            {accountTypeOptions.map((item = {}, index) => {
              return (
                <CheckboxJob
                  label={item}
                  key={index}
                  checked={account_type?.includes(item)}
                  onChange={() => processCheckboxFilter(item, "account_type")}
                />
              );
            })}
          </div>
        </div>

        <AccordionView label="degree" header="Select degree">
          <div className="form-field-group">
            <div className="checkbox-filter-list">
              {(netwokDiscoverOptions.degree ?? [])?.map((item = {}, index) => {
                return (
                  <CheckboxJob
                    label={item}
                    key={index}
                    checked={degree?.includes(item)}
                    onChange={() => processCheckboxFilter(item, "degree")}
                  />
                );
              })}
            </div>
          </div>
        </AccordionView>

        <AccordionView label="major" header="Select major">
          <div className="form-field-group">
            <DropdownCustom
              isMulti
              name="major"
              label=""
              placeholder="Choose majors"
              justifyContent="left"
              className={major.length !== 0 ? "active" : ""}
              value={selectedMajorOptions ?? []}
              options={majorOptions}
              onChange={(values) => {
                setCurrentFilters({
                  ...currentFilters,
                  major: (values ?? []).map((rec) => rec.value),
                });
              }}
            />
          </div>
        </AccordionView>

        <AccordionView label="graduation year" header="Select graduation year">
          <div className="form-field-group">
            <DropdownCustom
              isMulti
              name="graduation_year"
              label=""
              placeholder="Choose graduation years"
              justifyContent="left"
              className={graduation_year.length !== 0 ? "active" : ""}
              value={selectedGraduationOptions ?? []}
              options={graduationYearOptions}
              onChange={(values) => {
                setCurrentFilters({
                  ...currentFilters,
                  graduation_year: (values ?? []).map((rec) => rec.value),
                });
              }}
            />
          </div>
        </AccordionView>
      </CardCustom>
    </DiscoverFilterStyles>
  );
};

export default DiscoverFilter;
