import React, { useEffect, useState, useCallback } from "react";
import { Field, Form, Formik } from "formik";
import { isEmpty, isNull } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import TextboxCustom from "../../Textbox/TextboxCustom";
import DropdownCustom from "../../DropdownCustom/DropdownCustom";
import ButtonCustom from "../../ButtonCustom/ButtonCustom";
import CustomDialog from "../../CustomDialog/CustomDialog";
import CustomDropzone from "../../CustomDropzone/CustomDropzone";
import { PRIMARY_COLOR } from "../../../styles/variables";
import { updateUserProfileDetails } from "../../../services/authenticationServices";
import { applyJobAPI } from "../../../services/EmployerJobServices";
import usePromise from "../../../hooks/usePromise/usePromise";
import { Mixpanel } from "../../../mixpanel";
import {
  toastify,
  getYearList,
  capitalizeFirstLetter,
  downloadAttachment,
} from "../../../helper/helper";
import { DEGREE_OPTIONS } from "../../../constant/mockdata";
import { loginRequired } from "../../../hoc/loginRequired";
import { getMajorList } from "../../../services/educationServices";
import EditableDropdown from "../../../components/DropdownCustom/EditableDropdown";
import { validateName } from "../../../utils/common";
import { setSelectedJob } from "../../../redux/reducers/jobReducer";
import { updatePersistState } from "../../../redux/reducers/userReducer";

import linkedinIcon from "../../../assets/svg/f-linkedin-icon.svg";
import downloadIcon from "../../../assets/svg/downloadIcon.svg";
import DownloadFocusIcon from "../../../assets/FocusIcons/Common/Download.svg";
import copy from "../../../assets/svg/f-copy-icon.svg";

const ApplyJobDialog = ({
  open = false,
  setDialog = () => {},
  jobData,
  ...props
}) => {
  const dispatch = useDispatch();
  const { users = {} } = useSelector((store) => store.userStore);
  const { selectedJob } = useSelector((store) => store.jobStore);
  const graduationYearOptions = getYearList();
  const [showEmail, setShowEmail] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [callUpdateUserProfileDetails, refreshUpdateUserProfileDetails] =
    usePromise(updateUserProfileDetails);

  useEffect(() => {
    if (jobData.apply_through === "email") {
      setShowEmail(true);
    } else {
      setShowEmail(false);
    }
  }, [jobData]);

  useEffect(() => {
    if (callUpdateUserProfileDetails.hasFetched()) {
      if (
        callUpdateUserProfileDetails.hasErrors() === false &&
        callUpdateUserProfileDetails.data().message &&
        callUpdateUserProfileDetails.data().data
      ) {
        dispatch(updatePersistState(callUpdateUserProfileDetails.data().data));
        setApplicant({
          resume: "",
          cover: "",
        });
        setDialog();

        Mixpanel.track("Job applied successfully", {
          job_source: "ep_featured",
          job_title: selectedJob.title,
          job_employer_name: selectedJob.name,
          job_country: selectedJob.country_name,
          job_state: selectedJob.state_name,
          job_city: selectedJob.city_name,
          job_full_location: `${
            selectedJob.city_name !== null && selectedJob.city_name !== "null"
              ? `${selectedJob.city_name}${
                  selectedJob.state_name !== null &&
                  selectedJob.state_name !== "null"
                    ? ","
                    : ""
                }`
              : ""
          } ${
            selectedJob.state_name !== null && selectedJob.state_name !== "null"
              ? selectedJob.state_name
              : ""
          }`,
        });
      }
      if (
        callUpdateUserProfileDetails.hasErrors() === true &&
        callUpdateUserProfileDetails.data() &&
        callUpdateUserProfileDetails.data().message
      ) {
        toastify("error", callUpdateUserProfileDetails.data().message);
        setDialog();
      }
    }
  }, [callUpdateUserProfileDetails.isFetching()]);

  const handleCallback = useCallback((file) => {
    file.forEach((item) => {
      if (
        !(item.type === "application/pdf" || item.type === "application/msword")
      ) {
        toastify("error", "Please select a valid file format. (pdf or doc)");
      } else if (parseInt(item.size / 1000) > 5000) {
        toastify("error", "Please select a file < 5MB.");
      } else {
        setApplicant((prevState) => ({
          ...prevState,
          resume_name: item.name,
          resume: file,
        }));
      }
    });
  }, []);

  const resumeName = users?.resume_url
    ? users?.resume_url.substr(
        users?.resume_url.lastIndexOf("/") + 1,
        users?.resume_url.lastIndexOf("?") -
          users?.resume_url.lastIndexOf("/") -
          1
      )
    : "";

  const handleCallbackCover = useCallback((file) => {
    file.forEach((item) => {
      if (
        !(item.type === "application/pdf" || item.type === "application/msword")
      ) {
        toastify("error", "Please select a valid file format. (pdf or doc)");
      } else if (parseInt(item.size / 1000) > 5000) {
        toastify("error", "Please select a file < 5MB.");
      } else {
        setApplicant((prevState) => ({
          ...prevState,
          cover_name: item.name,
          cover: file,
        }));
      }
    });
  }, []);

  const [callingApplyJobAPI, refreshApplyJobAPI] = usePromise(applyJobAPI);
  const [applicant, setApplicant] = useState({
    resume: "",
    resume_name: "",
    cover: "",
    cover_name: "",
    first_name: users.first_name || "",
    last_name: users.last_name || "",
    major:
      users.educations && users.educations[0]
        ? users.educations[0].major
        : null,
    degree:
      users.educations && users.educations[0]
        ? users.educations[0].degree
        : null,
    graduation_year:
      users.educations && users.educations[0]
        ? users.educations[0].graduation_year
        : null,
    linkedin_public_url: users?.linkedin_public_url,
  });

  const saveApplyJob = () => {
    const formData = new FormData();
    formData.append("user_id", users.id);
    formData.append("employer_job_id", selectedJob.jobid);

    if (applicant.resume && applicant.resume.length > 0)
      formData.append("resume", applicant.resume[0]);
    else if (resumeName.length > 0)
      formData.append("remote_resume_url", users?.resume_url);

    if (applicant.cover && applicant.cover.length > 0)
      formData.append("cover_letter", applicant.cover[0]);
    refreshApplyJobAPI(formData);
  };

  useEffect(() => {
    if (
      callingApplyJobAPI.hasFetched() &&
      callingApplyJobAPI.hasErrors() === false
    ) {
      updateProfileDetails();

      toastify(
        "success",
        callingApplyJobAPI.data()
          ? callingApplyJobAPI.data().message
          : "Server error"
      );
    }
    if (
      callingApplyJobAPI.hasFetched() &&
      callingApplyJobAPI.hasErrors() === true
    ) {
      toastify(
        "error",
        callingApplyJobAPI.data()
          ? callingApplyJobAPI.data().message
          : callingApplyJobAPI._error.data.message
      );
      setDialog();
    }
  }, [callingApplyJobAPI.isFetching()]);

  const updateProfileDetails = () => {
    const formData = new FormData();

    formData.append("user[first_name]", applicant.first_name.trim());
    formData.append("user[last_name]", applicant.last_name.trim());
    formData.append("user[degree]", applicant.degree);
    formData.append("user[major]", applicant.major.trim());
    formData.append(
      "user[graduation_year]",
      applicant.graduation_year.toString()
    );
    if (
      applicant.linkedin_public_url &&
      applicant.linkedin_public_url.length > 0 &&
      !isNull(applicant.linkedin_public_url)
    )
      formData.append(
        "user[linkedin_public_url]",
        applicant.linkedin_public_url
      );
    else {
      formData.append("user[linkedin_public_url]", "");
    }

    if (applicant.resume && applicant.resume.length > 0)
      formData.append("user[resume]", applicant.resume[0]);

    if (applicant.cover && applicant.cover.length > 0)
      formData.append("user[cover_letter]", applicant.cover[0]);

    refreshUpdateUserProfileDetails(users.id, formData);
    dispatch(setSelectedJob(selectedJob));
  };

  const [, refreshGetMajorList] = usePromise(getMajorList);
  const [allSubjects, setAllSubjects] = useState([]);
  const getAllMajor = async () => {
    const allMajor = await refreshGetMajorList(users?.school_id);
    setAllSubjects(allMajor.data);
  };

  useEffect(() => {
    getAllMajor();
  }, []);

  const { formProps } = props;

  const validateFields = () => {
    let errors = {};
    if (
      !applicant.first_name ||
      applicant.first_name.trim() === "" ||
      isNull(applicant.first_name)
    ) {
      errors.first_name = "First name is required";
    } else if (!validateName(applicant.first_name)) {
      errors.first_name = "First name is not valid.";
    }
    if (
      !applicant.last_name ||
      applicant.last_name.trim() === "" ||
      isNull(applicant.last_name)
    ) {
      errors.last_name = "Last name is required";
    } else if (!validateName(applicant.last_name)) {
      errors.last_name = "Last name is not valid.";
    }

    if (!applicant.degree || isNull(applicant.degree)) {
      errors.degree = "Degree is required";
    }

    if (!applicant.major || isNull(applicant.major)) {
      errors.major = "Major is required";
    }

    if (!applicant.graduation_year || isNull(applicant.graduation_year)) {
      errors.graduation_year = "Graduation Year is required";
    }

    if (!applicant?.resume?.length && !users?.resume_url?.length) {
      errors.resume = "Resume is required";
    }

    if (
      applicant.linkedin_public_url &&
      applicant.linkedin_public_url.length > 0 &&
      //eslint-disable-next-line
      !/(ftp|http|https):\/\/?(?:www\.)?linkedin.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/.test(
        applicant.linkedin_public_url
      )
    ) {
      errors.linkedin_public_url = "LinkedIn URL is not valid";
    }
    setFormErrors(errors);
    return errors;
  };

  useEffect(() => {
    validateFields();
  }, [applicant, users]);

  return (
    <CustomDialog
      open={open}
      title="Apply"
      handleClose={() => setDialog()}
      isDisplayCloseButton={true}
      className="apply-job-popup"
      dialogTitleClass="modal-title"
      dialogSubTitleClass="text-para"
    >
      <div
        className={showEmail ? "email-apply-popup" : "apply-job-popup-content"}
      >
        {showEmail ? (
          <div>
            <div className="subTitle">
              Send your cover letter and resume to this email.
            </div>
            <div className="flex">
              <ButtonCustom
                height={50}
                width={280}
                color="primary"
                className="bg-light-blue"
              >
                {jobData.job_application_email}
              </ButtonCustom>
              <ButtonCustom
                height={50}
                width={94}
                className="bg-white ml-10"
                onClick={() => {
                  navigator.clipboard.writeText(jobData.job_application_email);
                  toastify("success", "Text copied successfully. ");
                }}
              >
                Copy
                <img className="copyicon" src={copy} />
              </ButtonCustom>
            </div>
          </div>
        ) : (
          <Formik
            initialValues={{
              first_name: "",
              last_name: "",
              major: "",
              degree: "",
              graduation_year: "",
            }}
            isInitialValid={false}
            validate={() => {
              validateFields();
            }}
            onSubmit={(values, actions) => {
              saveApplyJob();
            }}
          >
            {() => (
              <Form>
                <Field {...formProps} name="first_name" type="select">
                  {({ field, form: { touched, errors }, meta }) => (
                    <TextboxCustom
                      {...field}
                      label="First Name"
                      placeholder="First Name"
                      lableaignment="left"
                      textcolor={PRIMARY_COLOR}
                      value={applicant.first_name}
                      onChange={(e) =>
                        setApplicant({
                          ...applicant,
                          first_name: capitalizeFirstLetter(e.target.value),
                        })
                      }
                      required={true}
                      error={meta.touched && !isEmpty(errors["first_name"])}
                      errorText={meta.touched && meta.error}
                      maxLength={20}
                    />
                  )}
                </Field>
                <Field {...formProps} name="last_name" type="select">
                  {({ field, form: { touched, errors }, meta }) => (
                    <TextboxCustom
                      {...field}
                      label="Last Name"
                      name="last_name"
                      placeholder="Last Name"
                      lableaignment="left"
                      textcolor={PRIMARY_COLOR}
                      value={applicant.last_name}
                      onChange={(e) =>
                        setApplicant({
                          ...applicant,
                          last_name: e.target.value,
                        })
                      }
                      required={true}
                      error={meta.touched && !isEmpty(errors["last_name"])}
                      errorText={meta.touched && meta.error}
                      maxLength={20}
                    />
                  )}
                </Field>
                <Field {...formProps} name="degree">
                  {({
                    field,
                    form: { touched, errors, setFieldValue, setFieldTouched },
                    meta,
                  }) => (
                    <DropdownCustom
                      label="Degree"
                      name="degree"
                      // width={264}
                      options={DEGREE_OPTIONS}
                      justifyContent="left"
                      labelAlign="center"
                      value={
                        DEGREE_OPTIONS
                          ? DEGREE_OPTIONS.find(
                              (option) => option.value === applicant.degree
                            )
                          : ""
                      }
                      onBlur={(e) => setFieldTouched("degree", true)}
                      onChange={(e) => {
                        setApplicant({
                          ...applicant,
                          degree: e.value,
                        });
                        setFieldValue("degree", e.value);
                      }}
                      required={true}
                      error={errors.degree}
                      touched={touched.degree}
                      className="react-custom-dropdown-optionvalue-center"
                    />
                  )}
                </Field>
                <Field {...formProps} name="major" type="select">
                  {({
                    field,
                    form: { touched, errors, setFieldValue, setFieldTouched },
                    meta,
                  }) => (
                    <EditableDropdown
                      {...field}
                      placeholder="Major"
                      width="325px"
                      required={true}
                      error={meta.touched && !isEmpty(errors["major"])}
                      errorText={meta.touched && meta.error}
                      value={
                        allSubjects
                          ? allSubjects.find(
                              (option) => option.value === applicant.major
                            )
                            ? allSubjects.find(
                                (option) => option.value === applicant.major
                              )
                            : { label: applicant.major, value: applicant.major }
                          : ""
                      }
                      onBlur={(e) => setFieldTouched("degree", true)}
                      onChange={(e) => {
                        let value = capitalizeFirstLetter(
                          e === null ? "" : e.value
                        );

                        setApplicant({
                          ...applicant,
                          major: value,
                        });
                        setFieldValue("major", value);
                      }}
                      justifyContent="center"
                      marginBottom={0}
                      name="major"
                      label="Enter your major"
                      options={allSubjects?.map((sub) => {
                        return {
                          label: sub,
                          value: sub,
                        };
                      })}
                    />
                  )}
                </Field>
                <Field {...formProps} name="graduation_year">
                  {({
                    field,
                    form: { touched, errors, setFieldValue, setFieldTouched },
                    meta,
                  }) => (
                    <DropdownCustom
                      label="Graduation year"
                      placeholder="Select graduation year"
                      name="graduation_year"
                      // width={264}
                      defaultValue={parseInt(moment().format("YYYY"))}
                      options={graduationYearOptions}
                      justifyContent="left"
                      labelAlign="center"
                      value={
                        graduationYearOptions
                          ? graduationYearOptions.find(
                              (option) =>
                                option.value ===
                                parseInt(applicant.graduation_year)
                            )
                          : ""
                      }
                      onBlur={(e) => setFieldTouched("graduation_year", true)}
                      onChange={(e) => {
                        //updateUserDetails("graduation_year", e.value);
                        setApplicant({
                          ...applicant,
                          graduation_year: e.value,
                        });
                        setFieldValue("graduation_year", e.value);
                      }}
                      required={true}
                      error={errors.graduation_year}
                      touched={touched.graduation_year}
                      className="react-custom-dropdown-optionvalue-center"
                    />
                  )}
                </Field>
                <Field {...formProps} name="linkedin_public_url">
                  {({ field, form: { errors }, meta }) => (
                    <div className="textbox-with-icon job-upload-cv">
                      <img
                        className={`textbox-icon ${
                          applicant.linkedin_public_url &&
                          !errors["linkedin_public_url"]
                            ? "blur-icon"
                            : ""
                        }`}
                        src={linkedinIcon}
                        alt="linkedin"
                      />
                      <TextboxCustom
                        {...field}
                        name={"linkedin_public_url"}
                        label=""
                        placeholder="Link to LinkedIn"
                        lableaignment="left"
                        textcolor={PRIMARY_COLOR}
                        value={applicant.linkedin_public_url}
                        onChange={(e) =>
                          setApplicant({
                            ...applicant,
                            linkedin_public_url: e.target.value,
                          })
                        }
                        error={errors["linkedin_public_url"]}
                        errorText={meta.error}
                      />
                    </div>
                  )}
                </Field>

                {users?.resume_url &&
                users?.resume_url.length > 0 &&
                resumeName.length > 0 &&
                applicant.resume.length === 0 ? (
                  <>
                    <div className="upload-cv">
                      <label className="textbox-label text-primary">
                        Resume
                      </label>
                      <div className="d-flex align-items-center justify-content-center">
                        <a
                          onClick={(e) => {
                            e.preventDefault();
                            downloadAttachment(users?.resume_url);
                          }}
                          href="/#"
                          className="img-focus"
                          onFocus={() => {
                            let img = document.getElementById(`download-icon`);
                            if (img) img.src = DownloadFocusIcon;
                          }}
                          onBlur={() => {
                            let img = document.getElementById(`download-icon`);
                            if (img) img.src = downloadIcon;
                          }}
                        >
                          <img
                            src={downloadIcon}
                            alt=""
                            id="download-icon"
                            height={"18px"}
                            style={{
                              cursor: "pointer",
                              margin: "10px 15px 4px 0px",
                            }}
                          />
                        </a>
                        <p className="text-primary">{resumeName}</p>

                        <div
                          className="btn-edit text-secondary"
                          style={{
                            flex: "none",
                          }}
                        >
                          <CustomDropzone
                            text="Edit"
                            handleUploadFile={handleCallback}
                            vallidFiles=".doc,.pdf"
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <Field {...formProps} name="resume_name">
                    {({ form: { errors } }) => (
                      <div className="btn-edit ml-auto text-secondary fresh-upload-cv">
                        <label className="textbox-label text-primary">
                          Resume*
                        </label>
                        <p
                          className="textbox-label text-primary"
                          style={{ margin: "15px 10px 3px 10px" }}
                        >
                          {applicant.resume_name}
                        </p>

                        <CustomDropzone
                          text="Upload your CV"
                          handleUploadFile={handleCallback}
                          vallidFiles=".doc,.pdf"
                          width={"15px"}
                        />
                        <p className="error-text" id="email-helper-text">
                          {errors.resume}
                        </p>
                      </div>
                    )}
                  </Field>
                )}
                <div className="btn-edit ml-auto text-secondary job-upload-cv mt-25">
                  <label className="textbox-label text-primary">
                    Cover Letter
                  </label>
                  <p
                    className="textbox-label text-primary"
                    style={{ margin: "15px 10px 3px 10px" }}
                  >
                    {applicant.cover_name}
                  </p>
                  <CustomDropzone
                    text="Upload your cover letter"
                    handleUploadFile={handleCallbackCover}
                    vallidFiles=".doc,.pdf"
                    width={"15px"}
                  />
                </div>

                <div className="customized-button mt-30">
                  <ButtonCustom
                    type={"submit"}
                    isDisabled={Object.keys(formErrors).length > 0}
                  >
                    Submit
                  </ButtonCustom>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </CustomDialog>
  );
};

export default loginRequired(ApplyJobDialog);
