import styled from "styled-components";
import { PRIMARY_DARK_COLOR } from "../../styles/variables";

export default styled.div.attrs({ className: "" })`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  .Mui-disabled {
    cursor: not-allowed;
  }
  .textbox-label {
    display: flex;
    justify-content: ${(props) => props.lableaignment};
    padding: 5px 0 0 0;
    line-height: 14px;
    margin: 0 0 6px 4px;
    font-size: 15px;
    color: var(--field-label-color);
    font-family: "TTCommons-Regular";
  }
  margin-bottom: ${(props) => props.marginBottom}px;

  .MuiFormHelperText-root.Mui-error {
    color: var(--secondary-color);
    display: flex;
    justify-content: center;
    margin: 5px 8px 0px 8px;
    font-family: "TTCommons-Regular";
    font-size: 15px;
    line-height: 17px;
  }
  /*
  input[type="password"] {
    ::-webkit-input-placeholder {
      font-size: 18px;
      color: ${PRIMARY_DARK_COLOR};
      opacity: 1;
    }
    ::-moz-placeholder {
      font-size: 18px;
      color: ${PRIMARY_DARK_COLOR};
      opacity: 1;
    }
    :-ms-input-placeholder {
      font-size: 18px;
      color: ${PRIMARY_DARK_COLOR};
      opacity: 1;
    }
    :-moz-placeholder {
      font-size: 18px;
      color: ${PRIMARY_DARK_COLOR};
      opacity: 1;
    }
    font-size: 36px;
    opacity: 1;
    color: ${PRIMARY_DARK_COLOR};
  }
  */
`;
