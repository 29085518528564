import React, { useState, useEffect, useRef } from "react";
import debounce from "debounce-promise";
import moment from "moment";
import { Link } from "react-router-dom";
import {
  FormControl,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { isEmpty } from "lodash";

import VisaSearchFilterStyles from "./VisaSearchFilterStyles";
import usePromise from "../../../../hooks/usePromise/usePromise";
import { getYearList } from "../../../../helper/helper";
import CardCustom from "../../../CardCustom/CardCustom";
import DropdownCustom from "../../../DropdownCustom/DropdownCustom";
import JobExperienceModal from "../CompanyInfo/Common/JobExperienceModal";
import {
  getIndustryFilterForCompany,
  getCompanyFilterForCompany,
  getStateFilterForCompany,
  getCityFilterForCompany,
  getJobTitleFilterForCompany,
  getJobClassificationFilterForCompany,
} from "../../../../services/JobServices";
import AccordionView from "../../../AccordionView/AccordionView";
import CheckboxJob from "../../../atoms/CheckboxJob/CheckboxJob";
import { PRIMARY_DARK_COLOR } from "../../../../styles/variables";
import InfoIconTooltip from "../../../InfoIconTooltip/InfoIconTooltip";

import FilterIcon from "../../../../assets/svg/filter-Icon.svg";
import FilledFilterIcon from "../../../../assets/svg/filled-filter-Icon.svg";
import InfoIcon from "../../../../assets/svg/InfoIcon";

export const presetFilters = [
  {
    value: "top_employers_experience_level",
    label: "Top entry-level employers",
  },
  { value: "top_employers_new_york_city", label: "Top NYC employers" },
  {
    value: "top_employers_california",
    label: "Top CA employers",
  },
  {
    value: "top_employers_accounting",
    label: "Top Accounting employers",
  },
];

const visaOptions = [
  { value: "h1b", label: "H-1B" },
  { value: "greencard", label: "Green Card" },
];

const industryTypeOptions = [
  { value: "industry", label: "Industry" },
  { value: "company", label: "Company" },
];

const yearOptions = [
  { value: 2017, label: "2017" },
  { value: 2018, label: "2018" },
  { value: 2019, label: "2019" },
  { value: 2020, label: "2020" },
];

const h1bGreenCardYearOptions = [
  { value: 2024, label: "2024" },
  { value: 2023, label: "2023" },
];

export const experienceOptions = [
  { value: "entry", label: "Entry" },
  { value: "qualified", label: "Qualified" },
  { value: "experienced", label: "Experienced" },
  { value: "fully_competent", label: "Fully Competent" },
];

const functionOptions = [
  { value: "Accounting", label: "Accounting" },
  { value: "Consulting", label: "Consulting" },
  { value: "Corporate", label: "Corporate" },
  { value: "Finance", label: "Finance" },
  { value: "Manufacturing", label: "Manufacturing" },
  { value: "Marketing", label: "Marketing" },
  { value: "Operations/Logistics", label: "Operations/Logistics" },
  { value: "Other", label: "Other" },
  { value: "Real Estate", label: "Real Estate" },
  { value: "Rotational Program", label: "Rotational Program" },
  { value: "Technical", label: "Technical" },
];

const INITIAL_ASYNC_OPTIONS = {
  job_title: [],
  job_classification: [],
};

const VisaSearchFilter = ({
  preserveFilters = false,
  filter = {},
  setFilter = () => {},
  applyFilter = () => {},
  onClearAllClick = () => {},
  onPresetFilterClick = () => {},
}) => {
  const {
    job_classification,
    job_title,
    visa,
    year,
    industryType = "industry",
    industry,
    company,
    city,
    state,
    experience,
    graduationYear,
    functionFilter,
  } = filter;

  const [openFilter, toggleFilter] = useState(false);
  const [callingIndustryFilterAPI, refreshIndustryFilterAPI] = usePromise(
    getIndustryFilterForCompany
  );
  const [industryOptions, setIndustryOptions] = useState([]);
  const [showExperiencePopup, setExperiencePopup] = useState(false);
  const [companyCounter, setCompanyCounter] = useState(1);
  const [stateCounter, setStateCounter] = useState(1);
  const [cityCounter, setCityCounter] = useState(1);
  const [jobClassificationCounter, setJobClassificationCounter] = useState(1);
  const [jobTitleCounter, setJobTitleCounter] = useState(1);
  const [jobClassificationH1bCounter, setJobClassificationH1bCounter] =
    useState(1);
  const [asyncOptionsSelected, setAsyncOptionsSelected] = useState(
    INITIAL_ASYNC_OPTIONS
  );

  useEffect(() => {
    if (!preserveFilters)
      setFilter({
        year:
          visa !== null && (visa === "h1b" || visa === "greencard")
            ? 2024
            : 2020,
      });
    // To get initial filters based on year
    refreshYearOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isInitialIndustryMount = useRef(true);
  useEffect(() => {
    if (isInitialIndustryMount.current) {
      isInitialIndustryMount.current = false;
    } else {
      if (visa === "h1b" || visa === "greencard") {
        if (industryType !== null && industryType === "industry") {
          setFilter({
            company: null,
          });
          setIndustryOptions([]);
          refreshIndustryFilterAPI({ company_type: visa, year });
        } else {
          setFilter({
            industry: null,
          });
          refreshIndustryFilterAPI({ company_type: visa, year });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [industryType]);

  const refreshYearOptions = () => {
    if (visa === "h1b" || visa === "greencard") {
      if (industryType !== null && industryType === "industry") {
        setIndustryOptions([]);
        refreshIndustryFilterAPI({ company_type: visa, year });
      }
    }

    setCompanyCounter(companyCounter + 1);
    setCityCounter(cityCounter + 1);
    setJobClassificationH1bCounter(jobClassificationH1bCounter + 1);
    setJobClassificationCounter(jobClassificationCounter + 1);
    setJobTitleCounter(jobTitleCounter + 1);
  };

  const refreshVisaOptions = () => {
    if (visa === "h1b" || visa === "greencard") {
      if (industryType !== null && industryType === "industry") {
        setIndustryOptions([]);
        refreshIndustryFilterAPI({
          company_type: visa,
          year,
        });
      }
    }

    setCompanyCounter(companyCounter + 1);
    setStateCounter(stateCounter + 1);
    setCityCounter(cityCounter + 1);
    setJobClassificationH1bCounter(jobClassificationH1bCounter + 1);
    setJobClassificationCounter(jobClassificationCounter + 1);
    setJobTitleCounter(jobTitleCounter + 1);
  };

  const isInitialVisaMount = useRef(true);
  useEffect(() => {
    if (isInitialVisaMount.current) {
      isInitialVisaMount.current = false;
    } else {
      refreshVisaOptions();
      if (!preserveFilters)
        setFilter({
          job_classification: null,
          job_title: null,
          city: null,
          state: null,
          experience: null,
          industryType:
            visa !== null && (visa === "h1b" || visa === "greencard")
              ? "industry"
              : null,
          industry: null,
          company: null,
          graduationYear: null,
          functionFilter: null,
          year:
            visa !== null && (visa === "h1b" || visa === "greencard")
              ? 2024
              : 2020,
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visa]);

  const isInitialYearMount = useRef(true);
  useEffect(() => {
    if (isInitialYearMount.current) {
      isInitialYearMount.current = false;
    } else {
      refreshYearOptions();
      if (!preserveFilters)
        setFilter({
          job_classification: null,
          job_title: null,
          city: null,
          state: null,
          industryType: "industry",
          industry: null,
          company: null,
          experience: null,
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year]);

  useEffect(() => {
    if (state !== null) setCityCounter(cityCounter + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    if (
      callingIndustryFilterAPI.hasFetched() &&
      callingIndustryFilterAPI.hasErrors() === false &&
      callingIndustryFilterAPI.data()
    ) {
      const options = callingIndustryFilterAPI
        .data()
        .map((rec) => ({ value: rec.id, label: rec.industry_name }));
      setIndustryOptions(options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingIndustryFilterAPI.isFetching()]);

  useEffect(() => {
    if (!job_classification || isEmpty(job_classification))
      setAsyncOptionsSelected({
        ...asyncOptionsSelected,
        job_classification: [],
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job_classification]);

  useEffect(() => {
    if (!job_title || isEmpty(job_title))
      setAsyncOptionsSelected({
        ...asyncOptionsSelected,
        job_title: [],
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job_title]);

  const getJobTitle = async (inputValue) => {
    const data = await getJobTitleFilterForCompany({
      company_type: visa,
      search_field: inputValue,
      year,
    });

    return data.map((rec) => ({
      value: rec.id,
      label: rec.name,
    }));
  };

  const debouncedLoadJobTitleOptions = debounce(getJobTitle, 2000, {
    leading: true,
  });

  const getJobClassification = async (inputValue) => {
    const data = await getJobClassificationFilterForCompany({
      company_type: visa,
      search_field: inputValue,
      year,
    });

    return data.map((rec) => ({
      value: rec.id,
      label: rec.job_title_name,
    }));
  };

  const debouncedLoadJobClassificationOptions = debounce(
    getJobClassification,
    2000,
    {
      leading: true,
    }
  );

  const getCompany = async (inputValue) => {
    const data = await getCompanyFilterForCompany({
      company_type: visa,
      year,
      search_field: inputValue,
    });

    return data.map((rec) => ({
      value: rec.id,
      label: rec.company_name,
    }));
  };

  const debouncedLoadCompanyOptions = debounce(getCompany, 2000, {
    leading: true,
  });

  const getState = async (inputValue) => {
    const data = await getStateFilterForCompany({
      company_type: visa,
      search_field: inputValue,
    });

    return data.map((rec) => ({
      value: rec.id,
      label: rec.state_name,
    }));
  };

  const debouncedLoadStateOptions = debounce(getState, 2000, {
    leading: true,
  });

  const getCity = async (inputValue) => {
    const data = await getCityFilterForCompany({
      company_type: visa,
      search_field: inputValue,
      year,
      state_ids: state !== null ? [state?.value] : [],
    });

    return data.map((rec) => ({
      value: rec.id,
      label: rec.city_name,
    }));
  };

  const debouncedLoadCityOptions = debounce(getCity, 2000, {
    leading: true,
  });

  const processCheckboxFilter = (value = null, key = null) => {
    let _filterValues = [...((filter ?? {})[key] || [])];
    if (_filterValues?.includes(value)) {
      _filterValues = _filterValues.filter((item) => item !== value);
    } else {
      _filterValues.push(value);
    }
    changeFilter(key, _filterValues);
  };

  const changeFilter = (key, _filterValues) => {
    const changedFilter = {
      [key]: _filterValues,
    };
    applyFilter(changedFilter);
    setFilter(changedFilter);
  };

  const changeAsyncFilter = (key, _filterValues) => {
    changeFilter(
      key,
      (_filterValues ?? []).map((rec) => rec.value)
    );
    setAsyncOptionsSelected({ ...asyncOptionsSelected, [key]: _filterValues });
  };

  return (
    <VisaSearchFilterStyles>
      <div className="preset-filter--big-screen">
        <h2 className="title-primary mb-25">Popular Filters</h2>
        <div className="preset-filter">
          {presetFilters.map((item) => (
            <a
              href="/#"
              key={item.value}
              className={`preset-filter__item no-underline ${
                filter?.presetFilter === item.value &&
                "preset-filter__item--active"
              }`}
              onClick={(e) => {
                e.preventDefault();
                onPresetFilterClick({
                  presetFilter: item.value,
                  ...(item.value === presetFilters[3].value && {
                    industryCode: industryOptions?.filter(
                      (industry) => industry?.label === "Accounting"
                    )[0]?.value,
                  }),
                });
              }}
            >
              <span>{item.label}</span>
            </a>
          ))}
        </div>
      </div>
      <div
        className={`search-filter collapsible-view ${openFilter ? "open" : ""}`}
      >
        <h2 className="title-primary mb-15">
          Search & filter
          <Link
            to="/#"
            onClick={(e) => {
              e.preventDefault();
              onClearAllClick();
              setCityCounter(cityCounter + 1);
              setAsyncOptionsSelected(INITIAL_ASYNC_OPTIONS);
            }}
            className="ngray-link"
            aria-label="Clear all"
          >
            Clear all
          </Link>
          <a
            href="/#"
            onClick={(e) => {
              e.preventDefault();
              toggleFilter(!openFilter);
            }}
            className="collapse-icon"
          >
            {openFilter ? (
              <img src={FilledFilterIcon} alt="Filter Icon" />
            ) : (
              <img src={FilterIcon} alt="Filter Icon" />
            )}
          </a>
        </h2>
        <Grid container>
          <Grid item xs={12}>
            <CardCustom className="search-card overflow-initial">
              <div className="form-field-group">
                <label className="textbox-label">
                  Visa type
                  <InfoIconTooltip
                    aria-label="Visa type"
                    placement="bottom"
                    content={
                      <>
                        <h2>Visa types on Interstride</h2>
                        <Typography>
                          We source this information from the U.S. Department of
                          Labor, SEVIS, USCIS
                        </Typography>
                      </>
                    }
                  />
                </label>
                <DropdownCustom
                  name="visa"
                  placeholder="Visa type"
                  value={
                    visa !== null
                      ? (visaOptions ?? []).find(
                          (option) => option.value === visa
                        )
                      : ""
                  }
                  justifyContent="left"
                  options={visaOptions}
                  onChange={(e) => changeFilter("visa", e.value ?? null)}
                />
              </div>
              {visa !== null && (visa === "h1b" || visa === "greencard") && (
                <React.Fragment>
                  <div className="form-field-group mb-15">
                    <DropdownCustom
                      name="search_type"
                      label="Search type"
                      placeholder="Search type"
                      value={
                        visa !== null
                          ? (industryTypeOptions ?? []).find(
                              (option) => option.value === industryType
                            )
                          : ""
                      }
                      justifyContent="left"
                      options={industryTypeOptions}
                      onChange={(e) =>
                        changeFilter("industryType", e.value ?? null)
                      }
                    />
                  </div>
                  {industryType !== null && industryType === "company" && (
                    <div className="form-field-group">
                      <DropdownCustom
                        autoFocus={true}
                        key={JSON.stringify(companyCounter)}
                        isAsync={true}
                        name="company"
                        label="Companies"
                        placeholder="Enter a company name"
                        justifyContent="left"
                        value={company ? company : null}
                        defaultOptions
                        onChange={(e) => changeFilter("company", e)}
                        loadOptions={debouncedLoadCompanyOptions}
                      />
                    </div>
                  )}
                  {industryType !== null && industryType === "industry" && (
                    <div className="form-field-group">
                      <DropdownCustom
                        autoFocus
                        name="industry"
                        label="Industries"
                        placeholder="Enter an industry"
                        justifyContent="left"
                        value={
                          industry !== null
                            ? (industryOptions ?? []).find(
                                (option) => option.value === industry
                              )
                            : ""
                        }
                        options={industryOptions}
                        onChange={(e) =>
                          changeFilter("industry", e.value ?? null)
                        }
                      />
                    </div>
                  )}
                </React.Fragment>
              )}
              {visa !== null && visa === "h1b" ? (
                <AccordionView
                  label="Job classification"
                  header="Job classification"
                  infoIconTooltip={
                    <InfoIconTooltip
                      aria-label="Job classification"
                      margin="-4px 0px 0px 0px"
                      placement="bottom"
                      content={
                        <Typography>
                          Broader classification of job titles
                        </Typography>
                      }
                    />
                  }
                >
                  <div className="form-field-group">
                    <DropdownCustom
                      isMulti
                      key={`job-title-${JSON.stringify(jobTitleCounter)}`}
                      isAsync={true}
                      name="jobtitle"
                      placeholder="Job classification"
                      justifyContent="left"
                      value={asyncOptionsSelected.job_title ?? []}
                      defaultOptions
                      onChange={(values) =>
                        changeAsyncFilter("job_title", values)
                      }
                      loadOptions={debouncedLoadJobTitleOptions}
                    />
                  </div>
                </AccordionView>
              ) : null}
              {visa !== null &&
              (visa === "cpt" || visa === "opt" || visa === "stem") ? (
                <AccordionView label="Year" header="Year">
                  <div className="radio-filter-list">
                    <FormControl>
                      <RadioGroup
                        aria-labelledby="year-radio-buttons-group"
                        name="year-radio-buttons-group"
                        value={
                          year === null || year === undefined ? "empty" : year
                        }
                        onChange={(e) => changeFilter("year", e.target.value)}
                      >
                        {yearOptions?.map((item, index) => (
                          <div key={index} className="radio-filter-item">
                            <Radio
                              value={item.value}
                              disableRipple={true}
                              disableFocusRipple={true}
                              disableTouchRipple={true}
                              sx={{
                                "&.Mui-checked": {
                                  color: PRIMARY_DARK_COLOR,
                                },
                                padding: "1px 6px 0 0",
                              }}
                            />
                            <p
                              className={`radio-filter-item-label ${
                                year === item.value
                                  ? "radio-filter-item-checked"
                                  : ""
                              }`}
                            >
                              {item.label}
                            </p>
                          </div>
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </div>
                </AccordionView>
              ) : null}
              {visa !== null && visa === "h1b" ? (
                <AccordionView
                  label="Job title"
                  header="Job title"
                  infoIconTooltip={
                    <InfoIconTooltip
                      aria-label="Job title"
                      margin="-4px 0px 0px 0px"
                      placement="bottom"
                      content={
                        <Typography>
                          Exact job title sponsored by the employer
                        </Typography>
                      }
                    />
                  }
                >
                  <div className="form-field-group">
                    <DropdownCustom
                      isMulti
                      key={`job-classification-${JSON.stringify(
                        jobClassificationH1bCounter
                      )}`}
                      isAsync={true}
                      name="jobtitle"
                      label=""
                      placeholder="Job title"
                      justifyContent="left"
                      value={asyncOptionsSelected.job_classification ?? []}
                      defaultOptions
                      onChange={(values) =>
                        changeAsyncFilter("job_classification", values)
                      }
                      loadOptions={debouncedLoadJobClassificationOptions}
                    />
                  </div>
                </AccordionView>
              ) : (
                <>
                  {visa !== null &&
                    (visa === "h1b" ||
                      visa === "greencard" ||
                      visa === "alumni") && (
                      <AccordionView
                        label="Job title"
                        header="Job title"
                        infoIconTooltip={
                          <InfoIconTooltip
                            aria-label="Job title"
                            margin="-4px 0px 0px 0px"
                            placement="bottom"
                            content={
                              <Typography>
                                Exact job title sponsored by the employer
                              </Typography>
                            }
                          />
                        }
                      >
                        <div className="form-field-group">
                          <DropdownCustom
                            isMulti
                            key={`job-classification-${JSON.stringify(
                              jobClassificationCounter
                            )}`}
                            isAsync={true}
                            name="jobtitle"
                            label=""
                            placeholder="Job title"
                            justifyContent="left"
                            value={
                              asyncOptionsSelected.job_classification ?? []
                            }
                            defaultOptions
                            onChange={(values) =>
                              changeAsyncFilter("job_classification", values)
                            }
                            loadOptions={debouncedLoadJobClassificationOptions}
                          />
                        </div>
                      </AccordionView>
                    )}
                </>
              )}
              {visa !== null && (visa === "h1b" || visa === "greencard") ? (
                <AccordionView label="Year" header="Year">
                  <div className="radio-filter-list">
                    <FormControl>
                      <RadioGroup
                        aria-labelledby="year-radio-buttons-group"
                        name="year-radio-buttons-group"
                        value={
                          year === null || year === undefined ? "empty" : year
                        }
                        onChange={(e) => changeFilter("year", e.target.value)}
                      >
                        {h1bGreenCardYearOptions?.map((item, index) => (
                          <div key={index} className="radio-filter-item">
                            <Radio
                              value={item.value}
                              disableRipple={true}
                              disableFocusRipple={true}
                              disableTouchRipple={true}
                              sx={{
                                "&.Mui-checked": {
                                  color: PRIMARY_DARK_COLOR,
                                },
                                padding: "1px 6px 0 0",
                              }}
                            />
                            <p
                              className={`radio-filter-item-label ${
                                year === item.value
                                  ? "radio-filter-item-checked"
                                  : ""
                              }`}
                            >
                              {item.label}
                            </p>
                          </div>
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </div>
                </AccordionView>
              ) : (
                <AccordionView label="Year" header="Year">
                  <div className="radio-filter-list">
                    <FormControl>
                      <RadioGroup
                        aria-labelledby="year-radio-buttons-group"
                        name="year-radio-buttons-group"
                        value={
                          year === null || year === undefined ? "empty" : year
                        }
                        onChange={(e) => changeFilter("year", e.target.value)}
                      >
                        {yearOptions?.map((item, index) => (
                          <div key={index} className="radio-filter-item">
                            <Radio
                              value={item.value}
                              disableRipple={true}
                              disableFocusRipple={true}
                              disableTouchRipple={true}
                              sx={{
                                "&.Mui-checked": {
                                  color: PRIMARY_DARK_COLOR,
                                },
                                padding: "1px 6px 0 0",
                              }}
                            />
                            <p
                              className={`radio-filter-item-label ${
                                year === item.value
                                  ? "radio-filter-item-checked"
                                  : ""
                              }`}
                            >
                              {item.label}
                            </p>
                          </div>
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </div>
                </AccordionView>
              )}
              {visa !== null && (visa === "h1b" || visa === "greencard") && (
                <AccordionView label="State" header="State">
                  <div className="form-field-group">
                    <DropdownCustom
                      key={JSON.stringify(stateCounter)}
                      isAsync={true}
                      name="state"
                      label=""
                      placeholder="State"
                      justifyContent="left"
                      defaultOptions
                      value={state ? state : null}
                      onChange={(e) => changeFilter("state", e)}
                      loadOptions={debouncedLoadStateOptions}
                    />
                  </div>
                </AccordionView>
              )}
              {visa !== null &&
                (visa === "h1b" ||
                  visa === "greencard" ||
                  visa === "alumni") && (
                  <AccordionView label="City" header="City">
                    <div className="form-field-group">
                      <DropdownCustom
                        key={JSON.stringify(cityCounter)}
                        isAsync={true}
                        name="city"
                        label=""
                        placeholder="City"
                        justifyContent="left"
                        value={city ? city : null}
                        defaultOptions
                        onChange={(e) => changeFilter("city", e)}
                        loadOptions={debouncedLoadCityOptions}
                      />
                    </div>
                  </AccordionView>
                )}
              {visa !== null && visa === "alumni" && (
                <AccordionView label="Graduation year" header="Graduation year">
                  <div className="form-field-group">
                    <DropdownCustom
                      name="graduationYear"
                      label=""
                      placeholder="Graduation year"
                      defaultValue={parseInt(moment().format("YYYY"))}
                      justifyContent="left"
                      value={
                        graduationYear !== null
                          ? getYearList.find(
                              (option) => option.value === graduationYear
                            )
                          : ""
                      }
                      options={getYearList}
                      onChange={(e) =>
                        changeFilter("graduationYear", e.value ?? null)
                      }
                    />
                  </div>
                </AccordionView>
              )}
              {visa !== null && visa === "alumni" && (
                <AccordionView label="Function" header="Function">
                  <div className="form-field-group">
                    <DropdownCustom
                      name="function"
                      label=""
                      placeholder="Function"
                      justifyContent="left"
                      value={
                        functionFilter !== null
                          ? functionOptions.find(
                              (option) => option.value === functionFilter
                            )
                          : ""
                      }
                      options={functionOptions}
                      onChange={(e) =>
                        changeFilter("functionFilter", e.value ?? null)
                      }
                    />
                  </div>
                </AccordionView>
              )}
              {visa !== null && (visa === "h1b" || visa === "greencard") && (
                <AccordionView
                  label="Experience level"
                  header={
                    <label className="textbox-label">
                      Experience level
                      <a
                        href="/#"
                        onMouseEnter={(e) => {
                          e.preventDefault();
                          setExperiencePopup(true);
                        }}
                        className="note-icon"
                      >
                        <InfoIcon />
                      </a>
                    </label>
                  }
                >
                  <div className="form-field-group">
                    <div className="checkbox-filter-list">
                      {experienceOptions?.map((item = {}) => {
                        return (
                          <CheckboxJob
                            label={item.label}
                            key={item.value}
                            checked={experience?.includes(item.value)}
                            onChange={() =>
                              processCheckboxFilter(item.value, "experience")
                            }
                          />
                        );
                      })}
                    </div>
                  </div>
                </AccordionView>
              )}
            </CardCustom>
          </Grid>
        </Grid>

        {showExperiencePopup && (
          <JobExperienceModal onClose={() => setExperiencePopup(false)} />
        )}
      </div>
    </VisaSearchFilterStyles>
  );
};
export default VisaSearchFilter;
