import React from "react";
import { Link } from "react-router-dom";

import CountryInsightsNetworkingLinkStyles from "./CountryInsightsNetworkingLinkStyles";

const CountryInsightsNetworkingLink = ({ selectedCountry, selectedCity }) => {
  return (
    <CountryInsightsNetworkingLinkStyles>
      {!selectedCity && (
        <div className="country-insights-networking-link">
          <Link
            to={`/network/discover?country=${selectedCountry?.code}`}
            className="nlink-secondary"
          >
            Connect with your network from {selectedCountry?.name}
          </Link>
          <svg
            width="9px"
            height="14px"
            viewBox="0 0 9 14"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g
              id="Final-Design"
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd"
            >
              <g
                id="Country-Insights/Country"
                transform="translate(-1393.000000, -158.000000)"
                stroke="#0030B5"
                strokeWidth="2"
              >
                <g id="Connect" transform="translate(1116.000000, 154.000000)">
                  <polyline
                    id="Path-3"
                    transform="translate(281.000000, 11.000000) rotate(-90.000000) translate(-281.000000, -11.000000) "
                    points="275.5 8 281 14 286.5 8"
                  ></polyline>
                </g>
              </g>
            </g>
          </svg>
        </div>
      )}

      {selectedCity && (
        <div className="country-insights-networking-link">
          <Link
            to={`/network/discover?country=${selectedCountry?.code}&city=${selectedCity?.city_name}`}
            className="nlink-secondary"
          >
            Connect with your network from {selectedCity?.city_name}
          </Link>
          <svg
            width="9px"
            height="14px"
            viewBox="0 0 9 14"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g
              id="Final-Design"
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd"
            >
              <g
                id="Country-Insights/Country"
                transform="translate(-1393.000000, -158.000000)"
                stroke="#0030B5"
                strokeWidth="2"
              >
                <g id="Connect" transform="translate(1116.000000, 154.000000)">
                  <polyline
                    id="Path-3"
                    transform="translate(281.000000, 11.000000) rotate(-90.000000) translate(-281.000000, -11.000000) "
                    points="275.5 8 281 14 286.5 8"
                  ></polyline>
                </g>
              </g>
            </g>
          </svg>
        </div>
      )}
    </CountryInsightsNetworkingLinkStyles>
  );
};

export default CountryInsightsNetworkingLink;
